import { useContext, useState, ReactElement } from 'react';

import Modal from 'components/Modal';
import ActionButton from 'components/modals/common/buttons';
import ConfirmationScreen from 'components/modals/common/ConfirmationScreen';
import { CustomerContext } from 'contexts/CustomerContext';
import { addFreebieResponse, CustomerContextState } from 'types';

import { addFreebieR } from '../../repositories/PaymentMethods';

interface FreebiePropsType {
  cta_label: string | ReactElement;
  cta_styling: 'default' | 'no-styling';
  pricePointIdent: string;
}

export const FreebieModal = (props: FreebiePropsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    customerId,
    customerType,
    isLoading,
    setIsLoading,
    addToast,
  }: CustomerContextState = useContext(CustomerContext);

  const { cta_label = false, cta_styling } = props;

  const toggleModal = (value: boolean): void => setIsOpen(value);

  const addFreebieConfirm = async () => {
    if (customerId && customerType) {
      toggleModal(false);
      const result: addFreebieResponse | undefined = await addFreebieR({
        customerId,
        customerType,
        pricePointIdent: props.pricePointIdent,
        setIsLoading,
        addToast,
      });
      if (result?.data) {
        setIsDisabled(true);
      }
    }
  };

  return (
    <>
      <ActionButton
        onClick={() => {
          toggleModal(true);
        }}
        label={cta_label ? cta_label : 'Add Freebie'}
        skipStyling={cta_styling == 'no-styling'}
        dataTestId="e2e-add-freebie-btn"
        disabled={isDisabled}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => toggleModal(false)}
        dataTestID="e2e-add-freebie-modal"
      >
        <ConfirmationScreen
          isLoading={isLoading}
          onConfirm={addFreebieConfirm}
          onCancel={() => {
            toggleModal(false);
          }}
          confirmationMessage={'Are you sure you want to add freebie?'}
          title="Add Freebie"
          confirmCTACopy="Add Freebie"
        />
      </Modal>
    </>
  );
};
