import React, { ReactElement } from 'react';

export default function SearchFormDescription(): ReactElement {
  const searchFields = [
    'email',
    'user_id',
    'transaction id',
    'subscription id',
    'card last 4 digits',
  ];

  return (
    <div className={'pt-4'} style={{ marginLeft: '10px' }}>
      <p className={'italic'}>
        To perform a search you need to write at least
        <span className={'font-bold'}> 4 characters </span>.
      </p>
      <div className={'italic'}>
        The tool will return list of users that are a partial match <br /> with
        at least one of the following parameters:
        <br />
        {searchFields.map((field, index) => (
          <span key={field}>
            <span className={'font-bold'} key={field}>
              {field}
            </span>
            {index < searchFields.length - 1 && (
              <span key={`comma-${field}`}>, </span>
            )}
          </span>
        ))}
        .
      </div>
    </div>
  );
}
