import { useContext, useState } from 'react';

import Modal from 'components/Modal';
import ActionButton from 'components/modals/common/buttons';
import ConfirmationScreen from 'components/modals/common/ConfirmationScreen';
import { CustomerContext } from 'contexts/CustomerContext';
import { UnlinkPaymentModalProps } from 'interfaces';
import { unlinkPaymentMethodR } from 'repositories/PaymentMethods';
import { CustomerContextState } from 'types';

const UnlinkPaymentModal = (props: UnlinkPaymentModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    customerId,
    customerType,
    isLoading,
    setIsLoading,
    addToast,
  }: CustomerContextState = useContext(CustomerContext);

  const { cta_label = false, cta_styling, paymentMethodToken } = props;

  const toggleModal = (value: boolean): void => setIsOpen(value);

  const onUnlinkPaymentMethodConfirm = async () => {
    if (customerId && customerType) {
      toggleModal(false);
      await unlinkPaymentMethodR(
        customerId,
        customerType,
        paymentMethodToken,
        setIsLoading,
        addToast
      );
    }
  };

  return (
    <>
      <ActionButton
        onClick={() => {
          toggleModal(true);
        }}
        label={cta_label ? cta_label : 'Unlink Payment Method'}
        skipStyling={cta_styling == 'no-styling'}
        dataTestId="e2e-unlink-payment-method-btn"
      />

      <Modal
        isOpen={isOpen}
        onClose={() => toggleModal(false)}
        dataTestID="e2e-unlink-payment-method-modal"
      >
        <ConfirmationScreen
          isLoading={isLoading}
          onConfirm={onUnlinkPaymentMethodConfirm}
          onCancel={() => {
            toggleModal(false);
          }}
          confirmationMessage={
            'Are you sure you want to unlink this payment method?'
          }
          title="Unlink Payment Method"
          confirmCTACopy="Unlink Payment Method"
        />
      </Modal>
    </>
  );
};

UnlinkPaymentModal.displayName = 'UnlinkPaymentModal';
export default UnlinkPaymentModal;
