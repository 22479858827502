import { ButtonStyled } from './style';

type ButtonProps = {
  label: string;
  onClick: () => void;
  disabled: boolean;
};

export const Button = ({ label, onClick, disabled }: ButtonProps) => {
  return (
    <ButtonStyled onClick={onClick} disabled={disabled} type="button">
      {label}
    </ButtonStyled>
  );
};
