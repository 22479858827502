import { Dialog } from '@headlessui/react';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';
import { FormikErrors, useFormik } from 'formik';

import { DATE_FORMAT_TEMPLATE_YEAR } from 'api/constants';
import ActionButton from 'components/modals/common/buttons';

import { RenewalDiscountRawValues } from '../../../interfaces';

type renewalDiscount = {
  onConfirm: (value: string) => void;
  onCancel: () => void;
  isLoading: boolean;
  title?: string;
  confirmCTACopy: string;
  dismissCTACopy: string;
  productName?: string;
  nextRenewCheck?: string | null;
  nextPlan?: string | null;
  nextPrice?: string | null;
  currencyCode?: string | null;
};

const validate = (values: RenewalDiscountRawValues) => {
  const errors: FormikErrors<RenewalDiscountRawValues> = {};
  let discountValue: number | string = values.discountValue;

  if (isNaN(discountValue)) {
    errors.discountValue = 'Not a valid number';
  } else if (typeof discountValue === 'string') {
    discountValue = parseFloat(discountValue);
  }

  if (!discountValue) {
    errors.discountValue = 'Provide a valid number';
  }

  if (discountValue < 1) {
    errors.discountValue = 'Must be equal or greater than 1';
  }

  return errors;
};

export default function RenewalDiscount({
  onConfirm,
  isLoading,
  onCancel,
  confirmCTACopy,
  dismissCTACopy,
  productName,
  nextPlan,
  nextPrice,
  currencyCode,
}: renewalDiscount) {
  const initialDate = new Date();
  const formattedNextRenewCheck = dayjs(initialDate).format(
    DATE_FORMAT_TEMPLATE_YEAR
  );

  const initialValues: RenewalDiscountRawValues = {
    discountValue: 1,
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const formSubmitHandler = async (values: RenewalDiscountRawValues) => {
    const discountAmount = values.discountValue;
    onConfirm(discountAmount.toString());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: formSubmitHandler,
    validate: validate,
  });

  const { errors, touched, isSubmitting } = formik;

  const onClick = () => {
    void formik.submitForm();
  };

  const dismissHandler = () => {
    onCancel();
  };

  return (
    <>
      <div className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}>
        <button onClick={onCancel} className="absolute top-4 right-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className={'sm:flex sm:items-start'}>
          <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
            <Dialog.Title
              as="h3"
              className={'text-base leading-6 text-gray-900'}
            >
              {productName && nextPrice && (
                <>
                  <span className={'font-semibold'}>{productName}</span>
                  <span> next charge is:</span>
                </>
              )}{' '}
            </Dialog.Title>
            {nextPrice && (
              <div
                className={'font-semibold pt-4 '}
                style={{ letterSpacing: '0.5px' }}
              >
                {nextPrice} on {formattedNextRenewCheck} for a {nextPlan} Plan
              </div>
            )}
            <div>
              <div className={'pb-4'}>
                <div
                  className={'font-semibold pt-6 sm:text-left'}
                  style={{ letterSpacing: '0.5px' }}
                >
                  Discount for next charge
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className={'flex items-center'}>
                    <span className={'inline-block align-middle'}>
                      <input
                        type="number"
                        name="discountValue"
                        onChange={formik.handleChange}
                        value={formik.values.discountValue}
                        className={
                          'items-center p-3 border border-gray-200 rounded w-20 mr-2'
                        }
                      />
                    </span>
                    <span className={'inline-block align-middle'}>
                      {currencyCode}
                    </span>
                  </div>
                  {errors.discountValue && touched.discountValue && (
                    <div className={'basis-full'}>
                      <p className={'mt-2 text-sm text-red-600'}>
                        {errors.discountValue}
                      </p>
                    </div>
                  )}
                </form>

                <div
                  style={{
                    margin: '14px 0',
                    fontSize: '13px',
                    color: 'grey',
                    letterSpacing: '0.5px',
                  }}
                >
                  <p>
                    Please note the new Price applies to the next charge only.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={' px-4 sm:flex sm:px-6'}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          paddingTop: '3.75rem',
        }}
      >
        <ActionButton
          dataTestId="e2e-move-renewal-date-btn"
          onClick={onClick}
          label={dismissCTACopy}
          additionalStyles={{
            width: '50%',
          }}
          disabled={isSubmitting}
        />
        <ActionButton
          dataTestId="e2e-dismiss-btn"
          onClick={dismissHandler}
          disabled={isLoading}
          label={confirmCTACopy}
          additionalStyles={{ marginTop: '8px', width: '50%' }}
        />
      </div>
    </>
  );
}
