import { Dispatch, SetStateAction } from 'react';

import { AddToast } from 'react-toast-notifications';

import { requestsWrapper } from 'repositories/requestsWrapper';
import {
  cancelSubscription,
  changeRenewalDate,
  getRefundReasons,
  hardRestartSubscription,
  pauseSubscription,
  setRenewalDiscount,
  softRestartSubscription,
} from 'services/customer';

export const cancelSubscriptionR = async ({
  subscriptionId,
  setIsLoading,
  addToast,
  refundAmount,
  paymentId,
  force,
  cancelationReason,
}: {
  subscriptionId: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  addToast: AddToast;
  refundAmount?: number;
  paymentId?: string;
  force?: boolean;
  cancelationReason?: string;
}): Promise<boolean | undefined> => {
  const successMessage = 'The subscription was dismissed successfully.';

  const result = await requestsWrapper(
    cancelSubscription,
    {
      subscriptionId,
      ...(refundAmount && { refundAmount }),
      ...(paymentId && { paymentId }),
      ...(force && { force }),
      ...(cancelationReason && { cancelationReason }),
      ...(cancelationReason && { refundReason: cancelationReason }),
    },
    setIsLoading,
    addToast,
    successMessage
  );
  if (result) {
    return true;
  }
};

export const renewDateSubscription = async (
  values: Date | string,
  subscriptionId: string,
  prolongFeatures: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  const successMessage = 'The subscription renewal date is updated.';
  const result = await requestsWrapper(
    changeRenewalDate,
    { subscriptionId, isoDate: values, prolongFeatures },
    setIsLoading,
    addToast,
    successMessage
  );

  if (result?.status || result?.status === 200) {
    return true;
  }
};

export const pauseSubscriptionR = async (
  values: Date | string,
  subscriptionId: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  const successMessage = 'The subscription has been successfully paused.';
  const result = await requestsWrapper(
    pauseSubscription,
    { subscriptionId, isoDate: values },
    setIsLoading,
    addToast,
    successMessage
  );

  if (result?.status || result?.status === 200) {
    return true;
  }
};

export const setRenewalDiscountR = async (
  id: string,
  amount: string,
  subscriptionId: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  const successMessage =
    'Discount has been successfully applied for subscription.';
  const result = await requestsWrapper(
    setRenewalDiscount,
    { id, subscriptionId, amount },
    setIsLoading,
    addToast,
    successMessage
  );

  if (result?.status || result?.status === 200) {
    return true;
  }
};

export const executeSubscriptionHardResetR = async (
  subscriptionId: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  const successMessage = 'Subscription has been successfully restarted.';
  const result = await requestsWrapper(
    hardRestartSubscription,
    { subscriptionId },
    setIsLoading,
    addToast,
    successMessage
  );

  if (result?.status || result?.status === 200) {
    return true;
  }
};

export const executeSubscriptionSoftResetR = async (
  subscriptionId: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  const successMessage = 'Subscription has been successfully restarted.';
  const result = await requestsWrapper(
    softRestartSubscription,
    { subscriptionId },
    setIsLoading,
    addToast,
    successMessage
  );

  if (result?.status || result?.status === 200) {
    return true;
  }
};

export const getRefundReasonsR = async (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  const result = await requestsWrapper(
    getRefundReasons,
    {},
    setIsLoading,
    addToast
  );
  if (result?.data) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result?.data.reasons;
  } else {
    return null;
  }
};
