import { ActionButtonProps } from 'types';

export type buttonAttributesType = {
  type: 'submit' | 'button' | 'reset' | undefined;
  className: string;
  onClick: () => void;
  disabled?: boolean;
  style: object;
  'data-test-id'?: string;
};

export default function ActionButton({
  onClick,
  disabled,
  label,
  skipStyling = false,
  classForIcon,
  additionalStyles,
  dataTestId,
}: ActionButtonProps) {
  let classes = disabled ? 'cursor-not-allowed icon-btn-disabled' : '';
  const stylesForIcon = classForIcon ? classForIcon : '';

  const defaultClasses =
    'inline-flex justify-center rounded-md group relative ' +
    'justify-center rounded-md  px-4 py-4 text-sm font-semibold text-white bg-indigo-600 ' +
    'hover:bg-indigo-500 focus-visible:outline-indigo-600 focus-visible:outline ' +
    'focus-visible:outline-2 focus-visible:outline-offset-2';

  classes = skipStyling
    ? `${classes} ${stylesForIcon}`
    : `${classes} ${defaultClasses} ${stylesForIcon}`;

  const buttonAttributes: buttonAttributesType = {
    type: 'submit',
    className: classes,
    onClick: onClick,
    disabled: disabled,
    style: additionalStyles ? additionalStyles : {},
  };

  if (dataTestId !== null) {
    buttonAttributes['data-test-id'] = dataTestId;
  }

  return <button {...buttonAttributes}>{label}</button>;
}
