import { ReactElement } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

import {
  AUTH0_SCOPE,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_CONNECTION,
} from '../constants';
import { getAuth0RedirectUri } from '../services/auth';

export default function Auth0AppWrapper({
  children,
}: {
  children: ReactElement;
}) {
  const auth0Disabled = !(
    AUTH0_CLIENT_ID &&
    AUTH0_DOMAIN &&
    AUTH0_SCOPE &&
    AUTH0_AUDIENCE &&
    AUTH0_CONNECTION
  );

  if (auth0Disabled) {
    return <div>{children}</div>;
  }

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: getAuth0RedirectUri(),
        scope: AUTH0_SCOPE,
        audience: AUTH0_AUDIENCE,
        connection: AUTH0_CONNECTION,
      }}
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      cacheLocation="localstorage"
    >
      <div>{children}</div>
    </Auth0Provider>
  );
}
