import { ReactNode, useEffect, useRef } from 'react';

interface ClickOutsideProps {
  onClickOutside: () => void;
  children: ReactNode;
}
export function ClickOutside({ onClickOutside, children }: ClickOutsideProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return <div ref={ref}>{children}</div>;
}
