import { useContext } from 'react';

import { LoaderWrapper } from 'components/styled';

import { AuthContext } from '../contexts/AuthContext';
import { LoaderContext } from '../contexts/LoaderContext';

export const Loader = (): JSX.Element | null => {
  const { isLoading } = useContext(LoaderContext);
  const { isLoadingAuthorization } = useContext(AuthContext);

  if (isLoading || isLoadingAuthorization) {
    return <LoaderWrapper data-test-id="common-loader" />;
  } else {
    return null;
  }
};
