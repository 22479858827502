import React from 'react';

import { CancelledSubscriptionTooltipProps } from '../types';

export function CancelledSubscriptionInfoTooltip({
  cancelledSubscriptionInfo,
}: CancelledSubscriptionTooltipProps) {
  return (
    cancelledSubscriptionInfo && (
      <div
        className={
          'tooltip rounded shadow-lg p-1 bg-[#fcfcfc] text-[#333] -mt-10 overflow-y-auto'
        }
      >
        <div className="text-xl text-gray-600 pl-2 text-nowrap">
          <div data-test-id="e2e-refunded-currency-code">
            Unsubscription Origin:{' '}
            {cancelledSubscriptionInfo.cancellationOrigin || 'N/A'}
          </div>
          <div data-test-id="e2e-refunded-currency-code">
            Unsubscribed By: {cancelledSubscriptionInfo.cancelledBy}
          </div>
          <div data-test-id="e2e-refunded-currency-code">
            Unsubscription Reason:{' '}
            {cancelledSubscriptionInfo.cancellationReason}
          </div>
        </div>
      </div>
    )
  );
}
