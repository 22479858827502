import React, { useState, ReactNode } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

interface AccordionProps {
  header: ReactNode;
  body: ReactNode;
  defaultOpen?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  header,
  body,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="grow">{header}</div>
        <button
          data-test-id="e2e-expand-transactions"
          className="flex justify-center items-center w-full text-center text-xl"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </button>
      </div>
      {isOpen && <div className="border-t p-4">{body}</div>}
    </>
  );
};

export default Accordion;
