import CustomerTransactions from 'components/CustomerTransactions';

import { pricePointType } from '../types';

export default function TransactionsSubscriptions({
  pricePoints,
}: {
  pricePoints: pricePointType[];
}) {
  return <CustomerTransactions pricePoints={pricePoints} />;
}
