import { Dispatch, SetStateAction } from 'react';

import { AddToast } from 'react-toast-notifications';

import { requestsWrapper } from 'repositories/requestsWrapper';
import { acceptDispute } from 'services/customer';

export const acceptDisputeR = async (
  disputeId: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
): Promise<boolean | undefined> => {
  const successMessage = 'The dispute was accepted successfully.';

  const result = await requestsWrapper(
    acceptDispute,
    {
      disputeId,
    },
    setIsLoading,
    addToast,
    successMessage
  );
  if (result) {
    return true;
  }
};
