import React from 'react';

import dayjs from 'dayjs';

import { DATE_FORMAT_TEMPLATE } from 'api/constants';

import { PaymentMethodTooltipProps } from '../types';

export function RefundInfoTooltip({
  refundInfos,
  currencyCode,
}: PaymentMethodTooltipProps) {
  return (
    refundInfos &&
    refundInfos.length > 0 && (
      <div
        className={
          'tooltip rounded shadow-lg p-1 bg-[#fcfcfc] text-[#333] -mt-10 overflow-y-auto'
        }
      >
        {refundInfos.map((refundInfo) => {
          return (
            <div
              key={refundInfo.pspTransactionId}
              className={'border-b-2 border-b-gray-500 p-2'}
            >
              <div className="text-xl text-gray-600 pl-2 text-nowrap">
                <div data-test-id="e2e-refunded-currency-code">
                  Refund Origin: {refundInfo.refundOrigin}
                </div>
                <div data-test-id="e2e-refunded-currency-code">
                  Refunded By: {refundInfo.refundedBy}
                </div>
                <div data-test-id="e2e-refunded-currency-code">
                  Refund Reason: {refundInfo.refundReason || 'N/A'}
                </div>
                <div data-test-id="e2e-refunded-currency-code">
                  Refund Amount: {refundInfo.refundAmount} {currencyCode}
                </div>
                <div data-test-id="e2e-refunded-amount">
                  Refunded At:{' '}
                  {dayjs(refundInfo.refundDate).format(DATE_FORMAT_TEMPLATE)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
}
