import {
  Dispatch,
  ReactElement,
  ReactNode,
  RefObject,
  SetStateAction,
} from 'react';

import { Location } from '@remix-run/router';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { Options } from 'react-toast-notifications';

export enum btnLabelsEnum {
  revokeFeature = 'REVOKE FEATURE',
  changeGrantingDate = 'CHANGE GRANTING DATE',
  addFeature = 'ADD FEATURE',
}

export enum FeatureType {
  consumable = 'consumable',
  timebased = 'timebased',
}

export type PaymentMethodsData = {
  customerId: string;
  default: boolean;
  paymentMethodAlias: string;
  paymentMethodType: string;
  token: string;
  tokenType: string;

  paymentMethodId?: number;
  paymentSchema?: paymentSchemaType;
  cardLast4?: string;
  paymentAccountId?: string;
};

export type ErrorMappingType = {
  [key: string]: string;
};

export type CBHResponse<T> = {
  data: T | null;
  error: string | null;
};

export type paymentMethodResponse = CBHResponse<Array<paymentMethodType>>;
export type refundResponse = CBHResponse<refundType>;
export type refundReasonsResponse = CBHResponse<refundReasonsType>;
export type freebiesResponse = CBHResponse<freebiesType>;
export type addFreebieResponse = CBHResponse<addFreebieType>;
export type featureResponse = CBHResponse<Array<featureType>>;
export type subscriptionResponse = CBHResponse<Array<subscriptionType>>;
export type customerResponse = CBHResponse<Array<customersType>>;
export type pricePointResponse = CBHResponse<Array<pricePointType>>;
export type PricePointPaymentResponse = CBHResponse<
  Array<PricePointPaymentType>
>;

export type APIResponseType =
  | Array<paymentMethodType>
  | refundType
  | Array<featureType>
  | Array<subscriptionType>
  | Array<customersType>
  | Array<pricePointType>
  | Array<PricePointPaymentType>
  | UserInfo;

export type CBHResponseData<T = APIResponseType> = {
  [key: string]: T;
};

export type CBHPrivateClientResponse = {
  data: any;
  error: string | null;
};

export type CBHResponseError = {
  status: string;
  code: string;
  message: string;
  detail?: string;
};

export type paymentMethodType = {
  token: string;
  tokenType: string;
  paymentMethodType: string;
  paymentMethodAlias: string;
  customerId: string;
  default: true;
  canUnlink: false;
  cardLast4?: string;
  paymentAccountId?: string;
};

type refundType = {
  id: string;
  date: string;
  status: string;
  orderId: string;
  amount: number;
  currencyCode: string;
  customerId: string;
  metadata: object;
  statusReason: {
    type: string;
    declineType: string;
    code: string;
    message: string;
  };
};

export type RefundReasonsDictionary = {
  [key: string]: string;
};
export type refundReasonsType = {
  reasons: RefundReasonsDictionary;
};
export type addFreebieType = {
  orderId: string;
  status: string;
};
export type freebiesType = {
  freebies: freebiesPricePointType[];
  status: string;
};

export type featureType = {
  id: number;
  quantity: number;
  actualFrom: string;
  actualTill: string;
  lastSubscriptionId: string;
  orderId: string;
  name: string;
  type: FeatureType;
  pricePointIdent: string;
  pricePointName: string;
  pricePointDescription: string;
  productId: number;
  productSku: string;
  productName: string;
};

export type externalIdType = {
  type: Merchant.MerchantUuid | Merchant.MerchantStr;
  merchantId: number;
  value: string;
};

export type customersType = {
  email: string;
  emails: Array<string>;
  externalId: externalIdType;
};

export type RefundInfo = {
  pbpPaymentId: string;
  pspTransactionId: string;
  refundAmount: string;
  refundDate: string;
  refundReason?: string;
  refundType?: string;
  refundedBy?: string;
  refundOrigin?: string;
};

export type Dispute = {
  acceptedAt?: string;
  evidenceDueBy?: string;
  id: string;
  pbpPaymentId?: string;
  processor: string;
  pspCreatedAt: string;
  pspDisputeId: string;
  reason?: string;
  status?: DisputeStatuses;
  statusDescription?: string;
  detailsUrl?: string;
  isAcceptable: boolean;
};

export type SearchTransactionLog = {
  createdAtUtc: string;
  productName: string;
  customerId: string;
  emails: string[];
  localCurrencyIsoCode?: string;
  paymentMethod: { name: string; info: string };
  popTransactionId?: string;
  popTransactionType?: string;
  pspResponseCode?: string;
  pspResponseMessage?: string;
  priceLocal?: number;
  refundedAmount?: number;
  pspTransactionId?: string;
  skuName?: string;
  transactionId?: string;
  transactionStatus?: string;
  pspName?: string;
  nextPeriodType?: string;
  nextPeriodValue?: number;
  subscriptionData?: SubscriptionData;
  popTransactionLink?: string;
  pricePointData: pricePointType;
  pbpPaymentId: string;
  paymentMethodId?: number;
  paymentSchema?: paymentSchemaType;
  cardLast4: string;
  paymentAccountId?: string;
  disputes: Dispute[];
  isRefundable: boolean;
  refundInfos: RefundInfo[];
};

export type TransactionsForCustomersResponse = {
  transactions: Array<SearchTransactionLog>;
};

export type pricePointType = {
  id: number;
  ident: string;
  currentVersion: number; // Fixme in the API it's nullable
  productName: string | null;
  currencyCode: string;
  nextBasePrice: number | null;
  nextPeriodValue: number | null;
  nextPeriodType: string | null;
  introBasePrice: number | null;
  introPeriodValue: number | null;
  introPeriodType: string | null;
  commitmentPeriod: number;
};

export type freebiesPricePointType = {
  id: number;
  ident: string;
  name: string;
  productSku: string;
  currentVersion: number; // Fixme in the API it's nullable
  productName: string | null;
  currencyCode: string;
  nextBasePrice: number | null;
  nextPeriodValue: number | null;
  nextPeriodType: string | null;
  introBasePrice: number | null;
  introPeriodValue: number | null;
  introPeriodType: string | null;
  commitmentPeriod: number;
};

export type FirstTransactionLinksType = {
  pspTransactionLink?: string;
  popTransactionLink?: string;
};

export type cancelledSubscriptionInfoType = {
  cancellationOrigin: string;
  cancellationReason: string;
  cancelledBy: string;
  subscriptionId: string;
};

export type SubscriptionData = {
  subscriptionId: string;
  isTrial: boolean;
  subscriptionTags: Array<SubscriptionTags>;
  subscriptionNextCheck?: string;
  subscriptionPeriodEndAt?: string;
  cancelReason?: string;
  cancelledSubscriptionInfo?: cancelledSubscriptionInfoType;
};

export type TransactionLogType = {
  id: string;
  orderId: string;
  transactionId: string;
  paymentOrchestrationProviderId?: number;
  popTransactionId?: string;
  popTransactionType?: string;
  popTransactionLink?: string;
  pspId?: number;
  pspTransactionId?: string;
  pspTransactionLink?: string;
  subscriptionId?: string;
  isCit?: boolean;
  currencyCode?: string;
  transactionStatus?: string;
  pspResponseCode?: string;
  pspResponseMessage?: string;
  amount?: number;
  cardLast4?: string;
  createdAtUtc: string;
  productId?: number;
  pricePointId?: number;
  paymentAccountId?: string;
  pricePointVersionId?: number;
  refundInfo: RefundInfo;
};

export type chargeValueType = {
  id: number;
  levelOne: string;
  levelTwo: string;
};

export type paymentSchemaType =
  | 'amex'
  | 'diners_club'
  | 'discover'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'mir'
  | 'visa'
  | 'other';

export type firstTransactionType = {
  amount?: string;
  currencyCode?: string;
  paymentMethodId?: number;
  paymentSchema?: paymentSchemaType;
  cardLast4: string;
  paymentAccountId?: string;
  transactionId: string;
  pop?: string;
  popTransactionId?: string;
  psp?: string;
  pspTransactionId: string;
  isCit: boolean;
  pricePointVersionId: number;
};

export type pricePointTransactionType = {
  nextPeriodType: string;
  nextPeriodValue: number;
  pricePointId: number;
  pricePointVersionId: number;
};

export type subscriptionInfoType = {
  id: number;
  createdAt: string;
  type: string;
  subtype: string;
  subscriptionId: string;
  productName?: string;
  pricePoint?: pricePointType;
  transaction?: SubscriptionLogTransaction;
  tags: SubscriptionTags[];
};

export type DiscountInfo = {
  originalAmount: string;
  discountAmount: string;
  discountPercent: number;
  currencyCode: string;
};

export type PricePointPaymentType = {
  id: string;
  updatedAt: string;
  createdAt: string;
  lastTransactionEventTime: string;
  orderId: string;
  pspPaymentId: string;
  pspType: number;
  amountToRefund?: number;
  refundedAmount?: number;
  currencyCode?: string;
  chargeType?: chargeValueType;
  country?: string;
  firstTransaction?: firstTransactionType;
  pricePoint?: pricePointType;
  subscriptionInfo?: subscriptionInfoType;
  productName?: string;
  transactionLogs: Array<TransactionLogType>;
  discountInfo?: DiscountInfo;
  isRefundable: boolean;
  refundInfos?: RefundInfo[];
};

export type revokeFeatureRequestParams = {
  featureId: string;
  customerId: string;
  customerType: customerTypeVariants;
};
export type downloadReceiptRequestParams = {
  pbpPaymentId: string;
  createdAtUtc: string;
};

export enum subscriptionStateEnum {
  active = 'active',
  canceled = 'canceled',
}

export type SubscriptionEvent = {
  createdAt: string;
  type: string | null;
  subtype: string | null;
  id: string | null;
  productName: string;
  pricePoint: pricePointType;
  transaction: null | SubscriptionLogTransaction;
  subscriptionId: string;
  tags: SubscriptionTags[];
};

export type subscriptionType = {
  id: string;
  state: subscriptionStateEnum;
  pricePointId: number;
  pricePointVersionId: number;
  canceledAt: string;
  createdAt: string;
  updatedAt: string;
  nextRenewCheck: string | null;
  currentPeriodStartAt: string;
  currentPeriodEndAt: string;
  tags: SubscriptionTags[];
  name: string;
  logs: Array<SubscriptionEvent>;
  orderAmount: string;
  refundedAmount: string;
  productName?: string;
  pricePoints: Array<pricePointType>;
  cancelReason?: string;
  cancelledSubscriptionInfo?: cancelledSubscriptionInfoType;
};

export enum Merchant {
  MerchantUuid = 'merchant-uuid',
  MerchantStr = 'merchant-str',
}

// Customer Context Types
export type customerTypeVariants = Merchant.MerchantUuid | Merchant.MerchantStr;

export type CustomerContext = {
  customerId: string;
  customerType: customerTypeVariants;
  customerEmail: string | null;
  searchResult: 'found' | 'not-found' | 'loading' | null;
};

export type CustomerContextState = {
  customerId: string;
  customerType: customerTypeVariants;
  customerEmail: string | null;
  searchResult: 'found' | 'not-found' | 'loading' | null;
  openedSearchedCustomers: Array<customersType> | [];
  setOpenedSearchedCustomers: React.Dispatch<
    React.SetStateAction<Array<customersType>>
  >;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  addToast: (
    content: ReactNode,
    options?: Options,
    callback?: (id: string) => void
  ) => void;
  refetchFeaturesCounter: number;
  refetchFeatures: () => void;
  refetchSearch: () => void;
  searchTriggerCounter: number;
  setSearchTriggerCounter: (value: number) => void;
};

export type LoaderContextState = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  addToast: (
    content: ReactNode,
    options?: Options,
    callback?: (id: string) => void
  ) => void;
};

export type SetValueType<A> = (value: A) => void;

export type SearchContextState = {
  isDisabled: boolean;
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
  previousSearch: string;
  setPreviousSearch: SetValueType<string>;
  lastSearchResultClean: string;
  setLastSearchResultClean: SetValueType<string>;
  searchResults: SearchResultsType | null;
  setSearchResults: SetValueType<SearchResultsType | null>;
  queryStringSearchParams: URLSearchParams;
  setQueryStringSearchParams: Dispatch<SetStateAction<any>>;
  cleanSearch: () => void;
  customers: Array<customersType>;
  setCustomers: Dispatch<SetStateAction<Array<customersType>>>;
  customersTransactions: Array<SearchTransactionLog>;
  setCustomersTransactions: Dispatch<
    SetStateAction<Array<SearchTransactionLog> | []>
  >;
  formRef: RefObject<HTMLFormElement>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export type customerContextStates = {
  [key: string]: CustomerContext;
};

export type CustomerContextProps = {
  children?: ReactNode | undefined;
};

export type ZendeskIframeMessage = {
  version: number;
  mode: string;
  zendesk: {
    ticket_id: number;
    ticket_subject: string;
    ticket_type: string;
    customer_email: string;
    supporter_email: string;
  };
};

export type AdminMessage = {
  access: string;
  refresh: string;
  user: object;
};

// Customer Context Types

export type FoundedCustomerType = {
  externalId: {
    type: Merchant.MerchantUuid | Merchant.MerchantStr;
    merchantId: number;
    value: string;
  };
  email: string;
};

export type AxiosInterceptorProps = {
  children: ReactElement;
};

export type TransactionLog = {
  id: number;
  transactionId: string;
  paymentOrchestrationProviderId: number;
  popTransactionId: string;
  popTransactionType: string;
  pspId: number;
  pspTransactionId: string;
  subscriptionId: string | null;
  isCit: boolean;
  currencyCode: string | null;
  productId: number;
  transactionStatus: string;
  amount: number;
  cardLast4: string;
  pricePointId: number;
  pricePointVersionId: number;
  createdAtUtc: string;
};

export type customerFieldsParams = {
  customerId: string;
  customerType: customerTypeVariants;
};

export type extendedCustomerType = customersType & {
  searchQuery: string;
  addNewTab: (value: extendedCustomerType) => void;
};

export type ActionButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  label: string | ReactElement;
  skipStyling?: boolean;
  classForIcon?: string;
  additionalStyles?: object;
  dataTestId?: string;
};

export type ConfirmationScreenProps = {
  onConfirm: (selectedOption?: string) => void;
  onCancel: () => void;
  isLoading: boolean;
  confirmationMessage: string;
  title?: string;
  confirmCTACopy?: string;
  dismissCTACopy?: string;
  isCommitmentPeriod?: boolean;
  cancelSubscriptionAvailableOptions?: cancelSubscriptionOptions[];
};

export type ErrorScreenProps = {
  onCancel: () => void;
  errorMessage: boolean | string | ReactElement;
  active: boolean;
};

export type SuccessScreenProps = {
  onCancel: () => void;
  successMessage: boolean | string | ReactElement;
  active: boolean;
};

export type Workspace = {
  id: number;
  name: string;
};

export type ApplicationUser = {
  workspaces: Workspace[];
  activeWorkspace: number;
  activeMerchantId: number;
  activeWorkspaceName: string;
  username: string;
};

export type UserInfo = {
  activeMerchantId: number;
  activeWorkspaceId: number;
  groups: string[];
  permissions: string[];
  id: string;
  username: string;
  workspaces: Workspace[];
};

export type LocationState = {
  from: Location;
};

export type GetTokenResponse = {
  access: string;
  refresh: string;
};

export type LoginUserResponse = {
  data: GetTokenResponse | null;
  error: string | null;
};

export type searchCustomersParams = {
  searchString: string;
  from: Date | null;
  to: Date | null;
};

export type TableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  renderSubComponent: (props: { row: Row<TData> }) => ReactElement;
  getRowCanExpand: (row: Row<TData>) => boolean;
  tHeadStyles?: object;
  dataTestID: string;
};

export type ShortPricePoint = {
  id: number;
  versionId: number;
};

export type PricePointPaymentsModalProps = {
  pricePoints: Array<ShortPricePoint>;
};

export type refundPaymentParams = {
  paymentId: string;
  amount: number;
  isSoftRefund: boolean;
  reason: string;
};

export type cancelSubscriptionParams = {
  subscriptionId: string;
  refundAmount?: string;
  paymentId?: string;
  force?: boolean;
  cancelationReason?: string;
};

export type acceptDisputeParams = {
  disputeId: string;
};

export type unlinkPaymentMethodParams = {
  customerId: string;
  customerType: string;
  paymentMethodToken: string;
};

export type TablePaginationProps = {
  table: Table<any>;
};

export type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: (open: boolean) => void;
  dataTestID: string;
};

export type searchResultEntry = {
  results: Array<customersType>;
  expires: string;
};

export type SearchResultsType = {
  [key: string]: searchResultEntry;
};

export type SearchFormProps = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  setCustomers: Dispatch<SetStateAction<Array<customersType> | []>>;
  setCustomersTransactions: Dispatch<
    SetStateAction<Array<SearchTransactionLog> | []>
  >;
  customers: Array<customersType>;
};

export enum Mode {
  zendesk = 'zendesk',
}

export type SubscriptionLogTransaction = {
  amount: string;
  cardLast4?: string;
  currencyCode: string;
  paymentAccountId?: string;
  paymentMethodId: number;
  paymentSchema: paymentSchemaType;
  popTransactionId: string;
};

export enum SubscriptionTags {
  inRecurring = 'in_recurring',
  isExpired = 'is_expired',
  isCanceled = 'is_canceled',
  isActive = 'is_active',
  isUnsubscribed = 'is_unsubscribed',
  inIntro = 'in_intro',
  isTrial = 'trial',
  isUpcoming = 'is_upcoming',
  isPaused = 'is_paused',
  inGrace = 'in_grace',
  inRetry = 'in_retry',
  isDeferred = 'is_deferred',
  inCommitment = 'in_commitment',
  isUnsubscriptionPostponed = 'is_unsubscription_postponed',
}

export enum SubscriptionStatuses {
  active = 'Active',
  expired = 'Expired',
  canceled = 'Canceled',
  hardCancel = 'Hard canceled',
  softCancel = 'Soft canceled',
  canceledCrossgraded = 'Canceled - Crossgraded',
  notASubscription = 'Not a subscription',
  inCommitment = 'Active - in commitment',
}

export enum DisputeStatuses {
  request_for_information = 'request_for_information',
  request_for_information_refunded = 'request_for_information_refunded',
  early_fraud_warning = 'early_fraud_warning',
  early_fraud_warning_refunded = 'early_fraud_warning_refunded',
  first_chargeback_received = 'first_chargeback_received',
  first_chargeback_in_review = 'first_chargeback_in_review',
  first_chargeback_accepted = 'first_chargeback_accepted',
  first_chargeback_lost = 'first_chargeback_lost',
  first_chargeback_won = 'first_chargeback_won',
  first_chargeback_pre_arbitration = 'first_chargeback_pre_arbitration',
  second_chargeback_received = 'second_chargeback_received',
  pre_chargeback_alert_accepted = 'pre_chargeback_alert_accepted',
  pre_chargeback_alert_received = 'pre_chargeback_alert_received',
  pre_chargeback_alert_lost = 'pre_chargeback_alert_lost',
  pre_chargeback_alert_won = 'pre_chargeback_alert_won',

  dispute_open = 'dispute_open',
  dispute_under_review = 'dispute_under_review',
  dispute_waiting_for_buyer_response = 'dispute_waiting_for_buyer_response',
  dispute_waiting_for_seller_response = 'dispute_waiting_for_seller_response',
  dispute_accepted = 'dispute_accepted',
  dispute_lost = 'dispute_lost',
  dispute_won = 'dispute_won',

  chargeback_open = 'chargeback_open',
  chargeback_under_review = 'chargeback_under_review',
  chargeback_waiting_for_buyer_response = 'chargeback_waiting_for_buyer_response',
  chargeback_waiting_for_seller_response = 'chargeback_waiting_for_seller_response',
  chargeback_accepted = 'chargeback_accepted',
  chargeback_lost = 'chargeback_lost',
  chargeback_won = 'chargeback_won',
  chargeback_under_review_internal = 'chargeback_under_review_internal',
  chargeback_waiting_for_buyer_response_internal = 'chargeback_waiting_for_buyer_response_internal',
}

export type JwtDecodedToken = {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  is_staff: boolean;
  is_superuser: boolean;
  groups: Array<string>;
  permissions: Array<string>;
  active_merchant: {
    id: number;
    name: string;
  };
  active_workspace: {
    id: number;
    name: string;
  };
};

export type CustomerTransactionListRefundRowTableOptions = {
  meta: {
    updateRefundAmount: (
      transactionId: string,
      amount: number,
      precision: number
    ) => void;
  };
};

export type PricePointsProductMapType = object & {
  [x: number]: {
    [x: number]: string;
  };
};

export enum deferralType {
  withFeatures = 'withFeatures',
  withoutFeatures = 'withoutFeatures',
}

export enum cancelSubscriptionOptions {
  withoutRefund = 'withoutRefund',
  withRefund = 'withRefund',
  softCancel = 'softCancel',
  hardCancelWithoutRefund = 'hardCancelWithoutRefund',
  hardCancelWithRefund = 'hardCancelWithRefund',
}

export type PaymentMethodTooltipProps = {
  refundInfos: RefundInfo[];
  currencyCode: string;
};

export type CancelledSubscriptionTooltipProps = {
  cancelledSubscriptionInfo?: cancelledSubscriptionInfoType;
};
