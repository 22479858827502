import { Dispatch, SetStateAction } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { AddToast } from 'react-toast-notifications';

import { get_error_from_mapping } from 'services/customer';
import { CBHResponseError } from 'types';

export const requestsWrapper = async <T>(
  request: (body: any) => Promise<AxiosResponse<T>>,
  body: object,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast,
  successMessage?: string
): Promise<AxiosResponse<T> | null> => {
  try {
    // Loader
    setIsLoading(true);

    const result: AxiosResponse<T> = await request(body);
    if (result && result.status == 200) {
      if (successMessage) {
        addToast(successMessage, {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      return result;
    }
  } catch (e: unknown) {
    const error = e as AxiosError<CBHResponseError>;

    let errorMessage = error.message;

    if (error?.response?.data?.code) {
      errorMessage = get_error_from_mapping(
        error.response.data.code,
        error.message
      );
    }

    // addToast with error message
    addToast(errorMessage, {
      appearance: 'error',
      autoDismiss: true,
    });
  } finally {
    // remove Loader
    setIsLoading(false);
  }

  return null;
};
