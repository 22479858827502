import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';

import Layout from 'components/Layout';

import {
  defaultApplicationUser,
  useLocalStorage,
} from '../contexts/AuthContext';
import { inIframe } from '../helpers/iframe';
import { getAuth0RedirectUri } from '../services/auth';

export default function RequireAuth() {
  const location = useLocation();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    loginWithPopup,
    user,
  } = useAuth0();

  const [localUser] = useLocalStorage('user', defaultApplicationUser);

  useEffect(() => {
    console.log('RequireAuth useEffect, localUser:', localUser);
    console.log('RequireAuth useEffect, isAuthenticated:', isAuthenticated);
    console.log('RequireAuth useEffect, isLoading:', isLoading);
    console.log('RequireAuth useEffect, user:', user);

    if (isLoading || isAuthenticated) {
      return;
    }
    (async (): Promise<void> => {
      if (inIframe()) {
        await loginWithPopup({
          authorizationParams: {
            redirect_uri: getAuth0RedirectUri(),
          },
        });
      } else {
        await loginWithRedirect({
          appState: { returnTo: location.pathname },
        });
      }
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <></>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (inIframe() && localUser === null && isAuthenticated) {
    console.log('RequireAuth, inIframe, localUser === null && isAuthenticated');
    return <Navigate to="/auth0-callback" state={{ from: location }} replace />;
  }

  return <Layout />;
}
