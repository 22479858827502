import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { matchPath, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { ENV, FOUNDED_CUSTOMER_ID, SET_CUSTOMER_ON_START } from 'api/constants';
import {
  CustomerContextProps,
  CustomerContextState,
  customerContextStates,
  customersType,
  Merchant,
} from 'types';

const defaultCustomerContextStates: customerContextStates = {
  customer: {
    customerId: '',
    customerEmail: null,
    customerType: Merchant.MerchantUuid,
    searchResult: null,
  },
};

export const defaultCustomerContextState = {
  addToast: () => {},
  customerEmail: null,
  customerId: '',
  customerType: Merchant.MerchantUuid,
  isLoading: false,
  openedSearchedCustomers: [],
  searchResult: null,
  setIsLoading: () => {},
  setOpenedSearchedCustomers: () => {},
  refetchFeaturesCounter: 0,
  refetchFeatures: () => {},
  searchTriggerCounter: 0,
  setSearchTriggerCounter: () => {},
  refetchSearch: () => {},
};

export const CustomerContext = createContext<CustomerContextState>(
  defaultCustomerContextState
);

export const CustomerProvider = ({
  children,
}: CustomerContextProps): ReactElement => {
  const [searchResult] = useState<'found' | 'not-found' | 'loading' | null>(
    null
  );

  const [searchTriggerCounter, setSearchTriggerCounter] = useState(0);

  const [refetchFeaturesCounter, setRefetchFeaturesCounter] = useState(0);

  const location = useLocation();

  const [customerContextStates, setCustomerContextStates] =
    useState<customerContextStates>(defaultCustomerContextStates);
  const [isLoading, setIsLoading] = useState(false);

  const [openedSearchedCustomers, setOpenedSearchedCustomers] = useState<
    customersType[]
  >([]);

  const { addToast } = useToasts();

  const refetchFeatures = useCallback(() => {
    setRefetchFeaturesCounter(refetchFeaturesCounter + 1);
  }, [refetchFeaturesCounter]);

  const refetchSearch = useCallback(() => {
    setSearchTriggerCounter(searchTriggerCounter + 1);
  }, [searchTriggerCounter]);

  useEffect(() => {
    // We set customer from iframe message in production,
    // locally we set hardcoded customer id and set it explicitly
    if (ENV === 'development' && SET_CUSTOMER_ON_START) {
      setCustomerContextStates({
        ...customerContextStates,
        customer: {
          customerId: FOUNDED_CUSTOMER_ID,
          customerType: Merchant.MerchantUuid,
          searchResult: 'found',
          customerEmail: '',
        },
      });
    }
  }, []);

  const getContextValue = () => {
    const match = matchPath(
      '/customer/:externalIdType/:externalIdValue',
      location.pathname
    );

    let externalIdType: string | null = null;
    let externalIdValue: string | null = null;

    if (match && match.params.externalIdType && match.params.externalIdValue) {
      externalIdType = match.params.externalIdType;
      externalIdValue = match.params.externalIdValue;
    }

    let context;

    openedSearchedCustomers.map((openedCustomer) => {
      if (
        openedCustomer.externalId.type === externalIdType &&
        openedCustomer.externalId.value === externalIdValue
      ) {
        context = {
          customerId: openedCustomer.externalId.value,
          customerType: openedCustomer.externalId.type,
          searchResult: 'found',
          customerEmail: openedCustomer.emails[0],
        };
      }
    });

    if (!context) {
      context =
        customerContextStates.customer || defaultCustomerContextStates.customer;
    }

    return {
      customerId: context.customerId,
      customerEmail: context.customerEmail,
      customerType: context.customerType,
      searchResult: context.searchResult,
      openedSearchedCustomers: openedSearchedCustomers,
      isLoading: isLoading,
      setOpenedSearchedCustomers: setOpenedSearchedCustomers,
      addToast: addToast,
      setIsLoading: setIsLoading,
      refetchFeaturesCounter: refetchFeaturesCounter,
      refetchFeatures: refetchFeatures,
      refetchSearch: refetchSearch,
      searchTriggerCounter: searchTriggerCounter,
      setSearchTriggerCounter: setSearchTriggerCounter,
    };
  };

  const value = useMemo(
    () => getContextValue(),
    [
      location,
      searchResult,
      openedSearchedCustomers,
      customerContextStates,
      isLoading,
      addToast,
      refetchFeaturesCounter,
      refetchFeatures,
      searchTriggerCounter,
      refetchSearch,
    ]
  );

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};
