import React from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { DateTimePicker } from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

export enum QuickRange {
  seven_days = 'seven_days',
  thirty_days = 'thirty_days',
  ninety_days = 'ninety_days',
  six_month = 'six_month',
  twelve_month = 'twelve_month',
  custom = 'custom',
}

type DateTimeRangePickerProps = {
  isDateRangeOpened: boolean;
  setIsDateRangeOpened: (value: boolean) => void;
  quickRangeApplied: null | Array<Date | null>;
  quickRangeSelected: QuickRange;
  setStartDateTime: (value: Date | null) => void;
  startDateTime: Date | null;
  setEndDateTime: (value: Date | null) => void;
  endDateTime: Date | null;
  applyDateTimeRange: () => void;
  setDateTimeRangeFromQuickRange: (quickRange: QuickRange) => void;
};

export const getQuickRange = (quickRange: QuickRange): [Date, Date] => {
  const endDateTime = new Date();
  const startDateTime = new Date(endDateTime);

  switch (quickRange) {
    case QuickRange.seven_days:
      startDateTime.setDate(endDateTime.getDate() - 7);
      break;
    case QuickRange.thirty_days:
      startDateTime.setDate(endDateTime.getDate() - 30);
      break;
    case QuickRange.ninety_days:
      startDateTime.setDate(endDateTime.getDate() - 90);
      break;
    case QuickRange.six_month:
      startDateTime.setDate(endDateTime.getDate() - 180);
      break;
    case QuickRange.twelve_month:
      startDateTime.setDate(endDateTime.getDate() - 366);
      break;
  }

  return [startDateTime, endDateTime];
};

const getQuickRangeFilterMessage = (quickRange: QuickRange): string => {
  switch (quickRange) {
    case QuickRange.seven_days:
      return 'Last 7 days';
    case QuickRange.thirty_days:
      return 'Last 30 days';
    case QuickRange.ninety_days:
      return 'Last 90 days';
    case QuickRange.six_month:
      return 'Last 6 months';
    case QuickRange.twelve_month:
      return 'Last 12 months';
  }

  return '';
};

export default function DateTimeRangePicker({
  isDateRangeOpened,
  setIsDateRangeOpened,
  quickRangeApplied,
  quickRangeSelected,
  setStartDateTime,
  startDateTime,
  setEndDateTime,
  endDateTime,
  applyDateTimeRange,
  setDateTimeRangeFromQuickRange,
}: DateTimeRangePickerProps) {
  // Set min and max date for the date picker
  const minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );
  const maxDate = new Date();
  return (
    <div className={'p-2'}>
      <div
        className={'flex'}
        onClick={() => setIsDateRangeOpened(!isDateRangeOpened)}
      >
        <div className={'flex-none pr-4'}>
          {quickRangeApplied &&
            quickRangeApplied[0] &&
            quickRangeApplied[1] && (
              <span>
                <span className={'font-bold'}>From: </span>{' '}
                {quickRangeApplied[0]
                  ? new Date(quickRangeApplied[0]).toUTCString()
                  : ''}{' '}
                <span className={'font-bold'}>To: </span>
                {quickRangeApplied[1]
                  ? new Date(quickRangeApplied[1]).toUTCString()
                  : ''}
              </span>
            )}
          {!quickRangeApplied && quickRangeSelected && (
            <>
              <span>Time Range: </span>
              <span className={'font-bold'}>
                {getQuickRangeFilterMessage(quickRangeSelected)}
              </span>
            </>
          )}
        </div>
        <div className={'flex-auto'}>
          {isDateRangeOpened ? (
            <span>
              <ChevronUpIcon className="h-6 w-6" />
            </span>
          ) : (
            <span>
              <ChevronDownIcon className="h-6 w-6" />
            </span>
          )}
        </div>
      </div>
      <div className={(isDateRangeOpened ? '' : 'hidden') + ' mt-4'}>
        <div className={'flex'}>
          <div className={'flex-none pr-4'}>
            <div>
              <div className={'font-bold'}>From:</div>
              <div>
                <DateTimePicker
                  onChange={setStartDateTime}
                  value={startDateTime}
                  format="dd-MM-yyyy HH:mm:ss"
                  disableClock={true}
                  locale={'en-GB'}
                  minDate={minDate}
                />
              </div>
            </div>
            <div>
              <div className={'font-bold'}>To:</div>
              <div>
                <DateTimePicker
                  onChange={setEndDateTime}
                  value={endDateTime}
                  format="dd-MM-yyyy HH:mm:ss"
                  disableClock={true}
                  locale={'en-GB'}
                  maxDate={maxDate}
                />
              </div>
            </div>
            <div className={'mt-4'}>
              <button
                type="submit"
                className={
                  'text-white xs:mt-5 xs:w-full  sm:mt-0 sm:w-36 right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 mt-4'
                }
                data-test-id="e2e-apply-datetime-range"
                onClick={applyDateTimeRange}
              >
                Apply Time Range
              </button>
            </div>
          </div>
          <div className={'pl-4 flex-auto border-l-2 border-[#fff]-100'}>
            <div>
              <span>Select Quick Range:</span>
            </div>
            <div
              className={
                quickRangeSelected == QuickRange.seven_days ? 'font-bold' : ''
              }
              onClick={() =>
                setDateTimeRangeFromQuickRange(QuickRange.seven_days)
              }
            >
              Last 7 days
            </div>
            <div
              className={
                quickRangeSelected == QuickRange.thirty_days ? 'font-bold' : ''
              }
              onClick={() =>
                setDateTimeRangeFromQuickRange(QuickRange.thirty_days)
              }
            >
              Last 30 days
            </div>
            <div
              className={
                quickRangeSelected == QuickRange.ninety_days ? 'font-bold' : ''
              }
              onClick={() =>
                setDateTimeRangeFromQuickRange(QuickRange.ninety_days)
              }
            >
              Last 90 days
            </div>
            <div
              className={
                quickRangeSelected == QuickRange.six_month ? 'font-bold' : ''
              }
              onClick={() =>
                setDateTimeRangeFromQuickRange(QuickRange.six_month)
              }
            >
              Last 6 months
            </div>
            <div
              className={
                quickRangeSelected == QuickRange.twelve_month ? 'font-bold' : ''
              }
              onClick={() =>
                setDateTimeRangeFromQuickRange(QuickRange.twelve_month)
              }
            >
              Last 12 months
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
