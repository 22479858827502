import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';

import { DATE_FORMAT_TEMPLATE } from 'api/constants';
import ActionButton from 'components/modals/common/buttons';

type restartSubscription = {
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  title?: string;
  confirmCTACopy: string;
  dismissCTACopy: string;
  expirationDate?: string | undefined;
  isExpired?: boolean;
  nextPrice?: string | null;
  currencyCode?: string | null;
};

export default function RestartSubscription({
  onConfirm,
  isLoading,
  onCancel,
  confirmCTACopy,
  dismissCTACopy,
  expirationDate,
  isExpired,
  nextPrice,
}: restartSubscription) {
  const formattedExpirationDate = expirationDate
    ? dayjs(expirationDate).format(DATE_FORMAT_TEMPLATE)
    : '';

  const dismissHandler = () => {
    onCancel();
  };
  const renewDateHandler = () => {
    onConfirm();
  };

  return (
    <>
      <div className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}>
        <button onClick={onCancel} className="absolute top-4 right-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className={'sm:flex sm:items-start'}>
          <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
            <div
              style={{
                margin: '14px 0',
                letterSpacing: '0.5px',
              }}
            >
              {isExpired ? (
                <span className={'font-semibold'}>
                  This subscription has already expired on: <br />
                  {formattedExpirationDate}
                </span>
              ) : (
                <span className={'font-semibold'}>
                  This subscription has been already cancelled <br /> and will
                  expire on: {formattedExpirationDate}
                </span>
              )}
            </div>
            <div>
              <div
                style={{
                  margin: '14px 0',
                  fontSize: '13px',
                  color: 'grey',
                  letterSpacing: '0.5px',
                }}
              >
                {isExpired ? (
                  <p>
                    If you restart the subscription the customer will be
                    subscribed again and will be charged immediately{' '}
                    <span className={'font-semibold'}>{nextPrice}</span> as per
                    the normal subscription schedule
                  </p>
                ) : (
                  <p>
                    If you restart the subscription the customer will be
                    subscribed again and will be charged{' '}
                    <span className={'font-semibold'}>{nextPrice}</span> as per
                    the normal subscription schedule on{' '}
                    <span className={'font-semibold'}>
                      {formattedExpirationDate}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={' px-4 sm:flex sm:px-6'}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          paddingTop: '3.75rem',
        }}
      >
        <ActionButton
          dataTestId="e2e-dismiss-btn"
          onClick={renewDateHandler}
          disabled={isLoading}
          label={confirmCTACopy}
          additionalStyles={{ marginTop: '8px', width: '50%' }}
        />
        <ActionButton
          dataTestId="e2e-move-renewal-date-btn"
          onClick={dismissHandler}
          label={dismissCTACopy}
          additionalStyles={{ marginTop: '8px', width: '50%' }}
        />
      </div>
    </>
  );
}
