const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || '';
const BASE_AUTH_URL = process.env.REACT_APP_BASE_AUTH_URL || '';

const FOUNDED_CUSTOMER_ID =
  process.env.REACT_APP_FOUNDED_CUSTOMER_ID ||
  'e1e924f9-32ba-4d21-bbb4-38bbac172356';
const SET_CUSTOMER_ON_START =
  process.env.REACT_APP_SET_CUSTOMER_ON_START === 'true';

const DEFAULT_CANCELLATION_REASON = 'unknown_prefer_not_to_say';
const DEFAULT_CANCELLATION_TYPE = 'voluntary';

const CBH_PREFIX = '/customer-billing-history';

const ENV = process.env.NODE_ENV;

const CBH_URLS: { [key: string]: string } = {
  payment_methods_url: `${CBH_PREFIX}/get-payments-methods-by-customer-id`,
  refund_payment_url: `${CBH_PREFIX}/refund-payment`,
  cancel_subscription: `${CBH_PREFIX}/cancel-subscription`,
  defer_subscription: `${CBH_PREFIX}/defer-subscription`,
  get_features: `${CBH_PREFIX}/get-features`,
  get_subscriptions: `${CBH_PREFIX}/get-subscriptions`,
  unlink_payment_method: `${CBH_PREFIX}/unlink-payment-method`,
  get_price_points: `${CBH_PREFIX}/get-price-points`,
  get_payments_by_price_point: `${CBH_PREFIX}/get-payments-by-price-point`,
  search_customers: `${CBH_PREFIX}/search-customers`,
  list_transactions_for_customers: `${CBH_PREFIX}/list-transactions-for-customers`,
  download_receipt: `${CBH_PREFIX}/download-receipt`,
  revoke_feature: `${CBH_PREFIX}/revoke-feature`,
  accept_dispute: `${CBH_PREFIX}/accept-dispute`,
  pause_subscription: `${CBH_PREFIX}/pause-subscription`,
  set_renewal_discount: `${CBH_PREFIX}/set-renewal-discount`,
  hard_reset: `${CBH_PREFIX}/hard-reset`,
  soft_reset: `${CBH_PREFIX}/soft-reset`,
  refund_reasons: `${CBH_PREFIX}/refund-reasons`,
  get_freebies: `${CBH_PREFIX}/get-freebies`,
  grant_freebie: `${CBH_PREFIX}/grant-freebie`,
};

const USER_INFO_URL = '/auth/user-info/';

const AUTH_URLS = {
  login_url: '/api/v1/jwt/create/',
  refresh_url: '/api/v1/jwt/refresh/',
  user_url: '/api/v1/users/me/',
};

const DATE_FORMAT_TEMPLATE = 'D MMM YY, HH:mm';
export const DATE_FORMAT_TEMPLATE_YEAR = 'YYYY-MM-DD, HH:mm:ss';

export {
  CBH_URLS,
  BASE_API_URL,
  BASE_AUTH_URL,
  AUTH_URLS,
  USER_INFO_URL,
  FOUNDED_CUSTOMER_ID,
  ENV,
  SET_CUSTOMER_ON_START,
  DEFAULT_CANCELLATION_REASON,
  DEFAULT_CANCELLATION_TYPE,
  DATE_FORMAT_TEMPLATE,
};

export const paymentsMethodsRegistry: { [key: number]: string } = {
  1: 'card',
  2: 'paypal',
  3: 'venmo',
  4: 'apple pay',
  5: 'google pay',
  6: 'google store',
  7: 'google play billing',
};

export const ADMIN_ALLOWED_ORIGINS = [
  'https://developer.paltabrain.com',
  'https://developer.dev.paltabrain.com',
  'http://localhost:3000',
];

export const SEARCH_CUSTOMERS_TRANSACTIONS_TIMEOUT = 35000;
