import { ColumnDef } from '@tanstack/react-table';

import CBHTable from './CBHTable';
import { FreebieModal } from './modals/FreebieModal';
import { Button } from '../common/Button';
import { freebiesPricePointType } from '../types';

export const ProductsWithFreebieRow = ({
  products,
}: {
  products: Array<freebiesPricePointType>;
}) => {
  const columns: ColumnDef<freebiesPricePointType>[] = [
    {
      header: 'Sku',
      cell: ({ row }) => {
        return <div>{row.original.productSku}</div>;
      },
    },
    {
      header: 'Name',
      cell: ({ row }) => {
        return <div>{row.original.name}</div>;
      },
    },
    {
      header: 'Period',
      cell: ({ row }) => {
        let nextPlan = '';
        const ending =
          row.original.introPeriodValue && row.original.introPeriodValue > 1
            ? 's'
            : '';

        if (row.original.introPeriodValue && row.original.introPeriodType) {
          nextPlan = `${row.original.introPeriodValue} ${row.original.introPeriodType}${ending}`;
        }
        return <div>{nextPlan}</div>;
      },
    },
    {
      accessorKey: 'add freebie',
      header: '',
      cell: ({ row }) => {
        const onClick = () => {};
        return (
          <>
            <FreebieModal
              cta_label={
                <Button
                  key={row.original.id}
                  label="ADD FREEBIE"
                  onClick={onClick}
                  disabled={false}
                />
              }
              cta_styling={'no-styling'}
              pricePointIdent={row.original.ident}
            />
          </>
        );
      },
    },
  ];

  const renderSubComponent = () => <div>renderSubComponent</div>;

  return (
    <CBHTable
      data={products}
      columns={columns}
      getRowCanExpand={() => true}
      renderSubComponent={renderSubComponent}
      tHeadStyles={{ textWrap: 'nowrap' }}
      dataTestID={'e2e-products-with-freebie'}
    />
  );
};
