import client from '@palta-brain/analytics';
import Decimal from 'decimal.js';
import Long from 'long';

import { paltabrain } from './proto';

const saveContext = client.saveContext;
const isInitiatedAssert = client.isInitiatedAssert;
const batcher = client.batcher;
const validate = client.validate;
const logger = client.logger;
const showLogs = client.showLogs;

const enums = {};

const context = {
  /**
   * Application context
   * @function
   * @name setApplication
   * @param {Object} [data] - The data object
   * @param {string} [data.appId]  Application ID
   * @param {string} [data.appVersion]  Application version
   * @param {string} [data.appPlatform]  Application platform
   * @property {string} [data.appId]  Application ID
   * @property {string} [data.appVersion]  Application version
   * @property {string} [data.appPlatform]  Application platform
   * @returns {void}
   */
  setApplication(data) {
    try {
      validate(
        data,
        {
          name: 'setApplication',
        },
        {
          app_id: { id: 1, type: 'string', description: 'Application ID' },
          app_version: {
            id: 2,
            type: 'string',
            description: 'Application version',
          },
          app_platform: {
            id: 3,
            type: 'string',
            description: 'Application platform',
          },
        }
      );
      saveContext(data, 'application');
    } catch (e) {
      logger(e);
    }
  },

  /**
   * Device context
   * @function
   * @name setDevice
   * @param {Object} [data] - The data object
   * @param {string} [data.deviceBrand]  Device brand
   * @param {string} [data.deviceModel]  Device model
   * @param {string} [data.deviceCarrier]  Device carrier
   * @property {string} [data.deviceBrand]  Device brand
   * @property {string} [data.deviceModel]  Device model
   * @property {string} [data.deviceCarrier]  Device carrier
   * @returns {void}
   */
  setDevice(data) {
    try {
      validate(
        data,
        {
          name: 'setDevice',
        },
        {
          device_brand: { id: 1, type: 'string', description: 'Device brand' },
          device_model: { id: 2, type: 'string', description: 'Device model' },
          device_carrier: {
            id: 3,
            type: 'string',
            description: 'Device carrier',
          },
        }
      );
      saveContext(data, 'device');
    } catch (e) {
      logger(e);
    }
  },

  /**
   * Identifiers context
   * @function
   * @name setIdentify
   * @param {Object} [data] - The data object
   * @param {string} [data.idfa]  Apple Identifier for Advertisers
   * @param {string} [data.idfv]  Apple Identifier for Vendors
   * @param {string} [data.gaid]  Google Advertising Identifier
   * @property {string} [data.idfa]  Apple Identifier for Advertisers
   * @property {string} [data.idfv]  Apple Identifier for Vendors
   * @property {string} [data.gaid]  Google Advertising Identifier
   * @returns {void}
   */
  setIdentify(data) {
    try {
      validate(
        data,
        {
          name: 'setIdentify',
        },
        {
          idfa: {
            id: 1,
            type: 'string',
            description: 'Apple Identifier for Advertisers',
          },
          idfv: {
            id: 2,
            type: 'string',
            description: 'Apple Identifier for Vendors',
          },
          gaid: {
            id: 3,
            type: 'string',
            description: 'Google Advertising Identifier',
          },
        }
      );
      saveContext(data, 'identify');
    } catch (e) {
      logger(e);
    }
  },

  /**
   * Operating system context
   * @function
   * @name setOs
   * @param {Object} [data] - The data object
   * @param {string} [data.osName]  OS name
   * @param {string} [data.osVersion]  OS version
   * @property {string} [data.osName]  OS name
   * @property {string} [data.osVersion]  OS version
   * @returns {void}
   */
  setOs(data) {
    try {
      validate(
        data,
        {
          name: 'setOs',
        },
        {
          os_name: { id: 1, type: 'string', description: 'OS name' },
          os_version: { id: 2, type: 'string', description: 'OS version' },
        }
      );
      saveContext(data, 'os');
    } catch (e) {
      logger(e);
    }
  },

  /**
   * User context
   * @function
   * @name setUser
   * @param {Object} [data] - The data object
   * @param {string} [data.userId]
   * @property {string} [data.userId]
   * @returns {void}
   */
  setUser(data) {
    try {
      validate(
        data,
        {
          name: 'setUser',
        },
        { user_id: { id: 1, type: 'string' } }
      );
      saveContext(data, 'user');
    } catch (e) {
      logger(e);
    }
  },

  /**
   *
   * @function
   * @name setZendesk
   * @param {Object} [data] - The data object
   * @param {string} [data.zendeskTicketId]
   * @property {string} [data.zendeskTicketId]
   * @returns {void}
   */
  setZendesk(data) {
    try {
      validate(
        data,
        {
          name: 'setZendesk',
        },
        { zendesk_ticket_id: { id: 1, type: 'string' } }
      );
      saveContext(data, 'zendesk');
    } catch (e) {
      logger(e);
    }
  },

  /**
   *
   * @function
   * @name setBrowser
   * @param {Object} [data] - The data object
   * @param {string} [data.browserUserAgent]
   * @param {string} [data.browserReferrer]
   * @param {Long} [data.browserWindowHeight]
   * @param {Long} [data.browserWindowWidth]
   * @property {string} [data.browserUserAgent]
   * @property {string} [data.browserReferrer]
   * @property {Long} [data.browserWindowHeight]
   * @property {Long} [data.browserWindowWidth]
   * @returns {void}
   */
  setBrowser(data) {
    try {
      validate(
        data,
        {
          name: 'setBrowser',
        },
        {
          browser_user_agent: { id: 1, type: 'string' },
          browser_referrer: { id: 2, type: 'string' },
          browser_window_height: { id: 3, type: 'integer' },
          browser_window_width: { id: 4, type: 'integer' },
        }
      );
      saveContext(data, 'browser');
    } catch (e) {
      logger(e);
    }
  },
};

const events = {
  Core: {
    /**
     * @typedef {object}SessionStartReturnObject
     * @property {() => SessionStartReturnObject} log - Logs the event data to the console and returns the event object.
     * @property {() => void} track - Sends the event to the batcher for processing.
     */
    /**
     *
     * @function
     * @name SessionStart
     * @param {Object} [data] - The data object
     * @returns {SessionStartReturnObject | undefined} An object containing the following methods:
     */
    SessionStart(data = {}) {
      const headers = {};
      try {
        isInitiatedAssert();
        const data = {};
        validate(data, { name: 'SessionStart' }, undefined);
        return {
          track() {
            if (showLogs()) {
              console.log(
                'Event',
                'SessionStart',
                'Data',
                data,
                'Headers',
                headers
              );
            }
            batcher.processEvent({ data, eventId: 1, headers });
            return null;
          },
          log() {
            console.log('Event Data', data, 'Event Headers', headers);
            return this;
          },
        };
      } catch (e) {
        logger(e);
      }
    },
  },

  Logical: {
    /**
     * @typedef {object}PageOpenReturnObject
     * @property {() => PageOpenReturnObject} log - Logs the event data to the console and returns the event object.
     * @property {() => void} track - Sends the event to the batcher for processing.
     */
    /**
     *
     * @function
     * @name PageOpen
     * @param {Object} [data] - The data object
     * @param {string} [data.title]
     * @property {string} [data.title]
     * @returns {PageOpenReturnObject | undefined} An object containing the following methods:
     */
    PageOpen(data = {}) {
      const headers = {};
      try {
        isInitiatedAssert();

        validate(
          data,
          { name: 'PageOpen' },
          { title: { id: 1, type: 'string', description: 'Page title' } }
        );
        return {
          track() {
            if (showLogs()) {
              console.log(
                'Event',
                'PageOpen',
                'Data',
                data,
                'Headers',
                headers
              );
            }
            batcher.processEvent({ data, eventId: 2, headers });
            return null;
          },
          log() {
            console.log('Event Data', data, 'Event Headers', headers);
            return this;
          },
        };
      } catch (e) {
        logger(e);
      }
    },
  },

  Ux: {
    /**
     * @typedef {object}ButtonClickReturnObject
     * @property {() => ButtonClickReturnObject} log - Logs the event data to the console and returns the event object.
     * @property {() => void} track - Sends the event to the batcher for processing.
     */
    /**
     *
     * @function
     * @name ButtonClick
     * @param {Object} [data] - The data object
     * @param {string} [data.action]
     * @property {string} [data.action]
     * @returns {ButtonClickReturnObject | undefined} An object containing the following methods:
     */
    ButtonClick(data = {}) {
      const headers = {};
      try {
        isInitiatedAssert();

        validate(
          data,
          { name: 'ButtonClick' },
          { action: { id: 1, type: 'string' } }
        );
        return {
          track() {
            if (showLogs()) {
              console.log(
                'Event',
                'ButtonClick',
                'Data',
                data,
                'Headers',
                headers
              );
            }
            batcher.processEvent({ data, eventId: 3, headers });
            return null;
          },
          log() {
            console.log('Event Data', data, 'Event Headers', headers);
            return this;
          },
        };
      } catch (e) {
        logger(e);
      }
    },
  },
};

const keys = {
  url: 'https://telemetry.palta.paltabrain.com',
  key: '2b965a24ecd24cd7bbac1fecbb84f07c',
  port: '',
};

const types = {
  keys,
  events,
  context,
  enums,
};
export default {
  init: (config = {}) => {
    client.init({
      ...config,
      GENERATED_MODULE: {
        GENERATED_TYPE: types,
        GENERATED_PALTABRAIN_PROTO: paltabrain,
      },
    });
  },
  context: {
    getContext: client.getContext,
    ...types.context,
  },
  events: types.events,
  enums: {
    ...types.enums,
  },
  types: {
    Decimal: Decimal,
    Long: Long,
  },
  config: {
    storage: {
      IN_MEMORY: client.PALTALIB_IN_MEMORY_STORAGE_MODE,
      IN_DB: client.PALTALIB_IN_DB_STORAGE_MODE,
    },
  },
};
