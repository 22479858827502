import React, { useContext, useEffect, useState } from 'react';

import ChevronDownIcon from '@heroicons/react/20/solid/ChevronDownIcon';
import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon';
import InformationCircleIcon from '@heroicons/react/20/solid/InformationCircleIcon';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DATE_FORMAT_TEMPLATE_YEAR } from 'api/constants';
import CBHTable from 'components/CBHTable';
import RefundModal from 'components/modals/RefundModal';
import RefundIcon from 'components/RefundIcon';
import { CustomerContext } from 'contexts/CustomerContext';
import { getPricePointPayments } from 'services/customer';
import {
  CustomerContextState,
  PricePointPaymentResponse,
  PricePointPaymentsModalProps,
  PricePointPaymentType,
  ShortPricePoint,
  TransactionLogType,
} from 'types';

import DownLoadReceiptIcon from './DownLoadReceiptIcon';
import ActionButton from './modals/common/buttons';
import PaymentMethod from './PaymentMethod';
import { RefundInfoTooltip } from './RefundInfoTooltip';
import { LoaderWrapper } from './styled';
import { TransactionSubComponent } from './TransactionSubComponent';
import { getPrecisionByCurrencyCode } from '../helpers/numbers';
import { downloadReceiptR } from '../repositories/Search';

const columns: ColumnDef<PricePointPaymentType>[] = [
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => {
      return (
        <button
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer' },
          }}
        >
          {row.getIsExpanded() ? (
            <ChevronDownIcon className={'w-5 h-5 text-gray-500'} />
          ) : (
            <ChevronRightIcon className={'w-5 h-5 text-gray-500'} />
          )}
        </button>
      );
    },
  },
  {
    header: 'Time (UTC)',
    cell: ({ row }) => {
      return (
        <div style={{ minWidth: '100px' }}>
          {row.original.lastTransactionEventTime}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'productName',
    header: 'Product name',
  },
  {
    header: 'Payment Method',
    cell: ({ row }) => {
      const firstTransaction = row.original.firstTransaction;

      return (
        <div style={{ display: 'flex' }}>
          <div>
            {firstTransaction && (
              <PaymentMethod
                paymentMethodId={firstTransaction.paymentMethodId}
                paymentSchema={firstTransaction.paymentSchema}
                cardLast4={firstTransaction.cardLast4}
                paymentAccountId={firstTransaction.paymentAccountId}
                placement={'transaction-log'}
              />
            )}
          </div>
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    header: 'Type',
    cell: ({ row }) => {
      const firstTransactionPspId =
        row.original?.firstTransaction?.pspTransactionId;
      const firstTransactionPopId =
        row.original?.firstTransaction?.popTransactionId;
      const { refundInfos, currencyCode } = row.original;

      const [transactionType] = row.original.transactionLogs.map((l) => {
        if (
          (firstTransactionPspId &&
            l.pspTransactionId === firstTransactionPspId) ||
          (firstTransactionPopId &&
            l.popTransactionId === firstTransactionPopId)
        ) {
          return l.popTransactionType;
        }

        return '-';
      });

      const isRefund = transactionType === 'REFUND';

      return (
        <div>
          <span className={'w-12 h-12'}>{transactionType}</span>

          {isRefund && refundInfos && (
            <span className={'has-tooltip w-12 h-12 relative'}>
              <InformationCircleIcon
                className={
                  'w-12 h-12 text-gray-700 absolute left-[0px] top-[0px] text-xl'
                }
              />
              <RefundInfoTooltip
                refundInfos={refundInfos || []}
                currencyCode={currencyCode || ''}
              />
            </span>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'price',
    header: 'Price',
    cell: ({ row }) => {
      const firstTransaction = row.original.firstTransaction;

      return (
        <div>
          {firstTransaction &&
          firstTransaction.amount &&
          firstTransaction.currencyCode
            ? `${firstTransaction.amount} ${firstTransaction.currencyCode}`
            : 'NA'}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'status',
    header: 'Transaction status',
    cell: ({ row }) => {
      const { transactionLogs } = row.original;

      const transactionStatus = transactionLogs[0]?.transactionStatus || '-';
      const pspResponseCode = transactionLogs[0]?.pspResponseCode || null;

      return (
        <div className={'pt-2 has-tooltip flex flex-row items-center'}>
          {pspResponseCode && transactionStatus === 'DECLINED' && (
            <span
              className={
                'tooltip rounded shadow-lg mb-16 p-1 bg-[#e3e3e3] text-[#333]'
              }
            >
              Reason code: {pspResponseCode}
            </span>
          )}
          {transactionStatus}
        </div>
      );
    },
  },
  {
    accessorKey: 'refund',
    header: '',
    cell: ({ row }) => {
      const { refundedAmount, currencyCode } = row.original;
      const precision = getPrecisionByCurrencyCode(currencyCode);
      let amountToRefund = 0;
      let firstTransaction = null as TransactionLogType | null;

      if (row.original.amountToRefund) {
        amountToRefund = Number(row.original.amountToRefund);
      }

      const refundedAmountNumber = Number(refundedAmount || '0');

      let isSettledTransaction = false;
      if (
        row.original.transactionLogs &&
        row.original.transactionLogs.length &&
        row.original.transactionLogs[0].transactionStatus
      ) {
        firstTransaction = row.original.transactionLogs[0];
        isSettledTransaction = firstTransaction.transactionStatus == 'SETTLED';
      }
      const { setIsLoading, addToast }: CustomerContextState =
        useContext(CustomerContext);
      const downloadReceipt = async () => {
        const wasRefunded = refundedAmount !== 0;

        const createdUtc = wasRefunded
          ? undefined
          : firstTransaction?.createdAtUtc;
        const receipt = await downloadReceiptR(
          createdUtc,
          row.original.id,
          setIsLoading,
          addToast
        );

        if (receipt) {
          const url = window.URL.createObjectURL(new Blob([receipt]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'receipt.zip');
          document.body.appendChild(link);
          link.click();
        }
      };

      return (
        <>
          <RefundModal
            paymentId={row.original.pspPaymentId}
            paymentAmount={amountToRefund + refundedAmountNumber}
            cta_label={<RefundIcon className={'w-8 h-8 text-black-700'} />}
            cta_styling={'no-styling'}
            disabled={
              !amountToRefund ||
              amountToRefund <= 0 ||
              !row.original.isRefundable
            }
            classForIcon="refund-logo-btn"
            updateRefundAmount={null}
            precision={precision}
            refundedAmount={Number(row.original.refundedAmount || '0')}
          />
          <ActionButton
            onClick={downloadReceipt}
            label={<DownLoadReceiptIcon className="w-8 h-8" />}
            skipStyling
            dataTestId="e2e-download-receipt-btn"
            disabled={!isSettledTransaction}
            classForIcon={'download-receipt-logo-btn'}
          />
        </>
      );
    },
    footer: (props) => props.column.id,
  },
];

export default function PricePointPayments({
  pricePoints,
}: PricePointPaymentsModalProps) {
  const customerContext = useContext(CustomerContext);
  const [pricePointPayments, setPricePointPayments] = useState<
    Array<PricePointPaymentType>
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { customerId, customerType } = customerContext;

  const fetchPayments = async (
    customerId: string,
    customerType: string,
    pricePoints: Array<ShortPricePoint>
  ) => {
    setIsLoading(true);
    const result: PricePointPaymentResponse = await getPricePointPayments(
      customerId,
      customerType,
      pricePoints
    );

    if (result?.data) {
      const processedData = result.data.map(
        ({
          createdAt,
          updatedAt,
          transactionLogs,
          lastTransactionEventTime,
          ...subscription
        }: PricePointPaymentType) => {
          const logsData = transactionLogs.map((event) => ({
            ...event,
            createdAt: event.createdAtUtc,
          }));

          return {
            ...subscription,
            createdAt: createdAt,
            updatedAt: updatedAt
              ? dayjs(updatedAt).format(DATE_FORMAT_TEMPLATE_YEAR)
              : updatedAt,
            lastTransactionEventTime: lastTransactionEventTime
              ? dayjs(lastTransactionEventTime).format(
                  DATE_FORMAT_TEMPLATE_YEAR
                )
              : lastTransactionEventTime,
            transactionLogs: logsData,
          };
        }
      );

      setPricePointPayments(processedData);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (customerId && customerType && pricePoints) {
      (async () => {
        await fetchPayments(customerId, customerType, pricePoints);
      })();
    }
  }, [customerId, pricePoints]);

  return (
    <>
      {isLoading && (
        <LoaderWrapper data-test-id="price-point-payments-loader" />
      )}
      {!isLoading && pricePointPayments.length > 0 && (
        <CBHTable
          data={pricePointPayments}
          columns={columns}
          getRowCanExpand={() => true}
          renderSubComponent={TransactionSubComponent}
          dataTestID={'e2e-customer-transactions-table'}
        />
      )}
      {!isLoading && !pricePointPayments.length && (
        <div className={'text-gray-500'} style={{ textAlign: 'center' }}>
          No Payments
        </div>
      )}
    </>
  );
}
