import { MouseEvent, useCallback, useContext, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { MerchantLogo } from './MerchantLogo';
import { ClickOutside } from '../components/ClickOutside';
import { AuthContext } from '../contexts/AuthContext';
import { SearchContext } from '../contexts/SearchContext';
import { isAuth0Provider } from '../services/auth';
import { removeCookies, setActiveMerchantId } from '../services/cookie';

export const UserInfo = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [isOpened, setIsOpened] = useState(false);

  const isAuth0 = isAuth0Provider();

  const { cleanSearch } = useContext(SearchContext);

  const handleOpen = () => {
    setIsOpened((isOpened) => !isOpened);
  };

  const closeMenu = useCallback(() => {
    setIsOpened(false);
  }, []);

  let menuClass =
    'absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ' +
    'ring-1 ring-black ring-opacity-5 focus:outline-none';
  menuClass += isOpened
    ? 'transition ease-out duration-100 transform opacity-100 scale-100 visible'
    : 'transition ease-in duration-75 transform opacity-0 scale-95 invisible';

  const handleWorkspaceChange = useCallback(
    (workspaceId: number) => {
      if (!user) {
        return;
      }

      setActiveMerchantId(workspaceId);

      const activeWorkspaceName =
        user.workspaces.find((workspace) => workspace.id === workspaceId)
          ?.name || 'not specified';

      setUser({
        ...user,
        activeWorkspace: workspaceId,
        activeWorkspaceName: activeWorkspaceName,
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      cleanSearch();

      navigate('/search', { replace: true, state: { search: '' } });
    },
    [user]
  );

  const onClickLogout = useCallback(async (event: MouseEvent<HTMLElement>) => {
    const { origin } = window.location;
    event.preventDefault();
    localStorage.clear();
    removeCookies();
    await logout({
      logoutParams: { returnTo: `${origin}/login` },
    });
  }, []);

  return (
    user && (
      <ClickOutside onClickOutside={closeMenu}>
        <div className="relative">
          <div className="text-left absolute right-0">
            <div>
              <button
                type="button"
                className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={handleOpen}
              >
                <div className="flex">
                  <MerchantLogo merchantName={user.activeWorkspaceName} />
                  <div className="flex flex-col">
                    <div>{user.username}</div>
                    <div className={'font-bold text-left'}>
                      {user.activeWorkspaceName}
                    </div>
                  </div>
                </div>

                <svg
                  className="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div
              className={menuClass}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex={-1}
            >
              <div className="py-1" role="none">
                <div className={'px-4 py-2 text-sm'}>Signed in as</div>
                <div className={'px-4 py-1 text-sm'}>
                  <span className={'font-bold'}>{user.username}</span>
                </div>
              </div>

              {isAuth0 && (
                <div className="py-1" role="none">
                  <div className={'px-4 py-2 text-sm font-bold'}>
                    Switch workspace to:
                  </div>

                  {user.workspaces.map((workspace) => (
                    <a
                      href="#"
                      className={
                        'text-gray-700 block px-4 py-2 text-sm' +
                        (workspace.id === user.activeWorkspace
                          ? ' font-bold'
                          : '')
                      }
                      role="menuitem"
                      tabIndex={-1}
                      id="menu-item-0"
                      key={workspace.id}
                      onClick={() => {
                        handleWorkspaceChange(workspace.id);
                      }}
                    >
                      {workspace.name}
                    </a>
                  ))}
                </div>
              )}

              {user.username && (
                <div className="py-1" role="none">
                  <a
                    href="#"
                    className="text-gray-700 block px-4 py-2 text-sm"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-4"
                    onClick={onClickLogout}
                    key={'logout'}
                  >
                    Logout
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </ClickOutside>
    )
  );
};
