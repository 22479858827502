import { ReactElement, useContext } from 'react';

import { CustomerContext } from 'contexts/CustomerContext';

export default function PersonalInfo(): ReactElement {
  const customerContext = useContext(CustomerContext);

  return (
    <div
      className={'container mx-auto pt-10 border-r-2'}
      data-test-id={'e2e-customer-details'}
    >
      <div>
        <div className={'ml-4 px-4 sm:px-0'}>
          <h3 className={'text-base font-semibold leading-7 text-gray-900'}>
            Customer Details:
          </h3>
        </div>
        <div className={'mt-6 border-t border-gray-100'}>
          <div className={'divide-y divide-gray-100'}>
            <div
              className={
                'ml-4 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'
              }
            >
              <div className={'text-sm font-medium leading-6 text-gray-900'}>
                <span
                  className={
                    'block overflow-hidden whitespace-nowrap text-ellipsis  align-middle'
                  }
                >
                  Requester Email
                </span>
              </div>
              <div
                className={
                  'mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'
                }
              >
                <span
                  className={
                    'block overflow-hidden whitespace-nowrap text-ellipsis  align-middle'
                  }
                  data-test-id={'e2e-ustomer-email'}
                >
                  {customerContext.customerEmail}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
