import { Dialog } from '@headlessui/react';

import ActionButton from 'components/modals/common/buttons';
import { ConfirmationScreenProps } from 'types';

export default function ConfirmationScreen({
  onConfirm,
  onCancel,
  isLoading,
  confirmationMessage,
  title = 'Confirm Action',
  confirmCTACopy = 'Confirm',
  dismissCTACopy = 'Dismiss',
}: ConfirmationScreenProps) {
  return (
    <>
      <div className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}>
        <div className={'sm:flex sm:items-start'}>
          <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
            <Dialog.Title
              as="h3"
              className={'text-base font-semibold leading-6 text-gray-900'}
            >
              {title}
            </Dialog.Title>

            <div className={'mt-2'}>
              <div className={'pt-4 pb-4'}>
                <p>{confirmationMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          'bg-gray-50 gap-x-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'
        }
      >
        <ActionButton
          dataTestId="e2e-confirm-unlink-btn"
          onClick={onConfirm}
          disabled={isLoading}
          label={confirmCTACopy}
        />
        <ActionButton
          dataTestId="e2e-dismiss-unlink-btn"
          onClick={onCancel}
          label={dismissCTACopy}
        />
      </div>
    </>
  );
}
