import { styled } from 'styled-components';

export const ButtonStyled = styled.button`
  background-color: rgb(37 99 235);
  color: rgb(255 255 255);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 7px 10px;
  background-image: none;
  border-radius: 0.5rem;
  margin-bottom: 8px;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:first-child {
    margin-top: 8px;
  }
`;

export const ButtonSecondary = styled(ButtonStyled)`
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: rgb(37, 99, 235);
  border: 1px solid rgb(37, 99, 235);
  background-color: transparent;
`;
