import { useContext, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';

import { Button } from 'common/Button';
import { ButtonMenu } from 'components/styled';
import { CustomerContext } from 'contexts/CustomerContext';

import { Feature } from './Feature';
import Modal from './Modal';
import ActionButton from './modals/common/buttons';
import { LoaderContext } from '../contexts/LoaderContext';
import { revokeFeatureR } from '../repositories/FeatureRepository';
import { btnLabelsEnum, featureType } from '../types';

type FeatureRowProps = {
  feature: featureType;
};

type sideMenuType = {
  [key in Extract<btnLabelsEnum, btnLabelsEnum.revokeFeature>]: {
    onClick: () => void;
  };
};

type btnActionsAvailabilityType = {
  [key: string]: boolean;
};

const defaultBtnActionsAvailability: btnActionsAvailabilityType = {
  [btnLabelsEnum.revokeFeature]: false,
  [btnLabelsEnum.changeGrantingDate]: false,
  [btnLabelsEnum.addFeature]: false,
};

export function FeatureRow(props: FeatureRowProps) {
  const { feature } = props;
  const [btnActionsAvailability, setBtnActionsAvailability] = useState(
    defaultBtnActionsAvailability
  );
  const [revokeModalOpen, setRevokeModalOpen] = useState(false);
  const { customerId, customerType } = useContext(CustomerContext);

  const { setIsLoading, addToast } = useContext(LoaderContext);

  const revokeFeature = async () => {
    setRevokeModalOpen(false);
    const isFeatureRevoked: boolean | undefined = await revokeFeatureR(
      feature.id,
      customerId,
      customerType,
      setIsLoading,
      addToast
    );
    if (isFeatureRevoked) {
      setBtnActionsAvailability((state) => ({
        ...state,
        [btnLabelsEnum.revokeFeature]: true,
        [btnLabelsEnum.changeGrantingDate]: true,
      }));
    }
  };
  const sideMenuBtn: sideMenuType = {
    [btnLabelsEnum.revokeFeature]: {
      onClick() {
        setRevokeModalOpen(true);
      },
    },
  };

  return (
    <div
      className={
        'flex border-b-gray-500 border mt-2 justify-around align-center'
      }
      data-test-id={'e2e-features-row'}
    >
      <div className={' w-1/4 items-center flex '}>
        <div
          className={' w-full'}
          style={{
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '16px',
            letterSpacing: '1px',
          }}
        >
          <span
            className={'font-bold min-h-full inline-block flex-col'}
            data-test-id={'e2e-feature-name'}
          >
            {feature.name}
          </span>
        </div>
      </div>
      <Feature feature={feature} />
      <Modal
        isOpen={revokeModalOpen}
        onClose={() => setRevokeModalOpen(false)}
        dataTestID="e2e-revoke-feature-modal"
      >
        <div className="p-4 relative">
          <XMarkIcon
            className="absolute top-4 right-4 h-6 w-6 text-indigo-500 hover:text-indigo-400 cursor-pointer"
            onClick={() => setRevokeModalOpen(false)}
          />
          <h2>Are you sure you want to revoke the feature?</h2>
          <div className="flex flex-row gap-4 mt-4 items-center justify-end">
            <ActionButton
              dataTestId="e2e-dismiss-revoke-btn"
              onClick={() => setRevokeModalOpen(false)}
              label="Dismiss"
            />
            <ActionButton
              dataTestId="e2e-revoke-feature-btn"
              onClick={revokeFeature}
              label={'Revoke'}
            />
          </div>
        </div>
      </Modal>
      <ButtonMenu>
        {Object.entries(sideMenuBtn).map(([btnLabel, btnData]) => {
          const disabled = btnActionsAvailability[btnLabel];
          return (
            <Button
              key={btnLabel}
              label={btnLabel}
              onClick={btnData.onClick}
              disabled={disabled}
            />
          );
        })}
      </ButtonMenu>
    </div>
  );
}
