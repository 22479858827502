import React, {
  createContext,
  createRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  CustomerContextProps,
  customersType,
  SearchContextState,
  SearchResultsType,
  SearchTransactionLog,
} from 'types';

import { useLocalStorage } from './AuthContext';
import { ZendeskIframeMessageEvent } from '../interfaces';

const ZENDESK_ORIGIN = 'apps.zdusercontent.com';

export const defaultSearchContextState = {
  isDisabled: true,
  setIsDisabled: () => {},
  previousSearch: '',
  setPreviousSearch: () => {},
  lastSearchResultClean: '',
  setLastSearchResultClean: () => {},
  searchResults: null,
  setSearchResults: () => {},
  queryStringSearchParams: new URLSearchParams(),
  setQueryStringSearchParams: () => {},
  cleanSearch: () => {},
  customers: [],
  setCustomers: () => {},
  customersTransactions: [],
  setCustomersTransactions: () => {},
  formRef: createRef<HTMLFormElement>(),
  search: '',
  setSearch: () => {},
};

export const SearchContext = createContext<SearchContextState>(
  defaultSearchContextState
);

export const SearchContextProvider = ({
  children,
}: CustomerContextProps): ReactElement => {
  const [isDisabled, setIsDisabled] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [previousSearch, setPreviousSearch] = useLocalStorage(
    'previousSearch',
    ''
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [lastSearchResultClean, setLastSearchResultClean] = useLocalStorage(
    'lastSearchResultClean',
    ''
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [searchResults, setSearchResults] =
    useLocalStorage<SearchResultsType | null>('searchResults', null);

  const [queryStringSearchParams, setQueryStringSearchParams] =
    useSearchParams();

  const [customers, setCustomers] = useState<Array<customersType>>([]);

  const [customersTransactions, setCustomersTransactions] = useState<
    Array<SearchTransactionLog>
  >([]);

  const [search, setSearch] = useState('');

  const formRef = useRef<HTMLFormElement>(null);
  const handleIframeMessage = (event: ZendeskIframeMessageEvent) => {
    if (event.origin.includes(ZENDESK_ORIGIN)) {
      setPreviousSearch(event.data.zendesk.customer_email);
      setSearch(event.data.zendesk.customer_email);
      setQueryStringSearchParams({
        search: event.data.zendesk.customer_email,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage, false);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const cleanSearchParams = useCallback(() => {
    setPreviousSearch('');
    setLastSearchResultClean('');
    setSearchResults(null);
    setQueryStringSearchParams(new URLSearchParams());

    setCustomers([]);
    setCustomersTransactions([]);
    setSearch('');

    if (formRef.current) {
      formRef.current.reset();
    }

    setIsDisabled(false);
  }, []);

  const getContextValue = () => {
    return {
      isDisabled: isDisabled,
      setIsDisabled: setIsDisabled,
      previousSearch: previousSearch,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setPreviousSearch: setPreviousSearch,
      lastSearchResultClean: lastSearchResultClean,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setLastSearchResultClean: setLastSearchResultClean,
      searchResults: searchResults,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      setSearchResults: setSearchResults,
      queryStringSearchParams: queryStringSearchParams,
      setQueryStringSearchParams: setQueryStringSearchParams,
      cleanSearch: cleanSearchParams,
      customers: customers,
      setCustomers: setCustomers,
      customersTransactions: customersTransactions,
      setCustomersTransactions: setCustomersTransactions,
      formRef: formRef,
      search: search,
      setSearch: setSearch,
    };
  };

  const value = useMemo(
    () => getContextValue(),
    [
      isDisabled,
      setIsDisabled,
      previousSearch,
      setPreviousSearch,
      lastSearchResultClean,
      setLastSearchResultClean,
      searchResults,
      setSearchResults,
      queryStringSearchParams,
      setQueryStringSearchParams,
    ]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
