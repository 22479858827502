import { Dispatch, SetStateAction } from 'react';

import { AddToast } from 'react-toast-notifications';

import {
  addFreebie,
  getCustomerPaymentMethods,
  refundPayment,
  unlinkPaymentMethod,
} from 'services/customer';
import {
  addFreebieResponse,
  customerTypeVariants,
  paymentMethodType,
} from 'types';

import { requestsWrapper } from './requestsWrapper';

export const getPaymentMethods = async (
  customerId: string,
  customerType: customerTypeVariants,
  setIsLoading: Dispatch<SetStateAction<boolean>>
): Promise<Array<paymentMethodType> | null> => {
  try {
    setIsLoading(true);

    const result = await getCustomerPaymentMethods({
      customerId,
      customerType,
    });
    return result.data.paymentMethods;
  } catch (error) {
    return null;
  } finally {
    setIsLoading(false);
  }
};

export const unlinkPaymentMethodR = async (
  customerId: string,
  customerType: string,
  paymentMethodToken: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
): Promise<boolean | undefined> => {
  const successMessage = 'The payment method was successfully unlinked.';

  const result = await requestsWrapper(
    unlinkPaymentMethod,
    {
      customerId: customerId,
      customerType: customerType,
      paymentMethodToken: paymentMethodToken,
    },
    setIsLoading,
    addToast,
    successMessage
  );
  if (result) {
    return true;
  }
};

export const refundPaymentR = async (
  paymentId: string,
  refundAmount: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast,
  isSoftRefund: boolean,
  reason: string | null
): Promise<boolean | undefined> => {
  const successMessage = 'The payment was successfully refunded.';

  const result = await requestsWrapper(
    refundPayment,
    {
      paymentId: paymentId,
      amount: refundAmount,
      isSoftRefund,
      reason,
    },
    setIsLoading,
    addToast,
    successMessage
  );
  if (result) {
    return true;
  }
};
export const addFreebieR = async ({
  customerId,
  customerType,
  pricePointIdent,
  setIsLoading,
  addToast,
}: {
  customerId: string;
  customerType: customerTypeVariants;
  pricePointIdent: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  addToast: AddToast;
}): Promise<addFreebieResponse | undefined> => {
  const successMessage = 'Freebie was added.';

  const result = await requestsWrapper(
    addFreebie,
    {
      pricePointIdent,
      customerId,
      customerType,
    },
    setIsLoading,
    addToast,
    successMessage
  );

  if (result?.data) {
    return result?.data;
  }

  return undefined;
};
