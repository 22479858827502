import { useState, useContext, useEffect } from 'react';

import { Tabs } from 'flowbite-react';

import CustomerSubscriptions from 'components/CustomerSubscriptions';

import TransactionsSubscriptions from './TransactionsSubscriptions';
import { CustomerContext } from '../contexts/CustomerContext';
import { getPricePoints } from '../services/customer';
import { pricePointType } from '../types';

const tabsTheme = {
  base: 'flex flex-col gap-2',
  tablist: {
    base: 'flex text-center',
    styles: {
      fullWidth:
        'text-sm font-medium rounded-lg divide-x divide-gray-200 shadow sm:flex ',
    },
    tabitem: {
      base: 'flex-auto w-1/2 flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 ',
      styles: {
        fullWidth: {
          base: 'ml-2 first:ml-0 w-full first:rounded-l-lg last:rounded-r-lg',
          active: {
            on: 'inline-block p-4 text-gray-900 bg-gray-100 focus:ring-4 focus:ring-blue-300 active focus:outline-none',
            off: 'bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none',
          },
        },
      },
      icon: 'mr-2 h-5 w-5',
    },
  },
  tabpanel: 'p-4',
};

export default function ProductFeatures() {
  const customerContext = useContext(CustomerContext);
  const { customerId, customerType } = customerContext;

  const [pricePoints, setPricePoints] = useState<Array<pricePointType>>([]);

  const fetchPricePoints = async (customerId: string, customerType: string) => {
    const result = await getPricePoints(customerId, customerType);

    if (result?.data) {
      setPricePoints(result.data);
    }
  };

  useEffect(() => {
    if (customerId && customerType) {
      (async () => {
        await fetchPricePoints(customerId, customerType);
      })();
    }
  }, [customerId, customerType]);

  if (pricePoints && pricePoints.length > 0) {
    return (
      <Tabs.Group
        aria-label="Full width tabs"
        style="fullWidth"
        theme={tabsTheme}
        data-test-id={'e2e-subscriptions-transactions-tabs'}
      >
        <Tabs.Item title="Subscriptions">
          <CustomerSubscriptions pricePoints={pricePoints} />
        </Tabs.Item>
        <Tabs.Item title="Transactions">
          <TransactionsSubscriptions pricePoints={pricePoints} />
        </Tabs.Item>
      </Tabs.Group>
    );
  }

  return <></>;
}
