import { AddToast } from 'react-toast-notifications';

import { requestsWrapper } from './requestsWrapper';
import { revokeFeature } from '../services/customer';
import { customerTypeVariants } from '../types';

export const revokeFeatureR = async (
  featureId: number,
  customerId: string,
  customerType: customerTypeVariants,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  addToast: AddToast
): Promise<boolean | undefined> => {
  const successMessage = 'The feature was revoked successfully';

  const result = await requestsWrapper(
    revokeFeature,
    {
      featureId,
      customerId,
      customerType,
    },
    setIsLoading,
    addToast,
    successMessage
  );
  if (result) {
    return true;
  }
};
