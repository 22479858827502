import { ReactElement, useCallback, useContext, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  AuthContext,
  AuthContextState,
  defaultApplicationUser,
  useLocalStorage,
} from 'contexts/AuthContext';
import { LocationState } from 'types';

import { Loader } from '../components/Loader';
import { LoaderContext } from '../contexts/LoaderContext';
import { getPBPUserR } from '../repositories/User';

export default function Auth0Callback(): ReactElement {
  const { setUser } = useContext<AuthContextState>(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const state = location.state as LocationState;
  const { user, isAuthenticated, isLoading } = useAuth0();
  let previousPathname: string = state?.from?.pathname || '/search';

  const [localUser] = useLocalStorage('user', defaultApplicationUser);

  const { setIsLoading, addToast } = useContext(LoaderContext);

  if (previousPathname === '/' || previousPathname === '') {
    previousPathname = '/search';
  }

  const redirectUserToPreviousLocation = useCallback(() => {
    if (previousPathname) {
      navigate(previousPathname, { replace: true });
    }
  }, [previousPathname]);

  useEffect(() => {
    console.log('Auth0Callback, localUser:', localUser);
    setIsLoading(true);

    if (isLoading) {
      return;
    }

    if (localUser === null && isAuthenticated) {
      localStorage.setItem('authProvider', 'auth0');

      (async () => {
        await getPBPUserR(
          setUser,
          setIsLoading,
          redirectUserToPreviousLocation,
          addToast
        );
      })();
    } else if (localUser !== null && isAuthenticated) {
      navigate(previousPathname, { replace: true });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      const error = searchParams.get('error');
      const errorMessage = searchParams.get('error_description');

      if (error && errorMessage) {
        addToast(errorMessage, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      navigate('/login', { replace: true });
    }
  }, [user, isLoading, isAuthenticated, localUser]);

  return (
    <div
      className={
        'flex flex-col items-center px-6 pt-8 mx-auto md:h-screen pt:mt-0'
      }
    >
      <div
        className={
          'w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow'
        }
      >
        <Loader />
      </div>
    </div>
  );
}
