import React, { ReactElement, useContext, useState } from 'react';

import { Dialog } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'components/Modal';
import ActionButton from 'components/modals/common/buttons';

import MoveRenewalDate from './common/MoveRenewalDate';
import PauseSubscription from './common/PauseSubscription';
import RenewalDiscount from './common/RenewalDiscount';
import RestartSubscription from './common/RestartSubscription';
import { CustomerContext } from '../../contexts/CustomerContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { ManageIcon } from '../../pages/ManageIcon';
import {
  executeSubscriptionHardResetR,
  executeSubscriptionSoftResetR,
  pauseSubscriptionR,
  renewDateSubscription,
  setRenewalDiscountR,
} from '../../repositories/Subscription';
import { SubscriptionTags } from '../../types';

interface CancelSubscriptionModalProps {
  paymentId: string | undefined;
  subscriptionId: string;
  btnIcon?: ReactElement;
  cta_styling: 'default' | 'no-styling';
  productName?: string;
  nextRenewCheck?: string | null;
  expirationDate?: string | null;
  refundAmount?: string;
  updateRenewalDate: (date: Date | string, subscriptionId: string) => void;
  updateRefundAmount:
    | ((transactionId: string, amount: number, precision: number) => void)
    | null;
  nextPlan?: string | null;
  nextPrice?: string | null;
  currencyCode?: string | null;
  subscriptionTags?: SubscriptionTags[] | undefined;
}

const ManageSubscriptionModal = (props: CancelSubscriptionModalProps) => {
  const [isOpenedOptionsModal, setIsOpenedOptionsModal] = useState(false);
  const [isOpenRenewDateSubscription, setIsRenewDateSubscription] =
    useState(false);
  const [isOpenPauseSubscription, setIsOpenPauseSubscription] = useState(false);
  const [isOpenRenewalDiscount, setIsOpenRenewalDiscount] = useState(false);
  const [isOpenRestartSubscription, setIsOpenRestartSubscription] =
    useState(false);
  const { isLoading, setIsLoading, addToast } = useContext(LoaderContext);
  const { refetchFeatures } = useContext(CustomerContext);

  const {
    cta_styling,
    nextRenewCheck,
    subscriptionId,
    updateRenewalDate,
    productName,
    nextPlan,
    nextPrice,
    currencyCode,
    subscriptionTags,
    expirationDate,
  } = props;

  const isMoveRenewalDateDisabled =
    subscriptionTags?.includes(SubscriptionTags.isExpired) ||
    subscriptionTags?.includes(SubscriptionTags.isUnsubscribed) ||
    subscriptionTags?.includes(SubscriptionTags.isPaused) ||
    subscriptionTags?.includes(SubscriptionTags.inGrace) ||
    subscriptionTags?.includes(SubscriptionTags.inRetry);

  const isPauseSubscriptionDisabled =
    subscriptionTags?.includes(SubscriptionTags.isExpired) ||
    subscriptionTags?.includes(SubscriptionTags.isUnsubscribed) ||
    subscriptionTags?.includes(SubscriptionTags.isPaused) ||
    subscriptionTags?.includes(SubscriptionTags.inGrace) ||
    subscriptionTags?.includes(SubscriptionTags.isDeferred) ||
    subscriptionTags?.includes(SubscriptionTags.inRetry);

  const isRestartSubscriptionDisabled = !(
    subscriptionTags?.includes(SubscriptionTags.isExpired) ||
    subscriptionTags?.includes(SubscriptionTags.isUnsubscribed)
  );

  const isUnsubscribed = subscriptionTags?.includes(
    SubscriptionTags.isUnsubscribed
  );
  const isExpired = subscriptionTags?.includes(SubscriptionTags.isExpired);

  const moveRenewalDate = async (
    values: Date | string,
    prolongFeatures: boolean
  ) => {
    setIsRenewDateSubscription(false);
    const result = await renewDateSubscription(
      values,
      subscriptionId,
      prolongFeatures,
      setIsLoading,
      addToast
    );
    if (result) {
      updateRenewalDate(values, subscriptionId);
    }
    refetchFeatures();
  };

  const pauseSubscriptionHandler = async (values: Date | string) => {
    setIsRenewDateSubscription(false);
    const result = await pauseSubscriptionR(
      values,
      subscriptionId,
      setIsLoading,
      addToast
    );
    if (result) {
      updateRenewalDate(values, subscriptionId);
    }
    refetchFeatures();
  };

  const renewalDiscountHandler = async (amount: string) => {
    setIsRenewDateSubscription(false);
    const id = uuidv4();
    await setRenewalDiscountR(
      id,
      amount,
      subscriptionId,
      setIsLoading,
      addToast
    );
  };

  const resetSubscriptionHandler = async () => {
    setIsRenewDateSubscription(false);

    if (isExpired) {
      await executeSubscriptionHardResetR(
        subscriptionId,
        setIsLoading,
        addToast
      );
    } else if (isUnsubscribed) {
      await executeSubscriptionSoftResetR(
        subscriptionId,
        setIsLoading,
        addToast
      );
    }
  };

  const closeRenewalDateModal = () => {
    setIsOpenedOptionsModal(false);
    setIsRenewDateSubscription(false);
  };

  const closePauseSubscriptionModal = () => {
    setIsOpenedOptionsModal(false);
    setIsOpenPauseSubscription(false);
  };

  const closeRenewalDiscountModal = () => {
    setIsOpenedOptionsModal(false);
    setIsOpenRenewalDiscount(false);
  };

  const closeRestartSubscriptionModal = () => {
    setIsOpenedOptionsModal(false);
    setIsOpenRestartSubscription(false);
  };

  return (
    <>
      <div
        className={'has-tooltip'}
        style={{ display: 'flex', alignItems: 'top' }}
      >
        <span
          className={
            'tooltip rounded shadow-lg p-1 -top-2 bg-[#e3e3e3] text-[#333] text-nowrap'
          }
        >
          Manage Subscription
        </span>
        <ActionButton
          onClick={() => setIsOpenedOptionsModal(true)}
          label={<ManageIcon />}
          skipStyling={cta_styling == 'no-styling'}
          dataTestId={'e2e-manage-subscription-icon'}
        />
      </div>
      <Modal
        isOpen={isOpenedOptionsModal}
        onClose={() => setIsOpenedOptionsModal(false)}
        dataTestID="e2e-manage-subscription-modal"
      >
        <>
          <div className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}>
            <button
              onClick={() => setIsOpenedOptionsModal(false)}
              className="absolute top-4 right-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className={'sm:flex sm:items-start'}>
              <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
                <Dialog.Title
                  as="h3"
                  className={'text-base leading-6 text-gray-900'}
                >
                  <div
                    style={{
                      fontSize: '13px',
                      color: 'grey',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Click on the action you want to perform.
                    <br />
                    Please note not all the action might be available
                  </div>
                </Dialog.Title>
              </div>
            </div>
          </div>
          <div
            className={' px-4 sm:flex sm:px-6'}
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '50px',
              paddingTop: '3.75rem',
            }}
          >
            <ActionButton
              dataTestId="e2e-move-renewal-date-icon"
              onClick={() => setIsRenewDateSubscription(true)}
              label={'Move Renewal Date'}
              additionalStyles={{ marginTop: '8px', width: '50%' }}
              disabled={isMoveRenewalDateDisabled}
            />
            <ActionButton
              dataTestId="e2e-pause-subscription-icon"
              onClick={() => setIsOpenPauseSubscription(true)}
              label={'Pause Subscription'}
              additionalStyles={{ marginTop: '8px', width: '50%' }}
              disabled={isPauseSubscriptionDisabled}
            />
            <ActionButton
              dataTestId="e2e-apply-discount-icon"
              onClick={() => setIsOpenRenewalDiscount(true)}
              label={'Apply Discount'}
              additionalStyles={{ marginTop: '8px', width: '50%' }}
              // disabled={!nextRenewCheck}
            />
            <ActionButton
              dataTestId="e2e-restart-subscription-icon"
              onClick={() => setIsOpenRestartSubscription(true)}
              label={'Restart Subscription'}
              additionalStyles={{ marginTop: '8px', width: '50%' }}
              disabled={isRestartSubscriptionDisabled}
            />
          </div>
        </>
      </Modal>
      <Modal
        isOpen={isOpenRenewDateSubscription}
        onClose={() => closeRenewalDateModal()}
        dataTestID="e2e-move-renewal-date-modal"
      >
        {nextRenewCheck && (
          <MoveRenewalDate
            onConfirm={moveRenewalDate}
            isLoading={isLoading}
            onCancel={() => closeRenewalDateModal()}
            confirmCTACopy="Dismiss"
            dismissCTACopy="Move renewal date"
            productName={productName}
            nextRenewCheck={nextRenewCheck}
          />
        )}
      </Modal>
      <Modal
        isOpen={isOpenPauseSubscription}
        onClose={() => closePauseSubscriptionModal()}
        dataTestID="e2e-pause-subscription-modal"
      >
        {nextRenewCheck && (
          <PauseSubscription
            onConfirm={pauseSubscriptionHandler}
            isLoading={isLoading}
            onCancel={() => closePauseSubscriptionModal()}
            confirmCTACopy="Dismiss"
            dismissCTACopy="Pause Subscription"
            productName={productName}
            nextRenewCheck={nextRenewCheck}
          />
        )}
      </Modal>
      <Modal
        isOpen={isOpenRenewalDiscount}
        onClose={() => closePauseSubscriptionModal()}
        dataTestID="e2e-renewal-discount-modal"
      >
        <RenewalDiscount
          onConfirm={renewalDiscountHandler}
          isLoading={isLoading}
          onCancel={() => closeRenewalDiscountModal()}
          confirmCTACopy="Dismiss"
          dismissCTACopy="Apply Discount"
          productName={productName}
          nextRenewCheck={nextRenewCheck}
          nextPlan={nextPlan}
          nextPrice={nextPrice}
          currencyCode={currencyCode}
        />
      </Modal>
      <Modal
        isOpen={isOpenRestartSubscription}
        onClose={() => closeRestartSubscriptionModal()}
        dataTestID="e2e-reset-subscription-modal"
      >
        {expirationDate && (
          <RestartSubscription
            onConfirm={resetSubscriptionHandler}
            isLoading={isLoading}
            onCancel={() => closeRestartSubscriptionModal()}
            confirmCTACopy="Restart Subscription"
            dismissCTACopy="Dismiss"
            expirationDate={expirationDate}
            isExpired={isExpired}
            nextPrice={nextPrice}
            currencyCode={currencyCode}
          />
        )}
      </Modal>
    </>
  );
};
ManageSubscriptionModal.displayName = 'ManageSubscriptionModal';

export default ManageSubscriptionModal;
