import {
  forwardRef,
  memo,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';

import Modal from 'components/Modal';
import ActionButton from 'components/modals/common/buttons';
import { LoaderContext } from 'contexts/LoaderContext';
import {
  cancelSubscriptionFormProps,
  CancelSubscriptionFormValues,
} from 'interfaces';
import {
  cancelSubscriptionR,
  getRefundReasonsR,
} from 'repositories/Subscription';

import { RefundReasonsDictionary } from '../../types';

let refundResultsDictionary: RefundReasonsDictionary;

function RefundForm({ formik, onCancel }: cancelSubscriptionFormProps) {
  const { isSubmitting } = formik;
  const { setIsLoading, addToast, isLoading } = useContext(LoaderContext);
  const [refundReasonsList, setRefundReasonsList] = useState([]);
  const inProgressRequest = useRef<Promise<void> | null>(null);

  useEffect(() => {
    if (!inProgressRequest.current) {
      inProgressRequest.current = (async () => {
        const result = (await getRefundReasonsR(
          setIsLoading,
          addToast
        )) as RefundReasonsDictionary;
        refundResultsDictionary = result;
        const refundList = Object.values(result);
        if (refundList.length) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          setRefundReasonsList(refundList);
        }
        inProgressRequest.current = null;
      })();
    }
  }, []);

  const onClick = () => {
    void formik.submitForm();
  };

  if (!isLoading) {
    return (
      <>
        <div
          className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}
          data-test-id="e2e-refund-modal"
        >
          <div className={'sm:flex sm:items-start'}>
            <button onClick={onCancel} className="absolute top-4 right-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
              <Dialog.Title
                as="h3"
                className={'text-base font-semibold leading-6 text-gray-900'}
              >
                Cancel Subscription Without Refund
              </Dialog.Title>

              <div className={'mt-2'}>
                <div className={'pt-4 pb-4'}>
                  <p>
                    Please select a reason why you want to cancel the
                    subscription
                  </p>
                </div>
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="w-full" style={{ paddingBottom: '10px' }}>
                      <div className="relative">
                        <select
                          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          onChange={formik.handleChange}
                          name="refundReason"
                          value={formik.values.refundReason}
                        >
                          <option
                            value=""
                            disabled
                            selected
                            hidden
                            style={{
                              fontSize: '11px',
                              fontStyle: 'italic',
                              color: 'grey',
                            }}
                          >
                            Please select a reason why you want to cancel the
                            subscription
                          </option>
                          {refundReasonsList.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            'bg-gray-50 gap-x-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'
          }
        >
          <ActionButton
            dataTestId="e2e-refund-btn"
            onClick={onClick}
            disabled={isSubmitting}
            label={'Cancel Subscription'}
          />
          <ActionButton
            dataTestId="e2e-dismiss-btn"
            onClick={onCancel}
            label={'Dismiss'}
          />
        </div>
      </>
    );
  }
}

const RefundFormMemo = memo(RefundForm);

interface RefundModalProps {
  paymentId?: string;
  paymentAmount: number;
  refundedAmount: number;
  cta_label: string | ReactElement;
  cta_styling: 'default' | 'no-styling';
  classForIcon?: string;
  disabled?: boolean;
  withoutButton?: boolean;
  subscriptionId?: string;
  isSubscActive?: boolean;
  updateRefundAmount:
    | ((transactionId: string, amount: number, precision: number) => void)
    | null;
  isForceCancel?: boolean;
  precision: number;
}

export type RefundModalRefType = {
  toggleModal: (value: boolean) => void;
};

const CancelSubscriptionWithReasonSelectorModal = forwardRef<
  RefundModalRefType,
  RefundModalProps
>((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = (value: boolean): void => setIsOpen(value);
  useImperativeHandle(ref, () => ({
    toggleModal,
  }));
  const {
    cta_label = null,
    cta_styling,
    paymentAmount,
    refundedAmount,
    paymentId,
    disabled,
    subscriptionId,
    isForceCancel,
    precision,
  } = props;
  const { setIsLoading, addToast } = useContext(LoaderContext);

  const initialValues: CancelSubscriptionFormValues = {
    refundReason: '',
  };

  const formSubmitHandler = async (values: CancelSubscriptionFormValues) => {
    const refundReason =
      Object.keys(refundResultsDictionary).find(
        (key) => refundResultsDictionary[key] === values.refundReason
      ) || null;

    onCancelModal();
    setIsLoading(true);

    if (subscriptionId) {
      await cancelSubscriptionR({
        subscriptionId,
        setIsLoading,
        addToast,
        ...(isForceCancel ? { force: isForceCancel } : {}),
        ...(refundReason ? { cancelationReason: refundReason } : {}),
      });
      return;
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: formSubmitHandler,
    // validate: validate,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }

    void formik.setValues({
      refundReason: '',
    });
  }, [isOpen, paymentId, paymentAmount, precision, refundedAmount]);

  const onCancelModal = () => {
    toggleModal(false);
    formik.resetForm();
  };

  return (
    <>
      {!props.withoutButton && (
        <ActionButton
          dataTestId="e2e-refund-icon"
          onClick={() => toggleModal(true)}
          label={cta_label ? cta_label : 'Refund'}
          skipStyling={cta_styling == 'no-styling'}
          classForIcon={props.classForIcon}
          disabled={disabled}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => toggleModal(false)}
        dataTestID="e2e-refund-payment-modal"
      >
        <RefundFormMemo formik={formik} onCancel={onCancelModal} />
      </Modal>
    </>
  );
});

CancelSubscriptionWithReasonSelectorModal.displayName =
  'CancelSubscriptionWithReasonSelectorModal';

export default CancelSubscriptionWithReasonSelectorModal;
