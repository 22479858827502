import { useState } from 'react';

import { Dialog } from '@headlessui/react';

import ActionButton from 'components/modals/common/buttons';
import { cancelSubscriptionOptions, ConfirmationScreenProps } from 'types';

export default function CancelSubscriptionScreen({
  onConfirm,
  onCancel,
  isLoading,
  confirmationMessage,
  cancelSubscriptionAvailableOptions,
  title = 'Confirm Action',
  confirmCTACopy = 'Confirm',
  dismissCTACopy = 'Dismiss',
  isCommitmentPeriod = false,
}: ConfirmationScreenProps) {
  const [selectedOption, setSelectedOption] =
    useState<cancelSubscriptionOptions>(
      cancelSubscriptionOptions.withoutRefund
    );

  const handleConfirm = (selectedOption: string) => {
    onConfirm(selectedOption);
  };

  return (
    <>
      <div className="relative bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <button onClick={onCancel} className="absolute top-4 right-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <div className="pt-4 pb-4">
                <p>{confirmationMessage}</p>

                {isCommitmentPeriod ? (
                  <>
                    {cancelSubscriptionAvailableOptions?.includes(
                      cancelSubscriptionOptions.softCancel
                    ) && (
                      <div className="mt-5">
                        <label className="flex items-center flex-row">
                          <input
                            type="radio"
                            value={cancelSubscriptionOptions.softCancel}
                            className="mr-2"
                            checked={
                              selectedOption ===
                              cancelSubscriptionOptions.softCancel
                            }
                            onChange={() =>
                              setSelectedOption(
                                cancelSubscriptionOptions.softCancel
                              )
                            }
                          />
                          <div className="font-bold"> Soft Cancel</div>
                        </label>
                        <div className="ml-5">
                          The subscription won't auto-renew at end of commitment
                          period. Customer will be charged for next iteration of
                          commitment period
                        </div>
                      </div>
                    )}

                    {cancelSubscriptionAvailableOptions?.includes(
                      cancelSubscriptionOptions.hardCancelWithoutRefund
                    ) && (
                      <div className="mt-5">
                        <label className="flex items-center flex-row">
                          <input
                            type="radio"
                            value={
                              cancelSubscriptionOptions.hardCancelWithoutRefund
                            }
                            className="mr-2"
                            checked={
                              selectedOption ===
                              cancelSubscriptionOptions.hardCancelWithoutRefund
                            }
                            onChange={() =>
                              setSelectedOption(
                                cancelSubscriptionOptions.hardCancelWithoutRefund
                              )
                            }
                          />
                          <div className="font-bold">
                            Hard Cancel without refund
                          </div>
                        </label>
                        <div className="ml-6">
                          The subscription won't renew at next iteration of
                          commitment period
                        </div>
                      </div>
                    )}
                    {cancelSubscriptionAvailableOptions?.includes(
                      cancelSubscriptionOptions.hardCancelWithRefund
                    ) && (
                      <div className="mt-5">
                        <label className="flex items-center flex-row">
                          <input
                            type="radio"
                            value={
                              cancelSubscriptionOptions.hardCancelWithRefund
                            }
                            className="mr-2"
                            checked={
                              selectedOption ===
                              cancelSubscriptionOptions.hardCancelWithRefund
                            }
                            onChange={() =>
                              setSelectedOption(
                                cancelSubscriptionOptions.hardCancelWithRefund
                              )
                            }
                          />
                          <div className="font-bold">
                            Hard Cancel with refund
                          </div>
                        </label>
                        <div className="ml-6">
                          The subscription won't renew at next iteration of
                          commitment period and customer gets money back
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {cancelSubscriptionAvailableOptions?.includes(
                      cancelSubscriptionOptions.withoutRefund
                    ) && (
                      <div className="mt-4">
                        <label className="flex items-center flex-row">
                          <input
                            type="radio"
                            value={cancelSubscriptionOptions.withoutRefund}
                            className="mr-2"
                            checked={
                              selectedOption ===
                              cancelSubscriptionOptions.withoutRefund
                            }
                            onChange={() =>
                              setSelectedOption(
                                cancelSubscriptionOptions.withoutRefund
                              )
                            }
                          />
                          Cancel without refund
                        </label>
                      </div>
                    )}
                    {cancelSubscriptionAvailableOptions?.includes(
                      cancelSubscriptionOptions.withoutRefund
                    ) && (
                      <div className="mt-2">
                        <label className="flex items-center flex-row">
                          <input
                            type="radio"
                            value={cancelSubscriptionOptions.withRefund}
                            className="mr-2"
                            checked={
                              selectedOption ===
                              cancelSubscriptionOptions.withRefund
                            }
                            onChange={() =>
                              setSelectedOption(
                                cancelSubscriptionOptions.withRefund
                              )
                            }
                          />
                          Cancel with refund
                        </label>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 gap-x-2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <ActionButton
          dataTestId="e2e-cancel-subscription-btn"
          onClick={() => handleConfirm(selectedOption)}
          disabled={isLoading}
          label={confirmCTACopy}
        />
        <ActionButton
          dataTestId="e2e-dismiss-cancel-btn"
          onClick={onCancel}
          label={dismissCTACopy}
        />
      </div>
    </>
  );
}
