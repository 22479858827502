import { Dispatch, SetStateAction } from 'react';

import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { AddToast } from 'react-toast-notifications';

import { requestsWrapper } from 'repositories/requestsWrapper';
import {
  downloadReceipt,
  searchCustomers,
  searchCustomersTransactions,
} from 'services/customer';
import {
  CBHResponseData,
  customersType,
  TransactionsForCustomersResponse,
  SearchResultsType,
  SetValueType,
} from 'types';

const EXPIRATION_MINUTES = 5;
const CLEANUP_MINUTES = 20;

export const SearchR = async (
  query: string,
  from: Date | null,
  to: Date | null,
  searchResults: SearchResultsType | null,
  setSearchResults: SetValueType<SearchResultsType | null>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
) => {
  let results: customersType[] | null = null;

  const cacheKey = `${query}/${from?.toString() || ''}/${to?.toString() || ''}`;

  const { results: savedResults, expires } =
    (searchResults && searchResults[cacheKey]) || {};

  if (savedResults && dayjs(expires) > dayjs()) {
    results = savedResults;
  } else {
    const result: AxiosResponse<CBHResponseData<Array<customersType>>> | null =
      await requestsWrapper(
        searchCustomers,
        {
          searchString: query,
          from,
          to,
        },
        setIsLoading,
        addToast
      );

    if (result?.data?.customers) {
      results = result?.data?.customers;

      if (results && setSearchResults) {
        setSearchResults({
          ...searchResults,
          [cacheKey]: {
            results,
            expires: dayjs().add(EXPIRATION_MINUTES, 'minute').toISOString(),
          },
        });
      }
    }
  }

  return results;
};

export const searchMerchantsTransactions = async (
  merchantsIds: string[],
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
): Promise<TransactionsForCustomersResponse | null> => {
  const result = await requestsWrapper(
    searchCustomersTransactions,
    merchantsIds,
    setIsLoading,
    addToast
  );
  if (result) {
    return result.data;
  } else {
    return null;
  }
};

export const downloadReceiptR = async (
  createdAtUtc: string | undefined,
  pbpPaymentId: string,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  addToast: AddToast
): Promise<BlobPart | null> => {
  const result = await requestsWrapper(
    downloadReceipt,
    {
      createdAtUtc,
      pbpPaymentId,
    },
    setIsLoading,
    addToast
  );
  if (result) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result.data;
  } else {
    return null;
  }
};

export const cleanSearchResultsR = (
  lastSearchResultClean: string,
  searchResults: SearchResultsType | null,
  setSearchResults: SetValueType<SearchResultsType | null>,
  setLastSearchResultClean: Dispatch<SetStateAction<any>>,
  force: boolean = false
) => {
  const now = dayjs().toISOString();

  if (
    (lastSearchResultClean === '' ||
      dayjs(lastSearchResultClean)
        .add(CLEANUP_MINUTES, 'minute')
        .isBefore(now)) &&
    searchResults
  ) {
    const cleanedSearchResults = Object.entries(searchResults).reduce(
      (acc, [key, value]) => {
        if (!value) {
          return acc;
        }

        if (dayjs(value.expires).isBefore(now)) {
          return acc;
        }

        return {
          ...acc,
          [key]: value,
        };
      },
      {}
    );

    setSearchResults && setSearchResults(cleanedSearchResults);
    setLastSearchResultClean(now);
  }

  if (force && setSearchResults) {
    setSearchResults(null);
  }
};
