import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { Options, useToasts } from 'react-toast-notifications';

import { ApplicationUser, SetValueType } from 'types';

export const defaultApplicationUser = null;

export type AuthContextState = {
  isLoadingAuthorization: boolean;
  setIsLoadingAuthorization: Dispatch<SetStateAction<boolean>>;
  user: ApplicationUser | null;
  setUser: (user: ApplicationUser | null) => void;
  setUnAuthoriseUser: () => void;
  addToast: (
    content: ReactNode,
    options?: Options,
    callback?: (id: string) => void
  ) => void;
};

export const defaultAuthContextState = {
  isLoadingAuthorization: true,
  user: defaultApplicationUser,
  setUser: () => {},
  addToast: () => {},
  setIsLoadingAuthorization: () => {},
  setUnAuthoriseUser: () => {},
};

export const AuthContext = createContext<AuthContextState>(
  defaultAuthContextState
);

type AuthContextProps = {
  children?: ReactNode | undefined;
};

type keyNameType = string;

export function useLocalStorage<T>(
  keyName: keyNameType,
  defaultValue: T
): [T, SetValueType<T>] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value) as T;
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue: T) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
}

export const AuthProvider = ({ children }: AuthContextProps): ReactElement => {
  const [isLoadingAuthorization, setIsLoadingAuthorization] = useState(false);
  const [user, setUser] = useLocalStorage<ApplicationUser | null>(
    'user',
    defaultApplicationUser
  );

  const { addToast } = useToasts();
  const setUserProxy = (user: ApplicationUser | null) => {
    setIsLoadingAuthorization(false);
    setUser(user);
  };

  const setUnAuthoriseUser = () => {
    if (localStorage.getItem('access')) {
      localStorage.removeItem('access');
    }
    if (localStorage.getItem('refresh')) {
      localStorage.removeItem('refresh');
    }
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
  };

  const value = useMemo(
    () => ({
      isLoadingAuthorization: isLoadingAuthorization,
      setIsLoadingAuthorization: setIsLoadingAuthorization,
      user: user,
      setUser: setUserProxy,
      setUnAuthoriseUser: setUnAuthoriseUser,
      addToast: addToast,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
