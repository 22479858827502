import {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  GetTokenSilentlyVerboseResponse,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { private_client } from 'api/client';
import { USER_INFO_URL } from 'api/constants';
import { Auth0Error } from 'interfaces';
import { UserInfo } from 'types';

import { removeCookies } from './cookie';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_CONNECTION,
  AUTH0_DOMAIN,
  AUTH0_SCOPE,
} from '../constants';

export const getPBPUser = async (): Promise<AxiosResponse<UserInfo>> => {
  const token = await getToken();
  const config = {
    headers: { 'pbp-jwt': `Bearer ${token}` },
  } as AxiosRequestConfig;
  return private_client.get(USER_INFO_URL, config);
};

class LazyAuth0Client {
  private readonly options: Auth0ClientOptions;
  private client: Auth0Client | null;

  constructor(options: Auth0ClientOptions) {
    this.options = options;
    this.client = null;
  }

  private getAuth0Client() {
    if (!this.client) {
      this.client = new Auth0Client(this.options);
    }

    return this.client;
  }

  public async loginWithRedirect(options: RedirectLoginOptions = {}) {
    const client = this.getAuth0Client();

    await client.loginWithRedirect(options);
  }

  public async getTokenSilently(
    options: GetTokenSilentlyOptions & { detailedResponse: true }
  ): Promise<GetTokenSilentlyVerboseResponse> {
    const client: Auth0Client = this.getAuth0Client();

    return await client.getTokenSilently(options);
  }
}

export const getAuth0RedirectUri = () => {
  const port = location.port ? `:${location.port}` : '';
  return `${location.protocol}//${location.hostname}${port}/auth0-callback`;
};

export const auth0Client = new LazyAuth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: getAuth0RedirectUri(),
    scope: AUTH0_SCOPE,
    audience: AUTH0_AUDIENCE,
    cacheMode: 'on',
    connection: AUTH0_CONNECTION,
  },
  cacheLocation: 'localstorage',
});

export const fetchAuth0Token = async () => {
  console.log('Fetching token...');
  return await auth0Client.getTokenSilently({
    detailedResponse: true,
  });
};

export const getAuthProvider = () => {
  if (window) {
    // Perform localStorage action
    return localStorage.getItem('authProvider');
  }
};

export const isAuth0Provider = () => {
  return getAuthProvider() === 'auth0';
};

export const getToken = async () => {
  try {
    const { access_token: access } = await fetchAuth0Token();
    console.log('Token fetched successfully.');
    return access;
  } catch (e) {
    console.log('Fetching token failed...', e);
    const error = e as Auth0Error;
    if (error?.error === 'login_required') {
      localStorage.clear();
      removeCookies();
      window.location.href = '/login';
    } else {
      throw e;
    }
  }
};
