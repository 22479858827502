import '@zendeskgarden/css-bedrock';
import React from 'react';

import { DEFAULT_THEME, ThemeProvider } from '@zendeskgarden/react-theming';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'index.css';
import App from 'pages/App';
import reportWebVitals from 'reportWebVitals';

import Auth0AppWrapper from './components/Auth0Wrapper';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={{ ...DEFAULT_THEME }}>
      <Auth0AppWrapper>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0AppWrapper>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
