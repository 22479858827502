import React from 'react';

import { pricePointType } from '../../types';

export const SubscriptionPlan = ({
  pricePointData,
  isTrial,
  isUpcoming,
  stacked = true,
  isCommitmentPeriod,
}: {
  pricePointData: pricePointType;
  isTrial: boolean;
  isUpcoming: boolean;
  stacked: boolean;
  isCommitmentPeriod: boolean;
}) => {
  let trialType: string | null = null;

  if (pricePointData && pricePointData.introBasePrice) {
    trialType = Number(pricePointData.introBasePrice) === 0 ? 'free' : 'paid';
  }

  let plan = null;
  let ending = '';

  if (isTrial || isUpcoming) {
    ending =
      pricePointData.introPeriodValue && pricePointData.introPeriodValue > 1
        ? 's'
        : '';

    plan = `${pricePointData.introPeriodValue} ${pricePointData.introPeriodType}${ending}`;
  } else {
    ending =
      pricePointData.nextPeriodValue && pricePointData.nextPeriodValue > 1
        ? 's'
        : '';
    plan = `${pricePointData.nextPeriodValue} ${pricePointData.nextPeriodType}${ending}`;
  }

  if (isTrial && trialType && stacked) {
    return (
      <div>
        <span>Intro period</span>
        <br />
        <span className={'capitalize'}>{trialType} trial</span>
        <br />
        <span>{plan}</span>
      </div>
    );
  } else if (isTrial && trialType && !stacked) {
    return (
      <>
        <span>Intro period</span>:{' '}
        <span className={'capitalize'}>{trialType} trial</span> -{' '}
        <span>{plan}</span>
      </>
    );
  }

  if (
    !isTrial &&
    isCommitmentPeriod &&
    pricePointData?.commitmentPeriod >= 2 &&
    stacked
  ) {
    return (
      <span>
        Intro period
        <br />
        {pricePointData.introPeriodValue} {pricePointData.introPeriodType}
        <br />
        {pricePointData?.commitmentPeriod} iterations
      </span>
    );
  } else if (
    !isTrial &&
    isCommitmentPeriod &&
    pricePointData?.commitmentPeriod >= 2 &&
    !stacked
  ) {
    return (
      <>
        <span>Intro period</span>:{' '}
        <span className={'capitalize'}>
          {pricePointData.introPeriodValue} {pricePointData.introPeriodType}
        </span>{' '}
        - <span>{pricePointData?.commitmentPeriod} iterations</span>
      </>
    );
  }

  if (isUpcoming && trialType && stacked) {
    return (
      <div>
        <span>Upcoming period</span>
        <br />
        <span className={'capitalize'}>{trialType} trial</span>
        <br />
        <span>{plan}</span>
      </div>
    );
  } else if (isUpcoming && trialType && !stacked) {
    return (
      <>
        <span>Upcoming period</span>:{' '}
        <span className={'capitalize'}>{trialType} trial</span> -{' '}
        <span>{plan}</span>
      </>
    );
  } else {
    return <span>{plan}</span>;
  }
};
