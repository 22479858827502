import { paymentsMethodsRegistry } from 'api/constants';

import { PaymentMethodIcon } from './PaymentMethodIcon';
import { PaymentMethodTooltip } from './PaymentMethodTooltip';
import { PaymentMethodUserInfo } from './PaymentMethodUserInfo';

type processedPaymentSchemaType =
  | 'amex'
  | 'diners_club'
  | 'discover'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'mir'
  | 'visa'
  | 'other';

type paymentSchemaType = processedPaymentSchemaType | null | undefined;

type PaymentSchemaMap = {
  [key in processedPaymentSchemaType]: string;
};

const CARD_PAYMENT_SCHEMA_MAP: PaymentSchemaMap = {
  amex: '/cbh-payment-icons/amex.svg',
  diners_club: '/cbh-payment-icons/diners.svg',
  discover: '/cbh-payment-icons/discover.svg',
  jcb: '/cbh-payment-icons/jcb.svg',
  maestro: '/cbh-payment-icons/maestro.svg',
  mastercard: '/cbh-payment-icons/mastercard.svg',
  mir: '/cbh-payment-icons/mir.svg',
  visa: '/cbh-payment-icons/visa.svg',
  other: '/cbh-payment-icons/card-generic.svg',
};

interface PaymentMethodsMap {
  [key: number]: string;
}

const NON_CARD_PAYMENT_METHODS_MAP: PaymentMethodsMap = {
  2: '/cbh-payment-icons/paypal.svg',
  4: '/cbh-payment-icons/apple-pay.svg',
  5: '/cbh-payment-icons/google-pay.svg',
};

type PaymentMethodProps = {
  paymentMethodId?: number;
  paymentSchema: paymentSchemaType;
  cardLast4?: string;
  paymentAccountId?: string;
  placement?: string;
};

export function PaymentMethod(props: PaymentMethodProps) {
  const {
    paymentMethodId,
    paymentSchema,
    cardLast4,
    paymentAccountId,
    placement,
  } = props;

  if (!paymentMethodId) {
    return <span>-</span>;
  }

  let iconPath = '';
  let paymentSchemaPath = '';

  const paymentSchemaProcessed = (
    paymentSchema ? paymentSchema.toLowerCase() : 'other'
  ) as processedPaymentSchemaType;

  let tooltipPaymentSchema: string = paymentSchemaProcessed;

  const paymentMethod = paymentsMethodsRegistry[paymentMethodId];

  // Card payment method
  if (paymentMethodId === 1) {
    iconPath = CARD_PAYMENT_SCHEMA_MAP[paymentSchemaProcessed]
      ? CARD_PAYMENT_SCHEMA_MAP[paymentSchemaProcessed]
      : CARD_PAYMENT_SCHEMA_MAP['other'];
    // Apple Pay or Google Pay
  } else if ([4, 5].includes(paymentMethodId)) {
    iconPath = NON_CARD_PAYMENT_METHODS_MAP[paymentMethodId];
    paymentSchemaPath = CARD_PAYMENT_SCHEMA_MAP[paymentSchemaProcessed]
      ? CARD_PAYMENT_SCHEMA_MAP[paymentSchemaProcessed]
      : CARD_PAYMENT_SCHEMA_MAP['other'];
    // PayPal
  } else if ([2].includes(paymentMethodId)) {
    iconPath = NON_CARD_PAYMENT_METHODS_MAP[paymentMethodId];
    tooltipPaymentSchema = '';
    // Other payment methods we do not process yet
  } else {
    return <span>-</span>;
  }

  return (
    <div className={'flex has-tooltip'}>
      <PaymentMethodTooltip
        paymentMethod={paymentMethod}
        paymentSchema={tooltipPaymentSchema}
        cardLast4={cardLast4}
        paymentAccountId={paymentAccountId}
      />
      <PaymentMethodIcon iconPath={iconPath} />
      <PaymentMethodIcon iconPath={paymentSchemaPath} />
      <PaymentMethodUserInfo
        cardLast4={cardLast4}
        paymentAccountId={paymentAccountId}
        placement={placement}
      />
    </div>
  );
}
