import { ReactElement } from 'react';

import { LoginWithCloudFlareButton } from '../components/LoginWithCloudFlareButton';

export default function Login(): ReactElement {
  return (
    <div
      className={
        'flex flex-col items-center px-6 pt-8 mx-auto md:h-screen pt:mt-0'
      }
    >
      <div
        className={
          'w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow'
        }
      >
        <h2 className={'text-2xl font-bold text-gray-900'}>
          Sign in to platform
        </h2>
        <div className={'mt-8 space-y-6'}>
          <div>
            <LoginWithCloudFlareButton />
          </div>
        </div>
      </div>
    </div>
  );
}
