import { ReactElement, Fragment } from 'react';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';

type TableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  renderSubComponent?: (props: { row: Row<TData> }) => ReactElement;
  getRowCanExpand: (row: Row<TData>) => boolean;
  tableStyles?: object;
  tHeadStyle?: object;
  tableTitle?: string;
};
export function Table<TData>({
  data,
  columns,
  renderSubComponent,
  getRowCanExpand,
  tableStyles,
  tableTitle,
  tHeadStyle,
}: TableProps<TData>): ReactElement {
  const table = useReactTable<TData>({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className={'p-2'}>
      {tableTitle && <div style={tableStyles}>{tableTitle}</div>}
      <table className={'w-full'}>
        <thead className={'bg-gray-100'}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={
                      'p-2 text-xs font-medium text-left text-gray-500 uppercase'
                    }
                    key={header.id}
                    colSpan={header.colSpan}
                    style={tHeadStyle}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={'bg-white divide-y divide-gray-200'}>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr className={'hover:bg-gray-100'}>
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={'w-2 p-2'}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && renderSubComponent && (
                  <tr>
                    <td
                      colSpan={row.getVisibleCells().length}
                      className={'w-2 p-2'}
                    >
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div className={'h-2'} />
    </div>
  );
}
