import React, {
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';

import MagnifyingGlassIcon from '@heroicons/react/20/solid/MagnifyingGlassIcon';
import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon';
import { useDebounce } from 'use-debounce';

import analytics from 'events/analytics';
import { isSearchStringValid } from 'helpers/validators';
import {
  cleanSearchResultsR,
  searchMerchantsTransactions,
  SearchR,
} from 'repositories/Search';
import { SearchFormProps, TransactionsForCustomersResponse } from 'types';

import { MINIMUM_SEARCH_LENGTH } from '../../constants';
import { useLocalStorage } from '../../contexts/AuthContext';
import { CustomerContext } from '../../contexts/CustomerContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SearchContext } from '../../contexts/SearchContext';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DateTimeRangePicker, {
  getQuickRange,
  QuickRange,
} from '../DateTimeRangePicker';

const SEARCH_DEBOUNCE_TIME = 300; // ms

export default function SearchForm({
  search,
  setSearch,
  setCustomers,
  setCustomersTransactions,
  customers,
}: SearchFormProps): ReactElement {
  const { searchTriggerCounter, refetchSearch } = useContext(CustomerContext);
  const [debouncedSearch] = useDebounce(search, SEARCH_DEBOUNCE_TIME);
  const { setIsLoading, addToast, isLoading } = useContext(LoaderContext);

  const [startDateTime, setStartDateTime] = useLocalStorage<Date | null>(
    'startDateTime',
    null
  );
  const [endDateTime, setEndDateTime] = useLocalStorage<Date | null>(
    'endDateTime',
    null
  );
  const [isDateRangeOpened, setIsDateRangeOpened] = useLocalStorage(
    'isDateRangeOpened',
    false
  );
  const [quickRangeSelected, setQuickRangeSelected] = useLocalStorage(
    'quickRangeSelected',
    QuickRange.thirty_days
  );
  const [quickRangeApplied, setQuickRangeApplied] =
    useLocalStorage<null | Array<Date | null>>('quickRangeApplied', null);

  const [error, setError] = useState('');

  const setDateTimeRangeFromQuickRange = (quickRange: QuickRange) => {
    const [startDateTime, endDateTime] = getQuickRange(quickRange);
    setStartDateTime(startDateTime);
    setEndDateTime(endDateTime);
    setQuickRangeSelected(quickRange);
    setQuickRangeApplied(null);
    refetchSearch();
  };

  const applyDateTimeRange = () => {
    if (startDateTime && endDateTime) {
      setQuickRangeApplied([startDateTime, endDateTime]);
      setQuickRangeSelected(QuickRange.custom);
      refetchSearch();
    }
  };

  const {
    isDisabled,
    setIsDisabled,
    lastSearchResultClean,
    previousSearch,
    setPreviousSearch,
    setLastSearchResultClean,
    searchResults,
    setSearchResults,
    queryStringSearchParams,
    setQueryStringSearchParams,
    formRef,
  } = useContext(SearchContext);

  const onSearch = (e: MouseEvent) => {
    e.preventDefault();
    analytics.events.Ux.ButtonClick({ action: 'onSearch' })?.track();
    refetchSearch();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;

    setQueryStringSearchParams({ search: currentValue });
    setPreviousSearch(currentValue);
    setSearch(currentValue);
  };

  useEffect(() => {
    setQueryStringSearchParams({ search: debouncedSearch });
    setPreviousSearch(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    setIsDisabled(!debouncedSearch.length);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!customers.length && !isLoading && isSearchStringValid(search)) {
      setCustomersTransactions([]);
    }
  }, [customers, isLoading, search]);

  const onClearSearchString = (e: MouseEvent) => {
    e.preventDefault();

    setPreviousSearch('');
    setSearch('');

    // We need to reset the form manually
    // otherwise the input value will not be cleared even after resetting the state
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  // Set initial search value from query string or local storage
  useEffect(() => {
    const queryStringSearch = queryStringSearchParams.get('search') || '';
    if (queryStringSearch) {
      setSearch(queryStringSearch);
      setPreviousSearch(queryStringSearch);
    } else if (previousSearch) {
      setQueryStringSearchParams({ search: previousSearch });
      setSearch(previousSearch);
    }
  }, []);

  useEffect(() => {
    setError('');

    if (!debouncedSearch) {
      return;
    }

    setCustomers([]);

    if (debouncedSearch && !isSearchStringValid(debouncedSearch)) {
      setError(
        `Search query must be at least ${MINIMUM_SEARCH_LENGTH} characters long`
      );
      return;
    }

    let startDateTime = null;
    let endDateTime = null;

    if (quickRangeApplied) {
      startDateTime = quickRangeApplied[0];
      endDateTime = quickRangeApplied[1];
    } else {
      [startDateTime, endDateTime] = getQuickRange(quickRangeSelected);
    }

    (async () => {
      const result = await SearchR(
        debouncedSearch,
        startDateTime,
        endDateTime,
        searchResults,
        setSearchResults,
        setIsLoading,
        addToast
      );

      if (result && result.length) {
        setCustomers(result);
      }
    })();
  }, [
    debouncedSearch,
    searchTriggerCounter,
    quickRangeApplied,
    quickRangeSelected,
  ]);

  useEffect(() => {
    if (!customers) {
      return;
    } else {
      (async () => {
        if (customers) {
          const merchantsIds = customers.map(
            (merchant) => merchant.externalId.value
          );

          if (merchantsIds.length > 0) {
            const customersTrs: TransactionsForCustomersResponse | null =
              await searchMerchantsTransactions(
                merchantsIds,
                setIsLoading,
                addToast
              );

            if (customersTrs && customersTrs.transactions) {
              setCustomersTransactions(customersTrs?.transactions);
            }
          }
        }
      })();
    }
  }, [customers]);

  useEffect(() => {
    cleanSearchResultsR(
      lastSearchResultClean,
      searchResults,
      setSearchResults,
      setLastSearchResultClean
    );
  }, [search, quickRangeApplied, quickRangeSelected]);

  useEffect(() => {
    if (isDateRangeOpened && !startDateTime && !endDateTime) {
      setDateTimeRangeFromQuickRange(QuickRange.thirty_days);
    }
  }, [isDateRangeOpened]);

  return (
    <form ref={formRef}>
      <label
        htmlFor="default-search"
        className={'mb-2 text-sm font-medium text-gray-900 sr-only'}
      >
        Search
      </label>
      <div className={'relative w-full xs:columns-1 sm:columns-2'}>
        <div className={'sm:w-full'}>
          <div
            className={
              'absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'
            }
          >
            <MagnifyingGlassIcon
              className={'w-5 h-5 text-gray-500 xs:hidden '}
            />
          </div>

          <div className={'relative inset-y-0 right-0 flex items-center'}>
            <input
              type="search"
              id="default-search"
              className={
                'cbh-search block  w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
              }
              defaultValue={search}
              onChange={handleChange}
              placeholder="Search by customer id"
              required
              style={{ borderRadius: '50px' }}
            />
            <XCircleIcon
              data-test-id="e2e-clear-search"
              className={
                'absolute w-8 h-8 top-[10px] right-[10px] text-red-700'
              }
              onClick={onClearSearchString}
            />
          </div>
        </div>
        {!!error && (
          <div className={'mt-2 text-sm text-red-500 sm:hidden'}>{error}</div>
        )}

        <button
          type="submit"
          className={
            'text-white xs:mt-5 xs:w-full  sm:mt-0 sm:w-36 right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4'
          }
          data-test-id="e2e-submit-search"
          onClick={onSearch}
          disabled={isDisabled}
          style={isDisabled ? { opacity: 0.5 } : { opacity: 1 }}
        >
          Search
        </button>
      </div>
      <DateTimeRangePicker
        isDateRangeOpened={isDateRangeOpened}
        setIsDateRangeOpened={setIsDateRangeOpened}
        quickRangeApplied={quickRangeApplied}
        quickRangeSelected={quickRangeSelected}
        setStartDateTime={setStartDateTime}
        startDateTime={startDateTime}
        setEndDateTime={setEndDateTime}
        endDateTime={endDateTime}
        applyDateTimeRange={applyDateTimeRange}
        setDateTimeRangeFromQuickRange={setDateTimeRangeFromQuickRange}
      />

      {!!error && (
        <div className={'mt-2 text-sm text-red-500 xs:invisible sm:visible'}>
          {error}
        </div>
      )}
    </form>
  );
}
