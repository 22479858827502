import dayjs from 'dayjs';

import { DATE_FORMAT_TEMPLATE } from 'api/constants';

import { FeatureType, featureType } from '../types';

export const Feature = ({ feature }: { feature: featureType }) => {
  const getStatus = () => {
    let status = '';
    const now = Date.now();

    if (feature.type == FeatureType.consumable) {
      // Consumable
      status = feature.quantity ? 'Active' : 'Inactive';
    } else if (feature.actualFrom && feature.actualTill) {
      // Limited time case actualFrom < now < actualTill
      const actualFrom = new Date(feature.actualFrom).getTime();
      const actualTill = new Date(feature.actualTill).getTime();
      status = now > actualFrom && now < actualTill ? 'Active' : 'Inactive';
    } else if (feature.actualFrom) {
      // Lifetime case actualFrom < now
      const actualFrom = new Date(feature.actualFrom).getTime();
      status = now > actualFrom ? 'Active' : 'Inactive';
    }
    return status;
  };

  const getFeatureGrantedUntil = () => {
    const status = getStatus();

    if (status === 'Inactive') {
      return 'N/A';
    }
    return feature.actualTill
      ? dayjs(feature.actualTill).format(DATE_FORMAT_TEMPLATE)
      : 'LifeTime';
  };

  const featureRegistry: { [key: string]: string } = {
    'Feature Type:': feature.type,
    'Feature status:': getStatus(),
    'Feature is granted until:': getFeatureGrantedUntil(),
  };
  const FeatureRow = () => (
    <ul data-test-id={'e2e-feature-info'}>
      {Object.keys(featureRegistry).map((key: string) => {
        return (
          <li
            className={'p-1'}
            key={key}
            data-test-id={
              'e2e-' + key.toLowerCase().replace(':', '').split(' ').join('-')
            }
          >
            <span className={'font-semibold mr-4'}>{key}</span>
            {featureRegistry[key]}
          </li>
        );
      })}
    </ul>
  );
  return (
    <div>
      <FeatureRow />
    </div>
  );
};
