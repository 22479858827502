import { MouseEvent } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import { inIframe } from '../helpers/iframe';
import { getAuth0RedirectUri } from '../services/auth';

const LoginWithCloudFlareButton = () => {
  const { loginWithPopup, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const onClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    (async () => {
      if (inIframe()) {
        await loginWithPopup({
          authorizationParams: {
            redirect_uri: getAuth0RedirectUri(),
          },
        });
        navigate('/auth0-callback');
      } else {
        await loginWithRedirect();
      }
    })();
  };

  return (
    <button
      type="submit"
      className={
        'group relative flex w-full justify-center rounded-md  px-4 py-4 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 '
      }
      onClick={onClick}
    >
      <span className={'absolute inset-y-0 left-0 flex items-center pl-3'}>
        <LockClosedIcon
          className={'h-5 w-5 text-indigo-500 group-hover:text-indigo-400'}
          aria-hidden="true"
        />
      </span>
      Log In with Cloudflare
    </button>
  );
};

export { LoginWithCloudFlareButton };
