import React from 'react';

import { Row } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { DATE_FORMAT_TEMPLATE_YEAR } from 'api/constants';

import { SubscriptionPlan } from './common/SubscriptionPlan';
import PaymentMethod from './PaymentMethod';
import PaymentTransactionLogs from './PaymentTransactionLogs';
import {
  FirstTransactionLinksType,
  PricePointPaymentType,
  SubscriptionTags,
} from '../types';

export const TransactionSubComponent = ({
  row,
}: {
  row: Row<PricePointPaymentType>;
}) => {
  const {
    id,
    firstTransaction,
    productName,
    createdAt,
    subscriptionInfo,
    pricePoint,
    country,
    transactionLogs,
    chargeType,
    discountInfo,
  } = row.original;
  const createdAtUtc = dayjs(createdAt).format(DATE_FORMAT_TEMPLATE_YEAR);

  const firstTransactionLinks: FirstTransactionLinksType = {};

  const subscriptionPlan =
    pricePoint && pricePoint.nextPeriodValue && pricePoint.nextPeriodType
      ? `${pricePoint.nextPeriodValue} ${pricePoint.nextPeriodType}`
      : '';

  if (firstTransaction) {
    const popTransaction = transactionLogs.find(
      (e) => e.popTransactionId === firstTransaction.popTransactionId
    );
    const pspTransaction = transactionLogs.find(
      (e) => e.pspTransactionId === firstTransaction.pspTransactionId
    );

    if (popTransaction) {
      firstTransactionLinks.popTransactionLink =
        popTransaction.popTransactionLink;
    }
    if (pspTransaction) {
      firstTransactionLinks.pspTransactionLink =
        pspTransaction.pspTransactionLink;
    }
  }

  const priceBeforeDiscount = discountInfo?.originalAmount;
  const discountAmount = discountInfo?.discountAmount;
  const discountPercentage = discountInfo?.discountPercent;
  const discountCurrencyCode = discountInfo?.currencyCode;

  const isTrial =
    subscriptionInfo?.tags?.includes(SubscriptionTags.isTrial) || false;
  const isUpcoming =
    subscriptionInfo?.tags?.includes(SubscriptionTags.isUpcoming) || false;
  const isCommitmentPeriod =
    subscriptionInfo?.tags?.includes(SubscriptionTags.inCommitment) || false;

  return (
    <>
      <div className="text-gray-500 text-left" style={{ border: '1px solid' }}>
        <div
          style={{ padding: '12px 8px 20px 8px', borderBottom: '1px solid' }}
        >
          <div
            style={{
              marginBottom: '15px',
              fontWeight: '600',
              color: '#000',
            }}
          >
            Transaction in Details:
          </div>
          <div className="flex justify-between w-full">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="whitespace-nowrap">Created (UTC):</div>
              <div className="whitespace-nowrap">Transaction ID:</div>
              {firstTransaction && firstTransaction.pop && (
                <div className="whitespace-nowrap">POP:</div>
              )}
              {firstTransaction && firstTransaction.popTransactionId && (
                <div className="whitespace-nowrap">POP Transaction id:</div>
              )}
              {firstTransaction && firstTransaction.psp && (
                <div className="whitespace-nowrap">PSP:</div>
              )}
              {firstTransaction && firstTransaction.pspTransactionId && (
                <div className="whitespace-nowrap">PSP Transaction id:</div>
              )}
              {subscriptionInfo && (
                <div className="whitespace-nowrap">Subscription id:</div>
              )}
              {subscriptionInfo && (
                <div className="whitespace-nowrap">
                  Subscription event subtype:
                </div>
              )}
              {productName && <div className="whitespace-nowrap">Product:</div>}
              {subscriptionPlan && (
                <div className="whitespace-nowrap">Subscription Plan:</div>
              )}
              {country && <div className="whitespace-nowrap">Country:</div>}
              {firstTransaction && firstTransaction.amount && (
                <div className="whitespace-nowrap">Price:</div>
              )}
              {pricePoint && pricePoint.id && (
                <div className="whitespace-nowrap">Price Point id:</div>
              )}
              {pricePoint && pricePoint.ident && (
                <div className="whitespace-nowrap">Price Point ident:</div>
              )}
              {chargeType && (
                <div className="whitespace-nowrap">Charge Type:</div>
              )}
              {firstTransaction && firstTransaction.paymentMethodId && (
                <div className="whitespace-nowrap">Payment Method:</div>
              )}
              {discountInfo && (
                <div className="whitespace-nowrap">Discount:</div>
              )}
              {priceBeforeDiscount && (
                <div className="whitespace-nowrap">Price Before Discount:</div>
              )}
              {discountAmount && (
                <div className="whitespace-nowrap">Discount Amount:</div>
              )}
              {discountPercentage && (
                <div className="whitespace-nowrap">Discount Percentage:</div>
              )}
            </div>
            <div className="vertical-devider" />
            <div className="flex flex-col w-full">
              <div>{createdAtUtc}</div>
              <div>{id}</div>
              {firstTransaction && firstTransaction.pop && (
                <div>{firstTransaction.pop}</div>
              )}
              {firstTransaction &&
                firstTransaction.popTransactionId &&
                (firstTransactionLinks.popTransactionLink ? (
                  <a
                    href={firstTransactionLinks.popTransactionLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {firstTransaction.popTransactionId}
                  </a>
                ) : (
                  <div>{firstTransaction.popTransactionId}</div>
                ))}
              {firstTransaction && firstTransaction.psp && (
                <div>{firstTransaction.psp}</div>
              )}
              {firstTransaction &&
                firstTransaction.pspTransactionId &&
                (firstTransactionLinks.pspTransactionLink ? (
                  <a
                    href={firstTransactionLinks.pspTransactionLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {firstTransaction.pspTransactionId}
                  </a>
                ) : (
                  <div>{firstTransaction.pspTransactionId}</div>
                ))}
              {subscriptionInfo && (
                <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                  {subscriptionInfo.subscriptionId}
                </div>
              )}
              {subscriptionInfo && <div>{subscriptionInfo.subtype}</div>}
              {productName && <div>{productName}</div>}
              {subscriptionPlan && pricePoint && (
                <div>
                  <SubscriptionPlan
                    pricePointData={pricePoint}
                    isTrial={isTrial}
                    isUpcoming={isUpcoming}
                    stacked={false}
                    isCommitmentPeriod={isCommitmentPeriod}
                  />
                </div>
              )}
              {country && <div>{country}</div>}
              {firstTransaction && firstTransaction.amount && (
                <div>
                  {firstTransaction.amount} {firstTransaction.currencyCode}
                </div>
              )}
              {pricePoint && pricePoint.id && <div>{pricePoint.id}</div>}
              {pricePoint && pricePoint.ident && <div>{pricePoint.ident}</div>}
              {chargeType && <div>{chargeType.levelTwo}</div>}
              {firstTransaction && firstTransaction.paymentMethodId && (
                <div>
                  <PaymentMethod
                    paymentMethodId={firstTransaction.paymentMethodId}
                    paymentSchema={firstTransaction.paymentSchema}
                    cardLast4={firstTransaction.cardLast4}
                    paymentAccountId={firstTransaction.paymentAccountId}
                    placement={'transaction-log-detail'}
                  />
                </div>
              )}
              {discountInfo && <div>Yes</div>}
              {priceBeforeDiscount && (
                <div>
                  {priceBeforeDiscount} {discountCurrencyCode}
                </div>
              )}
              {discountAmount && (
                <div>
                  {discountAmount} {discountCurrencyCode}
                </div>
              )}
              {discountPercentage && <div>{discountPercentage} %</div>}
            </div>
          </div>
        </div>
        <PaymentTransactionLogs transactionLogs={transactionLogs} id={id} />
      </div>
    </>
  );
};
