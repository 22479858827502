import { useContext, useEffect, useState } from 'react';

import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import { CustomerContext } from 'contexts/CustomerContext';
import { NavigationItemType } from 'interfaces';

import { LinkStyledComponent } from './styled';
import { FullScreenBtn } from '../common/FullScreenBtn';
import { UserInfo } from '../common/UserInfo';
import { customersType } from '../types';

const processedOpenedSearchedCustomers = (
  searchedCustomers: customersType[]
) => {
  return searchedCustomers.map((customer) => {
    return {
      name: `Customer ${customer.emails[0]}`,
      href: `/customer/${customer.externalId.type}/${customer.externalId.value}`,
      current: true,
      canBeClosed: true,
    };
  });
};

const defaultSearchNavigation = [
  {
    name: 'Search',
    href: '/search',
    current: true,
    canBeClosed: false,
  },
];

export default function TopMenu() {
  const { openedSearchedCustomers, setOpenedSearchedCustomers } =
    useContext(CustomerContext);
  const [navigationItems, setNavigationItems] = useState<NavigationItemType[]>(
    []
  );
  const [queryStringSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    setNavigationItems(defaultSearchNavigation);
  }, []);

  const addUniqueNavigationItems = (newItems: NavigationItemType[]) => {
    const uniqueNewItems = newItems.filter(
      (newItem) =>
        !navigationItems.some(
          (existingItem) => existingItem.href === newItem.href
        )
    );
    setNavigationItems((prevItems) => [...prevItems, ...uniqueNewItems]);
  };

  useEffect(() => {
    const openedNavigationItems = processedOpenedSearchedCustomers(
      openedSearchedCustomers
    );
    addUniqueNavigationItems(openedNavigationItems);
  }, [openedSearchedCustomers]);

  const closeNavigationItem = (el: NavigationItemType) => {
    const updatedNavigationItems = navigationItems.filter(
      (item) => item.href !== el.href
    );

    setNavigationItems(updatedNavigationItems);

    const filteredSearchedCustomers = openedSearchedCustomers.filter(
      (customer) =>
        `/customer/${customer.externalId.type}/${customer.externalId.value}` !==
        el.href
    );
    setOpenedSearchedCustomers(filteredSearchedCustomers);

    if (location.pathname === el.href) {
      navigate('/search', { replace: true });
    }
  };

  const queryString = queryStringSearchParams.get('search') || '';

  const port = location.port ? `:${location.port}` : '';

  const fullScreenUrl = `${location.protocol}//${location.hostname}${port}/search?search=${queryString}`;

  return (
    <div className="relative">
      <LinkStyledComponent
        href={fullScreenUrl}
        target="_blank"
        rel="noreferrer"
      >
        <FullScreenBtn />
      </LinkStyledComponent>

      <UserInfo />

      <div
        className={
          'text-sm font-medium text-center text-gray-500 border-b border-gray-200'
        }
      >
        <ul className={'flex flex-wrap -mb-px'}>
          {navigationItems.map((item: NavigationItemType) => (
            <li className={'mr-2'} key={item.name + item.href}>
              <NavLink
                data-test-id="e2e-navlink"
                to={item.href}
                className={({ isActive, isPending }) =>
                  isPending
                    ? 'pending'
                    : isActive
                    ? 'hover:no-underline focus:no-underline text-xl inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active uppercase'
                    : 'focus:no-underline text-xl inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 uppercase hover:no-underline'
                }
              >
                {item.name}
                {item.canBeClosed && (
                  <button
                    className={'align-middle '}
                    onClick={(e) => {
                      e.preventDefault();
                      closeNavigationItem(item);
                    }}
                  >
                    <XMarkIcon className={'w-8 h-8 text-red-700'} />
                  </button>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
