import React, { createContext, ReactElement, useMemo, useState } from 'react';

import { useToasts } from 'react-toast-notifications';

import { CustomerContextProps, LoaderContextState } from 'types';

export const defaultLoaderContextState = {
  isLoading: false,
  setIsLoading: () => {},
  addToast: () => {},
};

export const LoaderContext = createContext<LoaderContextState>(
  defaultLoaderContextState
);

export const LoaderStateProvider = ({
  children,
}: CustomerContextProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const getContextValue = () => {
    return {
      isLoading: isLoading,
      setIsLoading: setIsLoading,
      addToast: addToast,
    };
  };

  const value = useMemo(() => getContextValue(), [isLoading, addToast]);

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};
