import { styled } from 'styled-components';

export const ButtonMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: rgb(66, 124, 229);
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: s7 1s infinite steps(8);
  position: absolute;
  left: 50%;
  bottom: 50%;

  @keyframes s7 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export const LinkStyledComponent = styled.a`
  display: none;

  @media (max-width: 400px) {
    margin: 0 auto;
    width: 9rem;
    display: block;
  }
`;
