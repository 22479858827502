import {
  PricePointsProductMapType,
  pricePointType,
  SubscriptionStatuses,
  SubscriptionTags,
} from 'types';

export const getIsExpiredBasedOnDate = (endPeriod: string | undefined) => {
  if (endPeriod) {
    return Date.parse(endPeriod) < Date.now();
  }

  return false;
};

export const getIsExpiredBasedOnTags = (
  tags: Array<SubscriptionTags> | undefined
) => {
  if (!tags) {
    return false;
  }

  return tags.includes(SubscriptionTags.isExpired);
};

export const getIsUnsubscribed = (
  tags: Array<SubscriptionTags> | undefined
) => {
  if (!tags) {
    return false;
  }

  return tags.includes(SubscriptionTags.isUnsubscribed);
};
export const getIsInCommitmentPlan = (
  tags: Array<SubscriptionTags> | undefined
) => {
  if (!tags) {
    return false;
  }

  return tags.includes(SubscriptionTags.inCommitment);
};
export const getIsHardCancel = (tags: Array<SubscriptionTags> | undefined) => {
  if (!tags) {
    return false;
  }

  return (
    tags.includes(SubscriptionTags.inCommitment) &&
    tags.includes(SubscriptionTags.isUnsubscribed)
  );
};
export const getIsSoftCancel = (tags: Array<SubscriptionTags> | undefined) => {
  if (!tags) {
    return false;
  }

  return (
    tags.includes(SubscriptionTags.inCommitment) &&
    tags.includes(SubscriptionTags.isUnsubscriptionPostponed)
  );
};

export const getSubscriptionStatus = (
  subscriptionTags: Array<SubscriptionTags> | undefined,
  cancelReason: string | undefined
): SubscriptionStatuses => {
  let subStatus: SubscriptionStatuses = SubscriptionStatuses.active;

  const isUnsubscribed = getIsUnsubscribed(subscriptionTags);

  const isExpiredBasedOnTags = getIsExpiredBasedOnTags(subscriptionTags);
  const isInCommitmentPlan = getIsInCommitmentPlan(subscriptionTags);
  const isHardCancel = getIsHardCancel(subscriptionTags);
  const isSoftCancel = getIsSoftCancel(subscriptionTags);

  if (isExpiredBasedOnTags) {
    subStatus = SubscriptionStatuses.expired;
  } else if (isUnsubscribed) {
    subStatus = SubscriptionStatuses.canceled;

    if (cancelReason && cancelReason === 'crossgrade') {
      subStatus = SubscriptionStatuses.canceledCrossgraded;
    }
  } else if (isInCommitmentPlan) {
    subStatus = SubscriptionStatuses.inCommitment;
  }

  if (isHardCancel) {
    subStatus = SubscriptionStatuses.hardCancel;
  } else if (isSoftCancel) {
    subStatus = SubscriptionStatuses.softCancel;
  }
  return subStatus;
};

export const getSubscriptionStatusColor = (status: SubscriptionStatuses) => {
  switch (status) {
    case SubscriptionStatuses.active:
      return 'text-green-500';
    case SubscriptionStatuses.inCommitment:
      return 'text-green-500';
    case SubscriptionStatuses.expired:
    case SubscriptionStatuses.canceled:
    case SubscriptionStatuses.notASubscription:
    case SubscriptionStatuses.canceledCrossgraded:
      return 'text-red-500';
    case SubscriptionStatuses.softCancel:
      return 'text-red-500';
    case SubscriptionStatuses.hardCancel:
      return 'text-red-500';
    default:
      return '';
  }
};

export const getProductMapFromPricePoints = (
  pricePoints: Array<pricePointType>
) => {
  const productMap: PricePointsProductMapType = {};

  for (const pricePoint of pricePoints) {
    if (!(pricePoint.id in productMap)) {
      productMap[pricePoint.id] = {};
    }

    if (!(pricePoint.currentVersion in productMap[pricePoint.id])) {
      productMap[pricePoint.id][pricePoint.currentVersion] = '';
    }

    productMap[pricePoint.id][pricePoint.currentVersion] =
      pricePoint.productName ? pricePoint.productName : '';
  }

  return productMap;
};
