type PaymentMethodUserInfoProps = {
  cardLast4?: string;
  paymentAccountId?: string;
  placement?: string;
};

export function PaymentMethodUserInfo({
  cardLast4,
  paymentAccountId,
  placement = '',
}: PaymentMethodUserInfoProps) {
  return (
    <div className={'flex-auto h-6 align-middle'}>
      {cardLast4 && (
        <span
          className={'leading-6'}
          data-test-id={`e2e-payment-method-alias${
            placement ? '-' + placement : ''
          }`}
        >
          ....{cardLast4}
        </span>
      )}
      {paymentAccountId && (
        <span
          className={'leading-6'}
          data-test-id={`e2e-payment-method-alias${
            placement ? '-' + placement : ''
          }`}
        >
          {paymentAccountId}
        </span>
      )}
    </div>
  );
}
