import React, { useState, useEffect } from 'react';

import { ColumnDef } from '@tanstack/react-table';

import { SubscriptionPlan } from './common/SubscriptionPlan';
import PaymentMethod from './PaymentMethod';
import { Table } from './TableLogs';
import { SubscriptionEvent, SubscriptionTags } from '../types';

const columns: ColumnDef<SubscriptionEvent>[] = [
  {
    accessorKey: 'createdAt',
    header: 'Timestamp (UTC)',
    cell: ({ row }) => (
      <div
        style={{
          minWidth: '90px',
        }}
      >
        {row.original.createdAt}
      </div>
    ),
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'subscriptionId',
    header: () => <span>Event Id</span>,
    cell: ({ row }) => {
      const [copySuccess, setCopySuccess] = useState(false);
      useEffect(() => {
        if (copySuccess) {
          const secTimer = setTimeout(() => {
            const fadeOut: HTMLElement | null =
              document.querySelector('.fade-out');

            if (fadeOut) {
              fadeOut.style.opacity = '0';
            }
          }, 800);

          const timeout = setTimeout(() => {
            setCopySuccess(false);

            const fadeOut: HTMLElement | null =
              document.querySelector('.fade-out');

            if (fadeOut) {
              fadeOut.style.opacity = '1';
            }
          }, 2000);

          return () => {
            clearTimeout(timeout);
            clearTimeout(secTimer);
          };
        }
      }, [copySuccess]);

      const handleCopy = async () => {
        await navigator.clipboard.writeText(row.original.subscriptionId);
        setCopySuccess(true);
      };

      return (
        <div className="formatted-string" style={{ minWidth: '100px' }}>
          <span
            style={{
              display: 'block',
              width: '34px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {row.original.id}
          </span>
          <span>{row.original.subscriptionId.slice(-4)}</span>
          <div className="copy-icon" onClick={handleCopy}>
            &#x2398;
          </div>
          {copySuccess && <div className="fade-out">Copied</div>}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'type',
    header: 'Event type',
    cell: ({ row }) => (
      <div style={{ minWidth: 'max-content' }}>
        {row.original.type ? row.original.type : '-'}
      </div>
    ),
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'subtype',
    header: 'Event Subtype',
    cell: ({ row }) => (
      <div>{row.original.subtype ? row.original.subtype : '-'}</div>
    ),
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'productName',
    header: 'Product Name',
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'plan',
    header: 'Plan',
    cell: ({ row }) => {
      const { tags } = row.original;
      const isTrial = tags?.includes(SubscriptionTags.isTrial);
      const isUpcoming = tags?.includes(SubscriptionTags.isUpcoming);
      const isCommitmentPeriod = tags?.includes(SubscriptionTags.inCommitment);

      return (
        <SubscriptionPlan
          pricePointData={row.original.pricePoint}
          isTrial={isTrial}
          isUpcoming={isUpcoming}
          stacked={true}
          isCommitmentPeriod={isCommitmentPeriod}
        />
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'paymentMethod',
    header: 'Payment Method',
    cell: ({ row }) => {
      const transactionValue = row.original.transaction;

      return (
        <div style={{ display: 'flex' }}>
          {transactionValue && (
            <PaymentMethod
              paymentMethodId={transactionValue.paymentMethodId}
              paymentSchema={transactionValue.paymentSchema}
              cardLast4={transactionValue.cardLast4}
              paymentAccountId={transactionValue.paymentAccountId}
              placement={'subscription-log'}
            />
          )}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
  {
    accessorKey: 'amount',
    header: 'Event Price',
    cell: ({ row }) => {
      const transactionValue = row.original.transaction;
      return (
        <div style={{ minWidth: 'max-content' }}>
          {transactionValue &&
          transactionValue.amount &&
          transactionValue.currencyCode
            ? `${transactionValue.amount} ${transactionValue.currencyCode}`
            : '-'}
        </div>
      );
    },
    footer: (props) => props.column.id,
  },
];
export const SubscriptionLogs = ({ logs }: { logs: SubscriptionEvent[] }) => {
  return (
    <Table
      data={logs}
      columns={columns}
      getRowCanExpand={() => true}
      tableStyles={{
        padding: '5px 5px 15px 5px',
        color: 'black',
        fontWeight: '600',
      }}
      tableTitle="Subscription Lifecycle - Events"
    />
  );
};
