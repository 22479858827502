import React, { useContext, useEffect, useState } from 'react';

import { Tabs } from 'flowbite-react';

import { FeatureRow } from 'components/FeatureRow';
import { CustomerContext } from 'contexts/CustomerContext';
import { getFeatures, getFreebies } from 'services/customer';
import { featureType, freebiesPricePointType } from 'types';

import { ProductsWithFreebieRow } from './ProductsWithFreebieRow';

interface FeatureListProps {
  features: Array<featureType>;
}

const tabsTheme = {
  base: 'flex flex-col gap-2',
  tablist: {
    base: 'flex text-center',
    styles: {
      fullWidth:
        'text-sm font-medium rounded-lg divide-x divide-gray-200 shadow sm:flex',
    },
    tabitem: {
      base: 'flex-auto w-1/2 flex items-center justify-center text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 ',
      styles: {
        fullWidth: {
          base: 'ml-2 first:ml-0 w-full first:rounded-l-lg last:rounded-r-lg',
          active: {
            on: 'inline-block p-4 text-gray-900 bg-gray-100 focus:ring-4 focus:ring-blue-300 active focus:outline-none',
            off: 'bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none',
          },
        },
      },
      icon: 'mr-2 h-5 w-5',
    },
  },
};

function FeatureList({ features }: FeatureListProps) {
  return (
    <div>
      {features.map((x) => (
        <FeatureRow feature={x} key={x.id} />
      ))}
    </div>
  );
}

export default function CustomerFeatures() {
  const [features, setFeatures] = useState<Array<featureType>>([]);
  const [products, setProducts] = useState<Array<freebiesPricePointType>>([]);
  const { customerId, customerType, refetchFeaturesCounter } =
    useContext(CustomerContext);

  const fetchFeatures = async (customerId: string, customerType: string) => {
    const result = await getFeatures(customerId, customerType);

    if (result?.data) {
      setFeatures(result.data);
    }
  };
  const fetchProducts = async () => {
    const result = await getFreebies();
    if (result?.data && result?.data?.freebies) {
      setProducts(result?.data?.freebies);
    }
  };

  useEffect(() => {
    if (customerId && customerType) {
      (async () => {
        await fetchFeatures(customerId, customerType);
        await fetchProducts();
      })();
    }
  }, [customerId, customerType, refetchFeaturesCounter]);

  if (!features.length) {
    return (
      <>
        <p>User doesn't have any features</p>
      </>
    );
  }

  return (
    <Tabs.Group
      aria-label="Full width tabs"
      style="fullWidth"
      theme={tabsTheme}
      data-test-id={'e2e-features-tab-group'}
    >
      <Tabs.Item title="Products">
        <ProductsWithFreebieRow products={products} />
      </Tabs.Item>
      <Tabs.Item title="Features">
        <FeatureList features={features} />
      </Tabs.Item>
    </Tabs.Group>
  );
}
