import { useContext } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import RequireAuth from 'components/RequireAuth';
import { CustomerContext } from 'contexts/CustomerContext';
import CustomerDetail from 'pages/CustomerDetail';
import Login from 'pages/Login';
import Search from 'pages/Search';

import Auth0Callback from '../pages/Auth0Callback';

export default function Router() {
  const { openedSearchedCustomers } = useContext(CustomerContext);
  const location = useLocation();
  return (
    <Routes>
      {/*Private routes*/}
      <Route element={<RequireAuth />}>
        <Route path="/customer" element={<CustomerDetail />} />
        <Route path="/search" element={<Search />} />

        {openedSearchedCustomers.map((customer) => (
          <Route
            key={customer.externalId.type + customer.externalId.value}
            path="/customer/:externalIdType/:externalIdValue"
            element={<CustomerDetail />}
          />
        ))}
        {/*Use Navigate instead of using `search` route to trigger search tab as a selected element */}
        <Route
          path="*"
          element={<Navigate to="/search" state={{ from: location }} replace />}
        />
      </Route>
      {/*Public routes*/}
      <Route path="/login" element={<Login />} />
      <Route path="/auth0-callback" element={<Auth0Callback />} />
      {/*</Route>*/}
    </Routes>
  );
}
