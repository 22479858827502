type PaymentMethodIconProps = {
  iconPath?: string;
};

export function PaymentMethodIcon({ iconPath }: PaymentMethodIconProps) {
  if (iconPath) {
    return (
      <div className={'flex-none w-6 h-6 mr-1'}>
        <img src={iconPath} className={'w-6 h-6'} />
      </div>
    );
  }

  return null;
}
