type PaymentMethodTooltipProps = {
  paymentMethod: string;
  paymentSchema: string;
  cardLast4?: string;
  paymentAccountId?: string;
};

export function PaymentMethodTooltip({
  paymentMethod,
  paymentSchema,
  cardLast4,
  paymentAccountId,
}: PaymentMethodTooltipProps) {
  return (
    <span
      className={
        'tooltip rounded shadow-lg p-1 bg-[#e3e3e3] text-[#333] -mt-10'
      }
    >
      {paymentMethod}: {paymentSchema} {cardLast4 && `...${cardLast4}`}{' '}
      {paymentAccountId && paymentAccountId}
    </span>
  );
}
