import { useState, useEffect, ChangeEvent } from 'react';

import { Dialog } from '@headlessui/react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { DATE_FORMAT_TEMPLATE_YEAR } from 'api/constants';
import ActionButton from 'components/modals/common/buttons';

import { deferralType } from '../../../types';

type moveRenewalDate = {
  onConfirm: (value: Date | string, prolongFeature: boolean) => void;
  onCancel: () => void;
  isLoading: boolean;
  title?: string;
  confirmCTACopy: string;
  dismissCTACopy: string;
  productName?: string;
  nextRenewCheck?: string | undefined;
};

export default function MoveRenewalDate({
  onConfirm,
  isLoading,
  onCancel,
  confirmCTACopy,
  dismissCTACopy,
  productName,
  nextRenewCheck,
}: moveRenewalDate) {
  const initialDate = new Date(nextRenewCheck as string);
  const formattedNextRenewCheck = dayjs(initialDate).format(
    DATE_FORMAT_TEMPLATE_YEAR
  );
  const [startDate, setStartDate] = useState(initialDate);
  const [deferralTypeVal, setDeferralTypeVal] = useState<deferralType>(
    deferralType.withFeatures
  );

  const onOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDeferralTypeVal(e.target.value as deferralType);
  };

  useEffect(() => {
    const input = document.querySelector(
      '.react-datepicker__input-container>input'
    ) as HTMLInputElement;

    if (input) {
      input.focus = () => false;
    }
  }, []);
  const dismissHandler = () => {
    onCancel();
    setStartDate(initialDate);
  };
  const renewDateHandler = () => {
    onConfirm(startDate, deferralTypeVal === deferralType.withFeatures);
  };

  const onChangeDatePickerHandler = (date: Date) => {
    setStartDate(date);
  };

  return (
    <>
      <div className={'bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'}>
        <button onClick={onCancel} className="absolute top-4 right-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className={'sm:flex sm:items-start'}>
          <div className={'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'}>
            <Dialog.Title
              as="h3"
              className={'text-base leading-6 text-gray-900'}
            >
              {productName && (
                <span className={'font-semibold'}>{productName}</span>
              )}{' '}
              <div
                style={{
                  fontSize: '13px',
                  color: 'grey',
                  letterSpacing: '0.5px',
                }}
              >
                Next expected renewal date is:
              </div>
            </Dialog.Title>
            <div
              className={'font-semibold pt-4'}
              style={{ letterSpacing: '0.5px' }}
            >
              {formattedNextRenewCheck}
            </div>
            <div>
              <div className={'pb-4'}>
                <div
                  style={{
                    margin: '14px 0',
                    fontSize: '13px',
                    color: 'grey',
                    letterSpacing: '0.5px',
                  }}
                >
                  Select new renewal date
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <DatePicker
                    minDate={startDate}
                    autoFocus={false}
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    // @ts-expect-error inconsistency in the types
                    onChange={onChangeDatePickerHandler}
                  />
                </div>
                <div className="flex items-center mb-4 mt-4">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value={deferralType.withFeatures}
                    name="default-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    checked={deferralTypeVal === deferralType.withFeatures}
                    onChange={onOptionChange}
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ms-2 text-sm font-medium text-gray-900"
                  >
                    Customer will keep access to feature associated to
                    subscription until new renewal date
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    checked={deferralTypeVal === deferralType.withoutFeatures}
                    id="default-radio-2"
                    type="radio"
                    value={deferralType.withoutFeatures}
                    name="default-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    onChange={onOptionChange}
                  />
                  <label
                    htmlFor="default-radio-2"
                    className="ms-2 text-sm font-medium text-gray-900"
                  >
                    Only renewal date will be moved customer will lose access to
                    feature associated to subscription after the current renewal
                    date
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={' px-4 sm:flex sm:px-6'}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '50px',
          paddingTop: '3.75rem',
        }}
      >
        <ActionButton
          dataTestId="e2e-move-renewal-date-btn"
          onClick={renewDateHandler}
          label={dismissCTACopy}
          additionalStyles={{
            width: '50%',
            opacity: startDate === initialDate ? '0.5' : '1',
          }}
          disabled={startDate === initialDate}
        />
        <ActionButton
          dataTestId="e2e-dismiss-btn"
          onClick={dismissHandler}
          disabled={isLoading}
          label={confirmCTACopy}
          additionalStyles={{ marginTop: '8px', width: '50%' }}
        />
      </div>
    </>
  );
}
