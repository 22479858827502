import { PaginationState } from '@tanstack/table-core/src/features/RowPagination';

import { range } from 'helpers/range';
import { TablePaginationProps } from 'types';

const buttonClassname =
  '!bg-blue-50 text-blue-600 ' +
  'hover:bg-blue-100 hover:text-blue-700' +
  ' w-12 border bg-white py-2 leading-tight ' +
  'text-gray-500 hover:bg-gray-100 hover:text-gray-700' +
  '!bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 ';

const selectClassname =
  'bg-gray-50 border-gray-300 ' +
  'text-gray-900 rounded-lg focus:ring-primary-500 focus:border-primary-500 ' +
  'block w-full p-2.5';

const pageSizeOptions: number[] = [10, 20, 30, 40, 50];

export default function TablePagination(props: TablePaginationProps) {
  const table = props.table;
  const paginationState = table.getState().pagination as PaginationState;
  const pageIndex = paginationState.pageIndex;
  const currentPage = pageIndex + 1;
  const totalPages = table.getPageCount();

  const firstPage = Math.max(1, currentPage - 2);
  const lastPage = Math.min(currentPage + 2, totalPages);

  return (
    <div className="flex">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="flex-none m-2">
          <button
            className={buttonClassname}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            style={{ borderRadius: '5px 0 0 5px' }}
          >
            {'<<'}
          </button>
          <button
            className={buttonClassname}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          {range(firstPage, lastPage).map((page: number) => (
            <button
              key={page}
              className={
                buttonClassname +
                (page === currentPage ? ' border-stone-900' : '')
              }
              onClick={() => table.setPageIndex(page - 1)}
            >
              {page}
            </button>
          ))}
          <button
            className={buttonClassname}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className={buttonClassname}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            style={{ borderRadius: '0 5px 5px 0' }}
          >
            {'>>'}
          </button>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="flex-none m-2">
            <span className="leading-10 text-lg font-normal text-gray-500">
              Showing{' '}
              <span className="font-semibold text-gray-900">
                {pageIndex + 1}{' '}
              </span>{' '}
              page of{' '}
              <span className="font-semibold text-gray-900">
                {' '}
                {table.getPageCount()}
              </span>
            </span>
          </div>
          <div className="flex-none m-2">
            <select
              className={selectClassname}
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {pageSizeOptions.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
