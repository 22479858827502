import { ReactElement, useContext, useEffect } from 'react';

import { LoaderContext } from 'contexts/LoaderContext';
import { isSearchStringValid } from 'helpers/validators';
import { customersType, SearchTransactionLog } from 'types';

type NoResultSearchProps = {
  customers: Array<customersType>;
  search: string;
  setCustomersTransactions: (transactions: Array<SearchTransactionLog>) => void;
};
export function NoResultSearch(props: NoResultSearchProps): ReactElement {
  const { customers, search, setCustomersTransactions } = props;
  const { isLoading } = useContext(LoaderContext);

  useEffect(() => {
    if (!customers.length && !isLoading && isSearchStringValid(search)) {
      setCustomersTransactions([]);
    }
  }, [customers, isLoading, search]);

  return (
    <>
      {!customers.length && !isLoading && isSearchStringValid(search) && (
        <div className={'mx-auto pt-10'}>
          <p className={'text-red-600 font-extrabold'}>
            Sorry no results, try with a different search
          </p>
        </div>
      )}
    </>
  );
}
