import { ReactElement, useEffect } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useNavigate } from 'react-router-dom';
import { AddToast, useToasts } from 'react-toast-notifications';

import { private_client, private_client as axios_instance } from 'api/client';
import { CBHRequestConfig } from 'interfaces';
import { fetchAuth0Token } from 'services/auth';
import { AxiosInterceptorProps, CBHResponseData } from 'types';

const handleExpiredAuth0Token = async (
  prevRequest: CBHRequestConfig,
  error: AxiosError<CBHResponseData>,
  addToast: AddToast,
  navigate: NavigateFunction
) => {
  const { access_token: access } = await fetchAuth0Token();

  const errorMessages = error.message
    ? error.message
    : 'Error interceptor: Refreshing Token failed';

  if (access) {
    prevRequest.headers['pbp-jwt'] = `Bearer ${access}`;
    return private_client(prevRequest);
  } else {
    addToast(errorMessages, {
      appearance: 'error',
      autoDismiss: true,
    });
    localStorage.clear();
    navigate('/login');
  }
};

const AxiosInterceptor = ({
  children,
}: AxiosInterceptorProps): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };

    const errInterceptor = async (error: AxiosError<CBHResponseData>) => {
      console.log('Error interceptor', error);
      const prevRequest = error?.config as CBHRequestConfig;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        return await handleExpiredAuth0Token(
          prevRequest,
          error,
          addToast,
          navigate
        );
      }

      return Promise.reject(error);
    };

    const interceptor = axios_instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => axios_instance.interceptors.response.eject(interceptor);
  }, [navigate]);

  return children;
};

export { AxiosInterceptor };
