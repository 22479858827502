import { ReactElement, useContext } from 'react';

import SearchForm from 'components/search/SearchForm';
import SearchFormDescription from 'components/search/SearchFormDescription';
import TopMenu from 'components/TopMenu';
import UserCard from 'components/UserCard';
import { CustomerContext } from 'contexts/CustomerContext';
import { customersType } from 'types';

import { NoResultSearch } from '../components/search/NoResultSearch';
import { SearchContext } from '../contexts/SearchContext';

export default function Search(): ReactElement {
  const {
    customers,
    setCustomers,
    customersTransactions,
    setCustomersTransactions,
    search,
    setSearch,
  } = useContext(SearchContext);

  const { setOpenedSearchedCustomers } = useContext(CustomerContext);

  const addNewTab = (customer: customersType) => {
    setOpenedSearchedCustomers((prevCustomers: customersType[]) => {
      const isCustomerExists = prevCustomers.some(
        (prevCustomer) =>
          prevCustomer.externalId.type === customer.externalId.type &&
          prevCustomer.externalId.value === customer.externalId.value &&
          prevCustomer.externalId.merchantId === customer.externalId.merchantId
      );

      return isCustomerExists ? prevCustomers : [...prevCustomers, customer];
    });
  };

  return (
    <>
      <TopMenu />
      <div className={'mx-auto pt-10'}>
        <SearchForm
          search={search}
          setSearch={setSearch}
          setCustomers={setCustomers}
          customers={customers}
          setCustomersTransactions={setCustomersTransactions}
        />

        <SearchFormDescription />

        {customers.map((user, index) => (
          <UserCard
            key={user.externalId.value}
            user={{ ...user, addNewTab, searchQuery: search }}
            first={index === 0}
            transactions={customersTransactions.filter(
              (ct) => ct.customerId === user.externalId.value
            )}
          />
        ))}

        <NoResultSearch
          customers={customers}
          search={search}
          setCustomersTransactions={setCustomersTransactions}
        />
      </div>
    </>
  );
}
