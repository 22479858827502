import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { TrashIcon } from '@heroicons/react/20/solid';

import { CustomerContext } from 'contexts/CustomerContext';
import { getPaymentMethods } from 'repositories/PaymentMethods';
import { PaymentMethodsData } from 'types';

import UnlinkPaymentModal from '../modals/UnlinkPaymentModal';
import PaymentMethod from '../PaymentMethod';
import { LoaderWrapper } from '../styled';

export default function PaymentMethods(): ReactElement {
  const customerContext = useContext(CustomerContext);
  const { customerId, customerType } = customerContext;
  const [paymentMethods, setPaymentMethods] = useState<
    Array<PaymentMethodsData>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (customerId && customerType) {
      (async () => {
        const result = await getPaymentMethods(
          customerId,
          customerType,
          setIsLoading
        );

        if (result) {
          setPaymentMethods(result);
        }
      })();
    }
  }, [customerId, customerType]);

  return (
    <div
      className={'container mx-auto pt-10'}
      data-test-id={'e2e-payment-methods-linked'}
    >
      <div>
        <div className={'ml-4 px-4 sm:px-0'}>
          <h3 className={'text-base font-semibold leading-7 text-gray-900'}>
            Payment Methods Linked:
          </h3>
        </div>

        <div className={'mt-6 border-t min-h-full border-gray-100 relative'}>
          <div
            className={
              'ml-4 divide-y divide-gray-100 max-h-80 overflow-hidden overflow-y-auto '
            }
          >
            {isLoading && (
              <LoaderWrapper data-test-id="payment-methods-loader" />
            )}
            {!!paymentMethods.length &&
              paymentMethods.map((paymentMethod: PaymentMethodsData) => (
                <div
                  className={'pt-1 pb-1 flex'}
                  key={paymentMethod.token}
                  data-test-id={'e2e-payment-method'}
                >
                  <PaymentMethod
                    paymentMethodId={paymentMethod.paymentMethodId}
                    paymentSchema={paymentMethod.paymentSchema}
                    cardLast4={paymentMethod.cardLast4}
                    paymentAccountId={paymentMethod.paymentAccountId}
                  />
                  <div className={'flex-auto'}>
                    <div className={'flex h-6 flex-row-reverse'}>
                      <UnlinkPaymentModal
                        paymentMethodToken={paymentMethod.token}
                        cta_label={<TrashIcon className="w-6 h-6" />}
                        cta_styling={'no-styling'}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
