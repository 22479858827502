import React, { Fragment, ReactElement } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  Table as TableType,
  useReactTable,
} from '@tanstack/react-table';

import TablePagination from 'components/TablePagination';
import { TableProps } from 'types';

export default function CBHTable<T>({
  columns,
  data,
  getRowCanExpand,
  renderSubComponent,
  tHeadStyles,
  dataTestID,
}: TableProps<T>): ReactElement {
  const table: TableType<T> = useReactTable<T>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowCanExpand: getRowCanExpand,
  });

  return (
    <div className={'p-2'} data-test-id={dataTestID}>
      <div className={'h-2'} />
      <table className={'w-full'}>
        <thead className={'bg-gray-100'} style={tHeadStyles}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={
                      'p-2 text-xs font-medium text-left text-gray-500 uppercase '
                    }
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={'bg-white divide-y divide-gray-200'}>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr
                  className={'hover:bg-gray-100'}
                  data-test-id={'e2e-cbh-table-item'}
                >
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={'p-2'}
                        data-test-id={
                          'e2e-' +
                          cell.column.id.toLowerCase().replaceAll(' ', '-')
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && (
                  <tr>
                    {/* 2nd row is a custom 1 cell row */}
                    <td
                      colSpan={row.getVisibleCells().length}
                      className={' p-2'}
                    >
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div className={'h-2'} />
      <TablePagination table={table} />
    </div>
  );
}
