export type MerchantLogoProps = {
  merchantName: string;
};

type merchantImageMapType = {
  [key: string]: string;
};

export const MerchantLogo = ({ merchantName }: MerchantLogoProps) => {
  const merchantImageMap: merchantImageMapType = {
    palta: '/logos/palta.png',
    'palta-sandbox': '/logos/palta.png',
    e2e: '/logos/palta.png',
    'e2e-sandbox': '/logos/palta.png',
    flo: '/logos/flo.png',
    pora: '/logos/lovi.jpeg',
    'pora-sandbox': '/logos/lovi.jpeg',
    lensa: '/logos/lensa.png',
    'lensa-sandbox': '/logos/lensa.png',
    simple: '/logos/simple.png',
    'simple-sandbox': '/logos/simple.png',
    zing: '/logos/zing.png',
    'zing-sandbox': '/logos/zing.png',
  };

  const imagePath = merchantImageMap[merchantName];
  const merchantNameFirstLetter = merchantName ? merchantName[0] : '';

  if (imagePath) {
    return (
      <img
        className="w-10 h-10 me-2 flex-none"
        src={merchantImageMap[merchantName]}
        alt="user photo"
      />
    );
  }

  return (
    <span className="w-10 h-10 me-2 flex-none capitalize text-center text-4xl">
      {merchantNameFirstLetter}
    </span>
  );
};
