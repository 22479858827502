import { memo } from 'react';

import { HighlightedTextProps } from 'interfaces';

const HighlightedText = memo(function HighlightedText({
  firstWord,
  secondWord,
}: HighlightedTextProps) {
  if (firstWord === secondWord) {
    return <b>{firstWord}</b>;
  }

  return <div>{firstWord}</div>;
});

export default HighlightedText;
