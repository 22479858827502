import { Dispatch, SetStateAction } from 'react';

import { AddToast } from 'react-toast-notifications';

import { requestsWrapper } from 'repositories/requestsWrapper';

import { getPBPUser } from '../services/auth';
import { setActiveMerchantId } from '../services/cookie';
import { ApplicationUser } from '../types';

const sandboxSortPrefix = 'ZZZZZZZZZZZ-';
const sandboxSubstring = 'sandbox';

export const getPBPUserR = async (
  setUser: (user: ApplicationUser) => unknown,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  redirectUserToPreviousLocation: () => unknown,
  addToast: AddToast
): Promise<boolean | undefined> => {
  const result = await requestsWrapper(getPBPUser, {}, setIsLoading, addToast);

  if (result) {
    const { workspaces, activeMerchantId, username } = result.data;

    const sortedWorkspaces = workspaces.sort((a, b) => {
      const aModified = a.name.includes(sandboxSubstring)
        ? `${sandboxSortPrefix}-${a.name}`
        : a.name;
      const bModified = b.name.includes(sandboxSubstring)
        ? `${sandboxSortPrefix}-${b.name}`
        : b.name;

      return aModified.localeCompare(bModified, 'en', {
        ignorePunctuation: true,
      });
    });

    const localActiveMerchantId = sortedWorkspaces
      ? sortedWorkspaces[0].id
      : activeMerchantId;

    const activeWorkspaceName =
      workspaces.find((workspace) => workspace.id === localActiveMerchantId)
        ?.name || 'not specified';

    setUser({
      workspaces: sortedWorkspaces,
      activeWorkspace: localActiveMerchantId,
      activeMerchantId: localActiveMerchantId,
      username: username,
      activeWorkspaceName: activeWorkspaceName,
    });

    setActiveMerchantId(localActiveMerchantId);
    redirectUserToPreviousLocation();
  } else {
    console.error('Error fetching user data');
  }

  return true;
};
