import { Decimal } from 'decimal.js';

export function getPrecision(numberAsString: string) {
  const n = numberAsString.toString().split('.');
  return n.length > 1 ? n[1].length : 0;
}

interface Map {
  [key: string]: number;
}

const currencyCodeToPrecisionMap: Map = {
  AFN: 2,
  EUR: 2,
  ALL: 2,
  DZD: 2,
  USD: 2,
  AOA: 2,
  XCD: 2,
  ARS: 2,
  AMD: 2,
  AWG: 2,
  AUD: 2,
  AZN: 2,
  BSD: 2,
  BHD: 3,
  BDT: 2,
  BBD: 2,
  BYN: 2,
  BZD: 2,
  XOF: 0,
  BMD: 2,
  INR: 2,
  BTN: 2,
  BOB: 2,
  BOV: 2,
  BAM: 2,
  BWP: 2,
  NOK: 2,
  BRL: 2,
  BND: 2,
  BGN: 2,
  BIF: 0,
  CVE: 2,
  KHR: 2,
  XAF: 0,
  CAD: 2,
  KYD: 2,
  CLP: 0,
  CLF: 4,
  CNY: 2,
  COP: 2,
  COU: 2,
  KMF: 0,
  CDF: 2,
  NZD: 2,
  CRC: 2,
  HRK: 2,
  CUP: 2,
  CUC: 2,
  ANG: 2,
  CZK: 2,
  DKK: 2,
  DJF: 0,
  DOP: 2,
  EGP: 2,
  SVC: 2,
  ERN: 2,
  SZL: 2,
  ETB: 2,
  FKP: 2,
  FJD: 2,
  XPF: 0,
  GMD: 2,
  GEL: 2,
  GHS: 2,
  GIP: 2,
  GTQ: 2,
  GBP: 2,
  GNF: 0,
  GYD: 2,
  HTG: 2,
  HNL: 2,
  HKD: 2,
  HUF: 2,
  ISK: 0,
  IDR: 2,
  XDR: 0,
  IRR: 2,
  IQD: 3,
  ILS: 2,
  JMD: 2,
  JPY: 0,
  JOD: 3,
  KZT: 2,
  KES: 2,
  KPW: 2,
  KRW: 0,
  KWD: 3,
  KGS: 2,
  LAK: 2,
  LBP: 2,
  LSL: 2,
  ZAR: 2,
  LRD: 2,
  LYD: 3,
  CHF: 2,
  MOP: 2,
  MKD: 2,
  MGA: 2,
  MWK: 2,
  MYR: 2,
  MVR: 2,
  MRU: 2,
  MUR: 2,
  XUA: 0,
  MXN: 2,
  MXV: 2,
  MDL: 2,
  MNT: 2,
  MAD: 2,
  MZN: 2,
  MMK: 2,
  NAD: 2,
  NPR: 2,
  NIO: 2,
  NGN: 2,
  OMR: 3,
  PKR: 2,
  PAB: 2,
  PGK: 2,
  PYG: 0,
  PEN: 2,
  PHP: 2,
  PLN: 2,
  QAR: 2,
  RON: 2,
  RUB: 2,
  RWF: 0,
  SHP: 2,
  WST: 2,
  STN: 2,
  SAR: 2,
  RSD: 2,
  SCR: 2,
  SLL: 2,
  SGD: 2,
  XSU: 0,
  SBD: 2,
  SOS: 2,
  SSP: 2,
  LKR: 2,
  SDG: 2,
  SRD: 2,
  SEK: 2,
  CHE: 2,
  CHW: 2,
  SYP: 2,
  TWD: 2,
  TJS: 2,
  TZS: 2,
  THB: 2,
  TOP: 2,
  TTD: 2,
  TND: 3,
  TRY: 2,
  TMT: 2,
  UGX: 0,
  UAH: 2,
  AED: 2,
  USN: 2,
  UYU: 2,
  UYI: 0,
  UYW: 4,
  UZS: 2,
  VUV: 0,
  VES: 2,
  VND: 0,
  YER: 2,
  ZMW: 2,
  ZWL: 2,
  XBA: 0,
  XBB: 0,
  XBC: 0,
  XBD: 0,
  XTS: 0,
  XXX: 0,
  XAU: 0,
  XPD: 0,
  XPT: 0,
  XAG: 0,
  AFA: 2,
  FIM: 2,
  ALK: 0,
  ADP: 0,
  ESP: 0,
  FRF: 2,
  AOK: 0,
  AON: 0,
  AOR: 0,
  ARA: 2,
  ARP: 2,
  ARY: 0,
  RUR: 2,
  ATS: 2,
  AYM: 0,
  AZM: 2,
  BYB: 2,
  BYR: 0,
  BEC: 0,
  BEF: 2,
  BEL: 0,
  BOP: 2,
  BAD: 2,
  BRB: 2,
  BRC: 2,
  BRE: 2,
  BRN: 2,
  BRR: 2,
  BGJ: 0,
  BGK: 0,
  BGL: 2,
  BUK: 0,
  HRD: 2,
  CYP: 2,
  CSJ: 0,
  CSK: 0,
  ECS: 0,
  ECV: 2,
  GQE: 0,
  EEK: 2,
  XEU: 0,
  GEK: 0,
  DDM: 0,
  DEM: 2,
  GHC: 2,
  GHP: 2,
  GRD: 0,
  GNE: 0,
  GNS: 0,
  GWE: 0,
  GWP: 2,
  ITL: 0,
  ISJ: 2,
  IEP: 2,
  ILP: 0,
  ILR: 2,
  LAJ: 0,
  LVL: 2,
  LVR: 2,
  LSM: 0,
  ZAL: 2,
  LTL: 2,
  LTT: 2,
  LUC: 0,
  LUF: 2,
  LUL: 0,
  MGF: 0,
  MVQ: 0,
  MLF: 0,
  MTL: 2,
  MTP: 0,
  MRO: 2,
  MXP: 0,
  MZE: 2,
  MZM: 2,
  NLG: 2,
  NIC: 2,
  PEH: 0,
  PEI: 0,
  PES: 2,
  PLZ: 2,
  PTE: 0,
  ROK: 0,
  ROL: 0,
  STD: 2,
  CSD: 2,
  SKK: 2,
  SIT: 2,
  RHD: 2,
  ESA: 0,
  ESB: 0,
  SDD: 2,
  SDP: 0,
  SRG: 2,
  CHC: 2,
  TJR: 0,
  TPE: 0,
  TRL: 0,
  TMM: 2,
  UGS: 0,
  UGW: 2,
  UAK: 2,
  SUR: 0,
  USS: 2,
  UYN: 0,
  UYP: 2,
  VEB: 2,
  VEF: 2,
  VNC: 0,
  YDD: 0,
  YUD: 2,
  YUM: 2,
  YUN: 2,
  ZRN: 2,
  ZRZ: 2,
  ZMK: 2,
  ZWC: 2,
  ZWD: 2,
  ZWN: 2,
  ZWR: 2,
  XFO: 0,
  XRE: 0,
  XFU: 0,
  VED: 2,
};

export function getPrecisionByCurrencyCode(currencyCode: string | undefined) {
  if (currencyCode) {
    return currencyCodeToPrecisionMap[currencyCode] || 2;
  }

  return 2;
}

export function round(num: number | string, precision: number) {
  const value = parseFloat(num.toString());
  if (isNaN(value)) {
    return 0;
  }

  return new Decimal(num).toDecimalPlaces(precision).toNumber();
}
