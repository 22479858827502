/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal';

const $Writer = $protobuf.Writer,
  $util = $protobuf.util;

const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

export const paltabrain = ($root.paltabrain = (() => {
  const paltabrain = {};

  paltabrain.event_schema = (function () {
    const event_schema = {};

    event_schema.v2 = (function () {
      const v2 = {};

      v2.ContextApplication = (function () {
        function ContextApplication(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextApplication.prototype.app_id = null;
        ContextApplication.prototype.app_version = null;
        ContextApplication.prototype.app_platform = null;

        let $oneOfFields;

        Object.defineProperty(ContextApplication.prototype, '_app_id', {
          get: $util.oneOfGetter(($oneOfFields = ['app_id'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextApplication.prototype, '_app_version', {
          get: $util.oneOfGetter(($oneOfFields = ['app_version'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextApplication.prototype, '_app_platform', {
          get: $util.oneOfGetter(($oneOfFields = ['app_platform'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        ContextApplication.create = function create(properties) {
          return new ContextApplication(properties);
        };

        ContextApplication.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.app_id != null && Object.hasOwnProperty.call(m, 'app_id'))
            w.uint32(10).string(m.app_id);
          if (
            m.app_version != null &&
            Object.hasOwnProperty.call(m, 'app_version')
          )
            w.uint32(18).string(m.app_version);
          if (
            m.app_platform != null &&
            Object.hasOwnProperty.call(m, 'app_platform')
          )
            w.uint32(26).string(m.app_platform);
          return w;
        };

        ContextApplication.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return (
            typeUrlPrefix + '/paltabrain.event_schema.v2.ContextApplication'
          );
        };

        return ContextApplication;
      })();

      v2.ContextDevice = (function () {
        function ContextDevice(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextDevice.prototype.device_brand = null;
        ContextDevice.prototype.device_model = null;
        ContextDevice.prototype.device_carrier = null;

        let $oneOfFields;

        Object.defineProperty(ContextDevice.prototype, '_device_brand', {
          get: $util.oneOfGetter(($oneOfFields = ['device_brand'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextDevice.prototype, '_device_model', {
          get: $util.oneOfGetter(($oneOfFields = ['device_model'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextDevice.prototype, '_device_carrier', {
          get: $util.oneOfGetter(($oneOfFields = ['device_carrier'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        ContextDevice.create = function create(properties) {
          return new ContextDevice(properties);
        };

        ContextDevice.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (
            m.device_brand != null &&
            Object.hasOwnProperty.call(m, 'device_brand')
          )
            w.uint32(10).string(m.device_brand);
          if (
            m.device_model != null &&
            Object.hasOwnProperty.call(m, 'device_model')
          )
            w.uint32(18).string(m.device_model);
          if (
            m.device_carrier != null &&
            Object.hasOwnProperty.call(m, 'device_carrier')
          )
            w.uint32(26).string(m.device_carrier);
          return w;
        };

        ContextDevice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.ContextDevice';
        };

        return ContextDevice;
      })();

      v2.ContextIdentify = (function () {
        function ContextIdentify(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextIdentify.prototype.idfa = null;
        ContextIdentify.prototype.idfv = null;
        ContextIdentify.prototype.gaid = null;

        let $oneOfFields;

        Object.defineProperty(ContextIdentify.prototype, '_idfa', {
          get: $util.oneOfGetter(($oneOfFields = ['idfa'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextIdentify.prototype, '_idfv', {
          get: $util.oneOfGetter(($oneOfFields = ['idfv'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextIdentify.prototype, '_gaid', {
          get: $util.oneOfGetter(($oneOfFields = ['gaid'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        ContextIdentify.create = function create(properties) {
          return new ContextIdentify(properties);
        };

        ContextIdentify.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.idfa != null && Object.hasOwnProperty.call(m, 'idfa'))
            w.uint32(10).string(m.idfa);
          if (m.idfv != null && Object.hasOwnProperty.call(m, 'idfv'))
            w.uint32(18).string(m.idfv);
          if (m.gaid != null && Object.hasOwnProperty.call(m, 'gaid'))
            w.uint32(26).string(m.gaid);
          return w;
        };

        ContextIdentify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.ContextIdentify';
        };

        return ContextIdentify;
      })();

      v2.ContextOs = (function () {
        function ContextOs(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextOs.prototype.os_name = null;
        ContextOs.prototype.os_version = null;

        let $oneOfFields;

        Object.defineProperty(ContextOs.prototype, '_os_name', {
          get: $util.oneOfGetter(($oneOfFields = ['os_name'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextOs.prototype, '_os_version', {
          get: $util.oneOfGetter(($oneOfFields = ['os_version'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        ContextOs.create = function create(properties) {
          return new ContextOs(properties);
        };

        ContextOs.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.os_name != null && Object.hasOwnProperty.call(m, 'os_name'))
            w.uint32(10).string(m.os_name);
          if (
            m.os_version != null &&
            Object.hasOwnProperty.call(m, 'os_version')
          )
            w.uint32(18).string(m.os_version);
          return w;
        };

        ContextOs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.ContextOs';
        };

        return ContextOs;
      })();

      v2.ContextUser = (function () {
        function ContextUser(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextUser.prototype.user_id = null;

        let $oneOfFields;

        Object.defineProperty(ContextUser.prototype, '_user_id', {
          get: $util.oneOfGetter(($oneOfFields = ['user_id'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        ContextUser.create = function create(properties) {
          return new ContextUser(properties);
        };

        ContextUser.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.user_id != null && Object.hasOwnProperty.call(m, 'user_id'))
            w.uint32(10).string(m.user_id);
          return w;
        };

        ContextUser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.ContextUser';
        };

        return ContextUser;
      })();

      v2.ContextZendesk = (function () {
        function ContextZendesk(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextZendesk.prototype.zendesk_ticket_id = null;

        let $oneOfFields;

        Object.defineProperty(ContextZendesk.prototype, '_zendesk_ticket_id', {
          get: $util.oneOfGetter(($oneOfFields = ['zendesk_ticket_id'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        ContextZendesk.create = function create(properties) {
          return new ContextZendesk(properties);
        };

        ContextZendesk.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (
            m.zendesk_ticket_id != null &&
            Object.hasOwnProperty.call(m, 'zendesk_ticket_id')
          )
            w.uint32(10).string(m.zendesk_ticket_id);
          return w;
        };

        ContextZendesk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.ContextZendesk';
        };

        return ContextZendesk;
      })();

      v2.ContextBrowser = (function () {
        function ContextBrowser(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        ContextBrowser.prototype.browser_user_agent = null;
        ContextBrowser.prototype.browser_referrer = null;
        ContextBrowser.prototype.browser_window_height = null;
        ContextBrowser.prototype.browser_window_width = null;

        let $oneOfFields;

        Object.defineProperty(ContextBrowser.prototype, '_browser_user_agent', {
          get: $util.oneOfGetter(($oneOfFields = ['browser_user_agent'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(ContextBrowser.prototype, '_browser_referrer', {
          get: $util.oneOfGetter(($oneOfFields = ['browser_referrer'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(
          ContextBrowser.prototype,
          '_browser_window_height',
          {
            get: $util.oneOfGetter(($oneOfFields = ['browser_window_height'])),
            set: $util.oneOfSetter($oneOfFields),
          }
        );

        Object.defineProperty(
          ContextBrowser.prototype,
          '_browser_window_width',
          {
            get: $util.oneOfGetter(($oneOfFields = ['browser_window_width'])),
            set: $util.oneOfSetter($oneOfFields),
          }
        );

        ContextBrowser.create = function create(properties) {
          return new ContextBrowser(properties);
        };

        ContextBrowser.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (
            m.browser_user_agent != null &&
            Object.hasOwnProperty.call(m, 'browser_user_agent')
          )
            w.uint32(10).string(m.browser_user_agent);
          if (
            m.browser_referrer != null &&
            Object.hasOwnProperty.call(m, 'browser_referrer')
          )
            w.uint32(18).string(m.browser_referrer);
          if (
            m.browser_window_height != null &&
            Object.hasOwnProperty.call(m, 'browser_window_height')
          )
            w.uint32(24).sint64(m.browser_window_height);
          if (
            m.browser_window_width != null &&
            Object.hasOwnProperty.call(m, 'browser_window_width')
          )
            w.uint32(32).sint64(m.browser_window_width);
          return w;
        };

        ContextBrowser.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.ContextBrowser';
        };

        return ContextBrowser;
      })();

      v2.Context = (function () {
        function Context(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        Context.prototype.application = null;
        Context.prototype.device = null;
        Context.prototype.identify = null;
        Context.prototype.os = null;
        Context.prototype.user = null;
        Context.prototype.zendesk = null;
        Context.prototype.browser = null;

        Context.create = function create(properties) {
          return new Context(properties);
        };

        Context.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (
            m.application != null &&
            Object.hasOwnProperty.call(m, 'application')
          )
            $root.paltabrain.event_schema.v2.ContextApplication.encode(
              m.application,
              w.uint32(10).fork()
            ).ldelim();
          if (m.device != null && Object.hasOwnProperty.call(m, 'device'))
            $root.paltabrain.event_schema.v2.ContextDevice.encode(
              m.device,
              w.uint32(18).fork()
            ).ldelim();
          if (m.identify != null && Object.hasOwnProperty.call(m, 'identify'))
            $root.paltabrain.event_schema.v2.ContextIdentify.encode(
              m.identify,
              w.uint32(26).fork()
            ).ldelim();
          if (m.os != null && Object.hasOwnProperty.call(m, 'os'))
            $root.paltabrain.event_schema.v2.ContextOs.encode(
              m.os,
              w.uint32(34).fork()
            ).ldelim();
          if (m.user != null && Object.hasOwnProperty.call(m, 'user'))
            $root.paltabrain.event_schema.v2.ContextUser.encode(
              m.user,
              w.uint32(42).fork()
            ).ldelim();
          if (m.zendesk != null && Object.hasOwnProperty.call(m, 'zendesk'))
            $root.paltabrain.event_schema.v2.ContextZendesk.encode(
              m.zendesk,
              w.uint32(50).fork()
            ).ldelim();
          if (m.browser != null && Object.hasOwnProperty.call(m, 'browser'))
            $root.paltabrain.event_schema.v2.ContextBrowser.encode(
              m.browser,
              w.uint32(58).fork()
            ).ldelim();
          return w;
        };

        Context.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.Context';
        };

        return Context;
      })();

      v2.EventHeader = (function () {
        function EventHeader(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        EventHeader.create = function create(properties) {
          return new EventHeader(properties);
        };

        EventHeader.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          return w;
        };

        EventHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.EventHeader';
        };

        return EventHeader;
      })();

      v2.EventPayloadSessionStart = (function () {
        function EventPayloadSessionStart(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        EventPayloadSessionStart.create = function create(properties) {
          return new EventPayloadSessionStart(properties);
        };

        EventPayloadSessionStart.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          return w;
        };

        EventPayloadSessionStart.getTypeUrl = function getTypeUrl(
          typeUrlPrefix
        ) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return (
            typeUrlPrefix +
            '/paltabrain.event_schema.v2.EventPayloadSessionStart'
          );
        };

        return EventPayloadSessionStart;
      })();

      v2.EventPayloadPageOpen = (function () {
        function EventPayloadPageOpen(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        EventPayloadPageOpen.prototype.title = null;

        let $oneOfFields;

        Object.defineProperty(EventPayloadPageOpen.prototype, '_title', {
          get: $util.oneOfGetter(($oneOfFields = ['title'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        EventPayloadPageOpen.create = function create(properties) {
          return new EventPayloadPageOpen(properties);
        };

        EventPayloadPageOpen.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.title != null && Object.hasOwnProperty.call(m, 'title'))
            w.uint32(10).string(m.title);
          return w;
        };

        EventPayloadPageOpen.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return (
            typeUrlPrefix + '/paltabrain.event_schema.v2.EventPayloadPageOpen'
          );
        };

        return EventPayloadPageOpen;
      })();

      v2.EventPayloadButtonClick = (function () {
        function EventPayloadButtonClick(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        EventPayloadButtonClick.prototype.action = null;

        let $oneOfFields;

        Object.defineProperty(EventPayloadButtonClick.prototype, '_action', {
          get: $util.oneOfGetter(($oneOfFields = ['action'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        EventPayloadButtonClick.create = function create(properties) {
          return new EventPayloadButtonClick(properties);
        };

        EventPayloadButtonClick.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.action != null && Object.hasOwnProperty.call(m, 'action'))
            w.uint32(10).string(m.action);
          return w;
        };

        EventPayloadButtonClick.getTypeUrl = function getTypeUrl(
          typeUrlPrefix
        ) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return (
            typeUrlPrefix +
            '/paltabrain.event_schema.v2.EventPayloadButtonClick'
          );
        };

        return EventPayloadButtonClick;
      })();

      v2.EventPayload = (function () {
        function EventPayload(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        EventPayload.prototype.event_1 = null;
        EventPayload.prototype.event_2 = null;
        EventPayload.prototype.event_3 = null;

        let $oneOfFields;

        Object.defineProperty(EventPayload.prototype, 'event_type', {
          get: $util.oneOfGetter(
            ($oneOfFields = ['event_1', 'event_2', 'event_3'])
          ),
          set: $util.oneOfSetter($oneOfFields),
        });

        EventPayload.create = function create(properties) {
          return new EventPayload(properties);
        };

        EventPayload.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.event_1 != null && Object.hasOwnProperty.call(m, 'event_1'))
            $root.paltabrain.event_schema.v2.EventPayloadSessionStart.encode(
              m.event_1,
              w.uint32(10).fork()
            ).ldelim();
          if (m.event_2 != null && Object.hasOwnProperty.call(m, 'event_2'))
            $root.paltabrain.event_schema.v2.EventPayloadPageOpen.encode(
              m.event_2,
              w.uint32(18).fork()
            ).ldelim();
          if (m.event_3 != null && Object.hasOwnProperty.call(m, 'event_3'))
            $root.paltabrain.event_schema.v2.EventPayloadButtonClick.encode(
              m.event_3,
              w.uint32(26).fork()
            ).ldelim();
          return w;
        };

        EventPayload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.v2.EventPayload';
        };

        return EventPayload;
      })();

      return v2;
    })();

    event_schema.sdk = (function () {
      const sdk = {};

      sdk.EventCommon = (function () {
        function EventCommon(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        EventCommon.prototype.event_ts = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        EventCommon.prototype.session_id = null;
        EventCommon.prototype.session_event_seq_num = null;

        let $oneOfFields;

        Object.defineProperty(EventCommon.prototype, '_session_id', {
          get: $util.oneOfGetter(($oneOfFields = ['session_id'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(EventCommon.prototype, '_session_event_seq_num', {
          get: $util.oneOfGetter(($oneOfFields = ['session_event_seq_num'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        EventCommon.create = function create(properties) {
          return new EventCommon(properties);
        };

        EventCommon.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.event_ts != null && Object.hasOwnProperty.call(m, 'event_ts'))
            w.uint32(8).sint64(m.event_ts);
          if (
            m.session_id != null &&
            Object.hasOwnProperty.call(m, 'session_id')
          )
            w.uint32(16).sint64(m.session_id);
          if (
            m.session_event_seq_num != null &&
            Object.hasOwnProperty.call(m, 'session_event_seq_num')
          )
            w.uint32(24).sint64(m.session_event_seq_num);
          return w;
        };

        EventCommon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.sdk.EventCommon';
        };

        return EventCommon;
      })();

      sdk.Event = (function () {
        function Event(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        Event.prototype.common = null;
        Event.prototype.header = $util.newBuffer([]);
        Event.prototype.payload = $util.newBuffer([]);

        Event.create = function create(properties) {
          return new Event(properties);
        };

        Event.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.common != null && Object.hasOwnProperty.call(m, 'common'))
            $root.paltabrain.event_schema.sdk.EventCommon.encode(
              m.common,
              w.uint32(10).fork()
            ).ldelim();
          if (m.header != null && Object.hasOwnProperty.call(m, 'header'))
            w.uint32(18).bytes(m.header);
          if (m.payload != null && Object.hasOwnProperty.call(m, 'payload'))
            w.uint32(26).bytes(m.payload);
          return w;
        };

        Event.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.sdk.Event';
        };

        return Event;
      })();

      sdk.BatchCommon = (function () {
        function BatchCommon(p) {
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        BatchCommon.prototype.instance_id = '';
        BatchCommon.prototype.batch_id = '';
        BatchCommon.prototype.country_code = null;
        BatchCommon.prototype.locale = null;
        BatchCommon.prototype.utc_offset = null;

        let $oneOfFields;

        Object.defineProperty(BatchCommon.prototype, '_country_code', {
          get: $util.oneOfGetter(($oneOfFields = ['country_code'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(BatchCommon.prototype, '_locale', {
          get: $util.oneOfGetter(($oneOfFields = ['locale'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        Object.defineProperty(BatchCommon.prototype, '_utc_offset', {
          get: $util.oneOfGetter(($oneOfFields = ['utc_offset'])),
          set: $util.oneOfSetter($oneOfFields),
        });

        BatchCommon.create = function create(properties) {
          return new BatchCommon(properties);
        };

        BatchCommon.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (
            m.instance_id != null &&
            Object.hasOwnProperty.call(m, 'instance_id')
          )
            w.uint32(10).string(m.instance_id);
          if (m.batch_id != null && Object.hasOwnProperty.call(m, 'batch_id'))
            w.uint32(18).string(m.batch_id);
          if (
            m.country_code != null &&
            Object.hasOwnProperty.call(m, 'country_code')
          )
            w.uint32(26).string(m.country_code);
          if (m.locale != null && Object.hasOwnProperty.call(m, 'locale'))
            w.uint32(34).string(m.locale);
          if (
            m.utc_offset != null &&
            Object.hasOwnProperty.call(m, 'utc_offset')
          )
            w.uint32(40).sint64(m.utc_offset);
          return w;
        };

        BatchCommon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.sdk.BatchCommon';
        };

        return BatchCommon;
      })();

      sdk.Batch = (function () {
        function Batch(p) {
          this.events = [];
          if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
              if (p[ks[i]] != null) this[ks[i]] = p[ks[i]];
        }

        Batch.prototype.common = null;
        Batch.prototype.context = $util.newBuffer([]);
        Batch.prototype.events = $util.emptyArray;

        Batch.create = function create(properties) {
          return new Batch(properties);
        };

        Batch.encode = function encode(m, w) {
          if (!w) w = $Writer.create();
          if (m.common != null && Object.hasOwnProperty.call(m, 'common'))
            $root.paltabrain.event_schema.sdk.BatchCommon.encode(
              m.common,
              w.uint32(10).fork()
            ).ldelim();
          if (m.context != null && Object.hasOwnProperty.call(m, 'context'))
            w.uint32(18).bytes(m.context);
          if (m.events != null && m.events.length) {
            for (var i = 0; i < m.events.length; ++i)
              $root.paltabrain.event_schema.sdk.Event.encode(
                m.events[i],
                w.uint32(26).fork()
              ).ldelim();
          }
          return w;
        };

        Batch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
          if (typeUrlPrefix === undefined) {
            typeUrlPrefix = 'type.googleapis.com';
          }
          return typeUrlPrefix + '/paltabrain.event_schema.sdk.Batch';
        };

        return Batch;
      })();

      return sdk;
    })();

    return event_schema;
  })();

  return paltabrain;
})());

export { $root as default };
