import * as Cookies from 'es-cookie';

const ACTIVE_MERCHANT_COOKIE_NAME = 'PBP_ACTIVE_MERCHANT_ID';
const ACTIVE_MERCHANT_COOKIE_EXPIRES = 365;

export function getActiveMerchantId() {
  return Cookies.get(ACTIVE_MERCHANT_COOKIE_NAME);
}

const isSafariBrowser = () =>
  navigator.userAgent.indexOf('Safari') > -1 &&
  navigator.userAgent.indexOf('Chrome') <= -1;

const isSecure = (): boolean =>
  !(window.location.hostname === 'localhost' && isSafariBrowser());

export function setActiveMerchantId(merchantId: number) {
  Cookies.set(ACTIVE_MERCHANT_COOKIE_NAME, merchantId.toString(), {
    expires: ACTIVE_MERCHANT_COOKIE_EXPIRES,
    domain: window.location.hostname,
    sameSite: 'none', // should be set to make cookies work under iframe
    secure: isSecure(), // should be set to make cookies work under iframe
  } as Cookies.CookieAttributes);
}

export function removeCookies() {
  const cookies = Cookies.getAll();

  for (const cookie in cookies) {
    Cookies.remove(cookie);
  }
}
