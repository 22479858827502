import { useContext } from 'react';

import { Outlet } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import { CustomerContext } from '../contexts/CustomerContext';

export default function Layout() {
  const { isLoading } = useContext(CustomerContext);
  const { isLoadingAuthorization } = useContext(AuthContext);

  const opacityLayout = isLoading || isLoadingAuthorization ? 'opacity-50' : '';

  return (
    <div className={'container mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'}>
      <div className={`columns-1 ${opacityLayout}`}>
        <Outlet />
      </div>
    </div>
  );
}
