import React from 'react';

import PricePointPayments from 'components/PricePointPayments';
import { pricePointType, ShortPricePoint } from 'types';

export default function CustomerTransactions({
  pricePoints,
}: {
  pricePoints: pricePointType[];
}) {
  const shortPricePoints: Array<ShortPricePoint> = pricePoints.map(
    ({ id, currentVersion }) => ({
      id: id,
      versionId: currentVersion,
    })
  );

  return <PricePointPayments pricePoints={shortPricePoints} />;
}
