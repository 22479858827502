import { Fragment } from 'react';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { TableOptions } from '@tanstack/table-core';

import TablePagination from './TablePagination';
import { SearchTransactionLog } from '../types';

type TableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  getRowCanExpand: (row: Row<TData>) => boolean;
  updateRefundAmount: (
    transactionId: string,
    amount: number,
    precision: number
  ) => void;
};

export interface CBHTableOptions<TData> extends TableOptions<TData> {
  meta: {
    updateRefundAmount: (
      transactionId: string,
      amount: number,
      precision: number
    ) => void;
  };
}

export function SearchTable<T>({
  data,
  columns,
  getRowCanExpand,
  updateRefundAmount,
}: TableProps<T>): JSX.Element {
  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateRefundAmount: updateRefundAmount,
    },
  } as CBHTableOptions<T>);

  return (
    <div className={'pt-2'}>
      <table
        data-test-id={'e2e-customer-transactions-table'}
        className={'w-full'}
      >
        <thead className={'bg-gray-100'}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={
                      'p-2 text-xs font-medium text-left text-gray-500 uppercase'
                    }
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={'bg-white divide-y divide-gray-200'}>
          {table.getRowModel().rows.map((row) => {
            const transactionLog = row.original as SearchTransactionLog;
            const transactionStatus = transactionLog?.transactionStatus || '';

            const trxSuccessfull = ['SETTLED', 'AUTHORIZED'].includes(
              transactionStatus
            );

            return (
              <Fragment key={row.id}>
                <tr
                  className={trxSuccessfull ? 'successful-trx' : 'failed-trx'}
                >
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={'p-2' + (row.index == 3 ? 'w-2/5' : 'w-1/5')}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <div className={'h-2'} />
      {!!data.length && <TablePagination table={table} />}
    </div>
  );
}
