import { ReactElement, useContext } from 'react';

import PaymentMethods from 'components/customer-details/payment-methods';
import PersonalInfo from 'components/customer-details/personal-info';
import ProductFeatures from 'components/ProductFeatures';
import TopMenu from 'components/TopMenu';
import { CustomerContext } from 'contexts/CustomerContext';

import CustomerFeatures from '../components/CustomerFeatures';

export default function CustomerDetail(): ReactElement {
  const { searchResult } = useContext(CustomerContext);

  return (
    <>
      <TopMenu />
      {searchResult == 'not-found' && (
        <div className={'container mx-auto pt-10'}>
          <div className={'grid grid-cols-1 text-red-700 h-1'}>
            <h1>
              Sorry we are unable to find the customer automatically.
              <br /> Please use the search tab or ask the customer to provide
              more information.
            </h1>
          </div>
        </div>
      )}

      {searchResult == 'found' && (
        <>
          <div className={'grid grid-cols-2 border-b-gray-200 border-b-2'}>
            <PersonalInfo />
            <PaymentMethods />
          </div>
          <div className={'grid grid-cols-1 mt-4'}>
            <ProductFeatures />
          </div>
          <div className={'grid grid-cols-1 mt-4'}>
            <CustomerFeatures />
          </div>
        </>
      )}

      {searchResult == 'loading' && (
        <div className={'container mx-auto pt-10'}>
          <h1>Searching ...</h1>
        </div>
      )}
    </>
  );
}
