import { extendedCustomerType, SearchTransactionLog } from 'types';

import Accordion from './Accordion';
import { CustomersTransactionsList } from './CustomersTransactionsList';
import HighlightedText from './HighlightedText';

type CardComponentProps = {
  user: extendedCustomerType;
  transactions: SearchTransactionLog[];
  first: boolean;
};

const UserCard = ({
  user,
  transactions = [],
  first = false,
}: CardComponentProps) => {
  return (
    <div
      data-test-id={`e2e-${user.externalId.value}`}
      className={'border border-black p-2 mb-2 w-full mt-4 user_row'}
    >
      <Accordion
        header={
          <>
            <div className="bg-gray-100 flex justify-between mb-1">
              <span className="text-gray-500 w-1/2 pl-2">Customer ID</span>
              <span className="text-gray-500 w-1/2">Email</span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="w-1/2">
                <a className={'text-blue-600 cursor-pointer'}>
                  <button
                    data-test-id="e2e-customer-id"
                    onClick={() => user.addNewTab(user)}
                  >
                    <HighlightedText
                      firstWord={user.externalId.value}
                      secondWord={user.searchQuery}
                    />
                  </button>
                </a>
              </span>
              <span data-test-id="e2e-customer-email" className="w-1/2">
                {user.emails.map((email) => (
                  <HighlightedText
                    firstWord={email}
                    secondWord={user.searchQuery}
                    key={email}
                  />
                ))}
              </span>
            </div>
          </>
        }
        body={
          <CustomersTransactionsList customersTransactions={transactions} />
        }
        defaultOpen={first}
      />
    </div>
  );
};

export default UserCard;
