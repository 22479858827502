import { pricePointType, SubscriptionTags } from '../types';

type NextPlanData = {
  plan: string | null;
  price: string | null;
};

type NextPlanDataArgs = {
  pricePoints: pricePointType[];
  tags: string[];
  pricePointId: number;
  pricePointVersionId: number;
};

export const getNextPlanData = ({
  pricePoints,
  tags,
  pricePointId,
  pricePointVersionId,
}: NextPlanDataArgs): NextPlanData => {
  const isUpcoming = tags?.includes(SubscriptionTags.isUpcoming);
  // const isTrial = tags?.includes(SubscriptionTags.isTrial);

  const pricePointData =
    pricePoints &&
    pricePoints.find(
      (pricePoint) =>
        pricePoint.id == pricePointId &&
        pricePoint.currentVersion == pricePointVersionId
    );

  let nextPlan = null;
  let nextPrice = null;

  if (pricePointData) {
    if (pricePointData.nextPeriodValue && pricePointData.nextPeriodType) {
      nextPlan = `${pricePointData.nextPeriodValue} ${pricePointData.nextPeriodType}`;
    }
    if (pricePointData.nextBasePrice && pricePointData.currencyCode) {
      nextPrice = `${pricePointData.nextBasePrice} ${pricePointData.currencyCode}`;
    }

    if (isUpcoming) {
      const ending =
        pricePointData.introPeriodValue && pricePointData.introPeriodValue > 1
          ? 's'
          : '';

      if (pricePointData.introPeriodValue && pricePointData.introPeriodType) {
        nextPlan = `${pricePointData.introPeriodValue} ${pricePointData.introPeriodType}${ending}`;
      }

      if (pricePointData.introBasePrice && pricePointData.currencyCode) {
        nextPrice = `${pricePointData.introBasePrice} ${pricePointData.currencyCode}`;
      }
    }
  }
  return { plan: nextPlan, price: nextPrice };
};
